import React, { FC } from 'react'

import { SafeNoteImages } from '@/features/safe-note/assets'
import {
  Button,
  Heading,
  IModalWithCloseFn,
  Modal,
  Spacer,
  Text,
  TextAlign
} from '@/packages/ui'

import styles from './ReminderSentModal.module.scss'

type ReminderSentModalProps = IModalWithCloseFn

const ReminderSentModal: FC<ReminderSentModalProps> = ({
  visible,
  closeModal
}: ReminderSentModalProps) => (
  <Modal
    id="reminder-sent-modal"
    size="sm"
    visible={visible}
    setVisible={closeModal}
  >
    <div className={styles.container}>
      <img
        className={styles.image}
        src={SafeNoteImages.Reminder}
        alt="reminder-image"
      />

      <Spacer size={37} />
      <Heading align={TextAlign.CENTER}>
        Thank you! Reminder has been sent.
      </Heading>

      <Spacer size={14} />
      <Text align={TextAlign.CENTER}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua, dolor sit amet,
        consectetur adipiscing elit.
      </Text>

      <Spacer size={30} />
      <Button width="default" uppercase onClick={closeModal}>
        Back to safe
      </Button>
    </div>
  </Modal>
)

export default ReminderSentModal
