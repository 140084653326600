import clsx from 'clsx'
import React, { FC, useEffect, useRef } from 'react'

import { Marker } from '@/common/helpers'
import {
  SAFE_NOTE_TEMPLATE_ANCHORS,
  SAFE_NOTE_TEMPLATE_ID,
  SafeNoteSignature,
  SafeNoteTemplate
} from '@/features/safe-note'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'
import { useSmartState } from '@/packages/hooks'
import { Spacer } from '@/packages/ui'

import { DocumentControl } from './components/DocumentControl'
import styles from './SafeNoteDocument.module.scss'

interface SafeNoteDocumentProps {}

const SafeNoteDocument: FC<SafeNoteDocumentProps> = () => {
  const {
    data: {
      mfn,
      discountRate,
      discountRateActive,
      valuationCap,
      valuationCapActive,
      safeAmount,
      companyName,
      recipientName,
      stateOfIncorporation,
      // hasSenderSignature,
      signatureData
    },
    showColors,
    fullScreen,
    toggleFullScreen,
    onDownload,
    // viewMode,
    viewAs
  } = useSafeNoteContext()

  const [state, setState] = useSmartState({
    searchValue: '',
    matchesCount: 0,
    currentWordIndex: 0
  })
  const marker = useRef<any>()

  useEffect(() => {
    setTimeout(() => {
      marker.current = new Marker(SAFE_NOTE_TEMPLATE_ID)
    })
  }, [])

  useEffect(() => {
    const markerResult = marker.current?.mark(state.searchValue)
    setState({
      matchesCount: markerResult,
      currentWordIndex: 0
    })
  }, [state.searchValue])

  // const showWarningMessage = viewAs === ViewSafeAs.SENDER && !hasSenderSignature

  return (
    <div className={clsx(styles.container, fullScreen && styles.fullScreen)}>
      <DocumentControl
        onFullScreen={toggleFullScreen}
        fullScreenActive={fullScreen}
        searchValue={state.searchValue}
        onSearch={(val: string) => setState({ searchValue: val })}
        matchesCount={state.matchesCount}
        currentWordIndex={state.searchValue ? state.currentWordIndex + 1 : 0}
        onPrevWord={() =>
          setState({ currentWordIndex: marker.current?.markPrev() })
        }
        onNextWord={() =>
          setState({ currentWordIndex: marker.current?.markNext() })
        }
        prevButtonDisabled={state.currentWordIndex === 0}
        nextButtonDisabled={state.currentWordIndex >= state.matchesCount}
        onDownload={onDownload}
      />

      <div className={clsx(styles.template)}>
        <SafeNoteTemplate
          id={SAFE_NOTE_TEMPLATE_ID}
          mfn={mfn}
          discountRate={discountRateActive ? discountRate : undefined}
          valuationCap={valuationCapActive ? valuationCap : undefined}
          safeAmount={safeAmount}
          companyName={companyName}
          recipientName={recipientName}
          showColors={showColors}
          stateOfIncorporation={stateOfIncorporation}
          anchors={{
            company: SAFE_NOTE_TEMPLATE_ANCHORS.COMPANY,
            recipient: SAFE_NOTE_TEMPLATE_ANCHORS.INVESTOR,
            amount: SAFE_NOTE_TEMPLATE_ANCHORS.AMOUNT,
            discountRate: SAFE_NOTE_TEMPLATE_ANCHORS.DISCOUNT_RATE,
            valuationCap: SAFE_NOTE_TEMPLATE_ANCHORS.VALUATION_CAP
          }}
        />

        {/* <ConditionalRender condition={!viewMode && showWarningMessage}> */}
        {/*   <Spacer size={20} /> */}
        {/*   <Info> */}
        {/*     You don’t have to sign now. You can send it and sign it later once */}
        {/*     they have reviewed it. Send now and sign later if you sign now, and */}
        {/*     the other person accepts, then it becomes a binding agreemnts. If */}
        {/*     you sign now, and they don’t accept, you will be asked the sign the */}
        {/*     revised terms */}
        {/*   </Info> */}
        {/* </ConditionalRender> */}

        <Spacer size={20} />
        <SafeNoteSignature {...signatureData} viewAs={viewAs} />
      </div>
    </div>
  )
}

export default SafeNoteDocument
