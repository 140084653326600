import React, { FC } from 'react'

import { useStore } from '@/common/store'
import { capitalize } from '@/common/utils'
import { WelcomeCharacters } from '@/features/auth/assets'
import { AddNewProfileModal } from '@/features/dashboard/modals'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import advantagesList from './advantagesList'
import styles from './DashboardEmptyScreen.module.scss'

interface DashboardEmptyScreenProps {
  type: ProfileTypes.ANGEL | ProfileTypes.ENTREPRENEUR
}

const copy = {
  [ProfileTypes.ENTREPRENEUR]: {
    subtitle: 'Welcome to your (online) Entrepreneurial office.',
    subtext:
      'Looking to fundraise? You came to the right place. Use this workspace to create, negotiate, and send SAFEs to your investors. Raising capital has never been so easy. Kickstart your startup and launch that genius idea of yours.'
  },
  [ProfileTypes.ANGEL]: {
    subtitle: 'You’ve entered the Angel workspace.',
    subtext:
      'As an Angel, you can invest in the companies and entrepreneurs you believe in. Provide seed funding to friends and family, and help them kickstart their ideas, all from this platform.'
  },
  [ProfileTypes.ADVISOR]: {
    subtitle: 'Lorem ipsum dolor',
    subtext:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl nisl eget augue.'
  }
}

const DashboardEmptyScreen: FC<DashboardEmptyScreenProps> = (props) => {
  const { type } = props
  const user = useStore((store) => store.user.me)

  const roleName = capitalize(type)

  const {
    visible: modalActive,
    closeModal,
    openModal
  } = useModal<boolean>(false)

  const Intro = (
    <div>
      <Row items="end" className={styles.heading}>
        <Heading weight={FontWeight.BLACK} type={HeadingTypes.H1}>
          Hey there,{' '}
          <span className={styles.name}>
            {user?.fullName}{' '}
            <Badge
              type={BadgeType.WARNING}
              size="normal"
              className={styles.badge}
            >
              {roleName}
            </Badge>
          </span>
        </Heading>
      </Row>

      <Heading type={HeadingTypes.H3}>{copy[type].subtitle}</Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        className={styles.workspaceDescription}
      >
        {copy[type].subtext}
      </Text>

      <Button width="default" onClick={() => openModal()}>
        <Row items="center" gap={5}>
          <SharedIcons.PlusThin color={Color.neutral0} size={20} /> Get started
        </Row>
      </Button>
    </div>
  )

  const Advantages = (
    <div>
      <Heading type={HeadingTypes.H3}>
        What can I do with an {roleName} workspace?
      </Heading>

      <div className={styles.advantagesList}>
        {advantagesList[type].map((item) => (
          <Col gap={8} className={styles.advantageItem} key={item.title}>
            <Row items="center" gap={6}>
              {item.icon({})}
              <Text
                type={TextTypes.BODY_MAIN}
                weight={FontWeight.BOLD}
                color={Color.primary400}
              >
                {item.title}
              </Text>
            </Row>
            <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral500}>
              {item.description}
            </Text>
          </Col>
        ))}
      </div>
    </div>
  )

  return (
    <Row gap={86} items="start" justify="between" className={styles.container}>
      {modalActive && (
        <AddNewProfileModal predefinedRole={type} closeModal={closeModal} />
      )}

      <div className={styles.leftSide}>
        {Intro}
        {Advantages}
      </div>
      <img
        src={
          type === ProfileTypes.ENTREPRENEUR
            ? WelcomeCharacters.EntrepreneurWelcomePage
            : WelcomeCharacters.AngelWelcomePage
        }
        className={styles[`${type}-character`]}
        alt=""
      />
    </Row>
  )
}

export default DashboardEmptyScreen
