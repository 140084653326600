import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  Button,
  Col,
  FontWeight,
  Heading,
  IModalWithCloseFn,
  Modal,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './TermsLearnMoreModal.module.scss'

const data = {
  mfn: {
    title: 'Set Most Favoured Nation',
    image: SafeNoteImages.MfnInfo,
    description: `Most Favoured Nation (MFN) is a trade status granted to a country,
          ensuring it receives the best trade terms and conditions offered to
          any other nation.`
  },
  terms: {
    title: 'Set Custom Terms',
    image: SafeNoteImages.TermsInfo,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  }
}

const TermsLearnMoreModal: FC<
  IModalWithCloseFn & { type: 'mfn' | 'terms' }
> = ({ type, visible, closeModal }) => (
  <Modal id="mfnTermsModal" size="sm" visible={visible} setVisible={closeModal}>
    <Col items="center" gap={32}>
      <img className={styles.image} src={data[type].image} alt="mfn-document" />

      <Col items="center" gap={15}>
        <Row items="center" gap={15}>
          <Heading>{data[type].title}</Heading>
        </Row>

        <Text
          type={TextTypes.BODY_DEFAULT}
          align={TextAlign.CENTER}
          color={Color.neutral500}
        >
          {data[type].description}
        </Text>

        <ConditionalRender condition={type === 'mfn'}>
          <Row items="center" gap={28}>
            <Row items="center" gap={5}>
              <SharedIcons.Checkmark size={15} />
              <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.SEMIBOLD}>
                Valuation Cap
              </Text>
              <Badge size="small">TBD</Badge>
            </Row>

            <Row items="center" gap={5}>
              <SharedIcons.Checkmark size={15} />
              <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.SEMIBOLD}>
                Discount Rate
              </Text>
              <Badge size="small">TBD</Badge>
            </Row>
          </Row>
        </ConditionalRender>
      </Col>

      <Button width="default" onClick={closeModal} uppercase>
        Back to terms
      </Button>
    </Col>
  </Modal>
)

export default TermsLearnMoreModal
