import clsx from 'clsx'
import React, { FC, useCallback, useMemo } from 'react'

import { SharedIcons } from '../../../icons'
import { Color } from '../../../palette'
import { Text, TextTypes } from '../../typography'
import styles from './FileItem.module.scss'

interface FileItemProps {
  id: string
  name: string
  icon?: string
  onDelete?: (id: string) => void
  onClick?: (id: string) => void
  uploadProgress?: number
  roundIcon?: boolean
  showUploadProgress?: boolean
  showPlaceholderIfNoIcon?: boolean
}

const FileItem: FC<FileItemProps> = ({
  id,
  name,
  onClick,
  onDelete,
  icon,
  showUploadProgress = false,
  uploadProgress = 0,
  showPlaceholderIfNoIcon = false,
  roundIcon = false
}: FileItemProps) => {
  const handleDelete = useCallback(() => {
    onDelete?.(id)
  }, [id, onDelete])

  const handleClick = useCallback(() => {
    onClick?.(id)
  }, [id, onClick])

  const showProgressBar = useMemo(
    () => showUploadProgress && uploadProgress < 100 && uploadProgress > 0,
    [showUploadProgress, uploadProgress]
  )
  const iconClasses = clsx(styles.iconWrapper, roundIcon && styles.roundIcon)

  return (
    <div className={styles.fileCard} key={id}>
      <div className={styles.infoRow}>
        <div className={styles.nameBlock} onClick={handleClick}>
          {!!icon && (
            <div className={iconClasses}>
              <img src={icon} alt="Icon" />
            </div>
          )}
          {showPlaceholderIfNoIcon && !Boolean(icon) && (
            <div className={iconClasses} />
          )}
          <span className={styles.fileName}>{name}</span>
        </div>
        <div className={styles.button} onClick={handleDelete}>
          <SharedIcons.Trash size={16} className={styles.deleteIcon} />
        </div>
      </div>
      {showProgressBar && (
        <div className={styles.progress}>
          <progress id="file" max="100" value={uploadProgress} />
          <Text
            type={TextTypes.BODY_EXTRA_SMALL}
            color={Color.neutral400}
            className={styles.progressInfo}
          >
            {uploadProgress}%
          </Text>
        </div>
      )}
    </div>
  )
}

export default FileItem
