import React, { FC } from 'react'

import {
  PaymentBridge,
  PaymentBridgeChildrenProps,
  PaymentDetails,
  PaymentMethod,
  PaymentMethodData,
  PaymentOptions,
  PaymentStatus
} from '@/features/payment'
import { IFile } from '@/packages/ui'

import styles from './PaymentModule.module.scss'

interface PaymentModuleProps {
  cashAmount?: number
  platformFee: number
  stripeFee: number
  onPay?: (
    data: PaymentBridgeChildrenProps
  ) => (data: PaymentMethodData) => void
  onPayLater?: () => void
  onSelect?: (method: PaymentMethod) => void
  status?: PaymentStatus
  details?: any
  setRecordPaymentFiles?: (files: IFile[] | undefined) => void
}

const PaymentModule: FC<PaymentModuleProps> = ({
  platformFee,
  cashAmount = 0,
  onPay,
  onPayLater,
  onSelect,
  status,
  stripeFee,
  details,
  setRecordPaymentFiles
}) => (
  <PaymentBridge
    initialDetails={details}
    payAmount={cashAmount + platformFee}
    initialStatus={status}
  >
    {(paymentBridgeProps: PaymentBridgeChildrenProps) => (
      <>
        <PaymentDetails
          className={styles.detailsSection}
          cashAmount={cashAmount}
          platformFee={platformFee}
          stripeFee={stripeFee}
        />

        <div className={styles.paymentMethods}>
          <PaymentOptions
            title="Payment Method"
            withRecordPayment
            onPayLater={onPayLater}
            onPay={onPay?.(paymentBridgeProps)}
            cashAmount={cashAmount}
            onSelect={onSelect}
            setRecordPaymentFiles={setRecordPaymentFiles}
          />
        </div>
      </>
    )}
  </PaymentBridge>
)

export default PaymentModule
