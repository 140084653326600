import clsx from 'clsx'
import React, { FC, useCallback, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { Marker } from '@/common/helpers'
import { SAFE_NOTE_TEMPLATE_ANCHORS, ViewSafeAs } from '@/features/safe-note'
import {
  SafeNoteDocument,
  SafeNoteNavigation,
  SignatureProps,
  TermsControl
} from '@/features/safe-note/components'
import {
  ConfirmSwitchMfn,
  EditDiscountRateModal,
  EditValuationCapModal
} from '@/features/safe-note/modals'
import { useModal } from '@/packages/hooks'
import { Portal, Spacer } from '@/packages/ui'

import { SafeNoteContextProvider, SafeNoteContextValue } from './context'
import styles from './SafeNote.module.scss'

export interface SafeNoteProps {
  companyName?: string
  senderName?: string
  recipientName?: string
  safeAmount?: number
  cashAmount?: number
  discountRate?: number
  discountRateActive?: boolean
  valuationCap?: number
  valuationCapActive?: boolean
  safeAllocation?: number
  safeAllowance?: number
  mfn?: boolean
  stateOfIncorporation?: string

  signatureData: SignatureProps

  initialFullScreen?: boolean
  viewAs: ViewSafeAs
  viewMode?: boolean
  onUpdateData?: (data: any, immediateUpdate?: boolean) => void
  onFullScreen?: (newV: boolean, prevV: boolean) => void
  onDownload?: () => void
}

enum ModalType {
  SWITCH_MFN = 'switchMfn',
  EDIT_DISCOUNT = 'editDiscountRate',
  EDIT_VALUATION = 'editValuationCap'
}

const SafeNote: FC<SafeNoteProps> = (props: SafeNoteProps) => {
  const {
    discountRate,
    valuationCap,
    mfn,
    companyName,
    senderName,
    recipientName,
    safeAmount,
    cashAmount,
    safeAllowance,
    safeAllocation,
    discountRateActive,
    valuationCapActive,
    stateOfIncorporation,

    signatureData,

    initialFullScreen = false,
    viewAs,
    viewMode,
    onUpdateData,
    onFullScreen,
    onDownload
  } = props

  const {
    visible: activeModal,
    closeModal,
    openModal
  } = useModal<ModalType>(undefined)

  const [showColors, setShowColors] = useState<boolean>(true)
  const [fullScreen, setFullScreen] = useState<boolean>(initialFullScreen)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false)

  const handleSwitchMfn = useCallback(
    (value: boolean) => {
      if (value) {
        openModal(ModalType.SWITCH_MFN)
        return
      }

      onUpdateData?.({ mfnActive: false }, true)
    },
    [onUpdateData]
  )

  const handleActivateMfn = useCallback(() => {
    onUpdateData?.(
      {
        discountRateActive: false,
        valuationCapActive: false,
        mfn: true
      },
      true
    )
  }, [onUpdateData])

  const handleSwitchDiscountRate = useCallback(
    (value: boolean) => {
      if (!discountRate) {
        openModal(ModalType.EDIT_DISCOUNT)
        return
      }

      onUpdateData?.(
        {
          discountRateActive: value,
          mfn: false
        },
        true
      )
    },
    [discountRate]
  )

  const handleSetNewDiscountRate = useCallback(
    (value: number | undefined) => {
      onUpdateData?.(
        {
          discountRate: value,
          mfn: false,
          discountRateActive: true
        },
        true
      )
    },
    [onUpdateData]
  )

  const handleSwitchValuationCap = useCallback(
    (value: boolean) => {
      if (!valuationCap) {
        openModal(ModalType.EDIT_VALUATION)
        return
      }

      onUpdateData?.(
        {
          valuationCapActive: value,
          mfn: false
        },
        true
      )
    },
    [valuationCap]
  )

  const handleSetNewValuationCap = useCallback(
    (data: any) => {
      onUpdateData?.(
        {
          ...data,
          valuationCapActive: true,
          mfn: false
        },
        true
      )
    },
    [onUpdateData]
  )

  const toggleFullScreen = useCallback(() => {
    setFullScreen((prev) => {
      onFullScreen?.(!prev, prev)

      return !prev
    })
    setSidebarCollapsed(false)
  }, [onFullScreen])

  const toggleSidebar = useCallback(() => {
    setSidebarCollapsed((prev) => !prev)
  }, [])

  const handleNavigationItemClick = useCallback(
    (anchor: string, defaultStyles?: any) => {
      const marker = new Marker(anchor)

      if (anchor === SAFE_NOTE_TEMPLATE_ANCHORS.COMPANY) {
        marker.mark(companyName, {
          withTimeout: true,
          disableMoving: true,
          accuracy: 'complementary',
          separateWordSearch: false
        })
        return
      }

      marker.markElement({ withTimeout: true, defaultStyles })
    },
    [companyName]
  )

  const renderModals = () => {
    switch (activeModal) {
      case ModalType.SWITCH_MFN:
        return (
          <ConfirmSwitchMfn
            closeModal={closeModal}
            onConfirm={handleActivateMfn}
          />
        )
      case ModalType.EDIT_DISCOUNT:
        return (
          <EditDiscountRateModal
            discountRate={discountRate}
            closeModal={closeModal}
            onSelect={handleSetNewDiscountRate}
          />
        )
      case ModalType.EDIT_VALUATION:
        return (
          <EditValuationCapModal
            safeAllowance={safeAllowance}
            safeAllocation={safeAllocation}
            closeModal={closeModal}
            onSelect={handleSetNewValuationCap}
          />
        )

      default:
        return <></>
    }
  }

  const contextValue: SafeNoteContextValue = {
    data: {
      companyName,
      recipientName,
      senderName,
      discountRate,
      valuationCap,
      discountRateActive,
      valuationCapActive,
      mfn,
      safeAmount,
      cashAmount,
      safeAllowance,
      safeAllocation,
      stateOfIncorporation,
      hasSenderSignature: Boolean(signatureData.senderSignature),
      hasInvestorSignature: Boolean(
        signatureData.recipientSignature || signatureData.tempSignature
      ),
      signatureData
    },

    viewMode,
    viewAs,
    fullScreen,
    setFullScreen,
    toggleFullScreen,
    showColors,
    setShowColors,
    sidebarCollapsed,
    toggleSidebar,

    onSwitchMfn: handleSwitchMfn,
    onSwitchDiscountRate: handleSwitchDiscountRate,
    onSwitchValuationCap: handleSwitchValuationCap,
    onEditDiscountRate: () => openModal(ModalType.EDIT_DISCOUNT),
    onEditValuationCap: () => openModal(ModalType.EDIT_VALUATION),
    onNavigationItemClick: handleNavigationItemClick,
    onDownload
  }

  return (
    <SafeNoteContextProvider {...contextValue}>
      {!viewMode && renderModals()}

      <div className={styles.container}>
        <ConditionalRender condition={!viewMode}>
          <TermsControl />
          <Spacer size={25} />
        </ConditionalRender>

        <Portal wrapperId="safe-note-full-screen-portal" disabled={!fullScreen}>
          <div
            className={clsx(
              styles.safeNoteContainer,
              fullScreen && styles.fullScreen,
              sidebarCollapsed && styles.sidebarCollapsed
            )}
          >
            <SafeNoteNavigation />
            <SafeNoteDocument />
          </div>
        </Portal>
      </div>
    </SafeNoteContextProvider>
  )
}

export default SafeNote
