import { AnimatePresence, motion } from 'framer-motion'
import { FC, ReactNode } from 'react'

interface ToggleTransitionProps {
  key: string
  durationOnVisible?: number
  durationOnHidden?: number
  visible?: boolean
  initial?: boolean
  // if false then animation won't be playing
  active?: boolean
  children: ReactNode
}

const OpacityTransition: FC<ToggleTransitionProps> = ({
  key,
  durationOnHidden = 0.1,
  durationOnVisible = 0.1,
  visible = true,
  initial = false,
  active = true,
  children
}: ToggleTransitionProps) => {
  const containerVariants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: durationOnHidden
      }
    },
    visible: {
      opacity: 1,
      transition: {
        duration: durationOnVisible,
        delay: 0.2
      }
    }
  }

  if (!active) return <>{children}</>

  return (
    <AnimatePresence initial={initial}>
      {visible && (
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          key={key}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default OpacityTransition
