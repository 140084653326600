import clsx from 'clsx'
import { noop } from 'lodash'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { ConditionalRender } from '@/common/components'
import { useToast } from '@/common/hooks'
import { StoreType, withStore } from '@/common/store'
import { useAppContext } from '@/context'
import { SafeFor } from '@/features/safe-note'
import {
  PaymentStep,
  RecipientStep,
  ReviewStep,
  SafeNoteSummary,
  TermsStep,
  TransactionType
} from '@/features/safe-note/components'
import { CreateSafeContextProvider } from '@/features/safe-note/context'
import { getRawSafeTemplate } from '@/features/safe-note/utils/raw-template'
import { useSmartState } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import {
  Button,
  FullScreenLoading,
  StepperNavigation,
  TextTypes,
  useStepper
} from '@/packages/ui'
import { IStep } from '@/packages/ui/stepper/types'
import { useNavigator } from '@/router'

import styles from './SafeNoteView.module.scss'
import safeNoteSteps from './steps'

const mapStateToProps = ({ user, safeNote }: StoreType) => ({
  user: user.me,
  fetchOne: safeNote.fetchOne,
  createSafeNoteRequest: safeNote.create,
  createSafeNoteLoading: safeNote.functionLoading.create,
  generatePdf: safeNote.generatePdf,
  generatePdfLoading: safeNote.functionLoading.generatePdf,
  companies: user.companies
})

interface StateType {
  data?: any
  tempData?: any
  nextDisabled?: boolean
}

type CreateSafeViewProps = ReturnType<typeof mapStateToProps> & {
  viewMode?: boolean
}

const SafeNoteView: FC<CreateSafeViewProps> = ({
  user,
  companies,
  createSafeNoteRequest,
  viewMode = true,
  generatePdf,
  generatePdfLoading,
  fetchOne,
  createSafeNoteLoading
}: CreateSafeViewProps) => {
  const navigate = useNavigator()

  const { companyId } = useParams()
  const currentCompany = companies?.find?.(
    (company: any) => company.id === companyId
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const { setNavbarTitle } = useAppContext()

  const [steps, setSteps] = useState<IStep[]>(safeNoteSteps)
  const [state, setState] = useSmartState<StateType>({
    data: {
      // safeFor: SafeFor.AGENCY,
      // cashAmount: 3000,
      // safeAmount: 3000,
      // transactionType: TransactionType.SAFE_PLUS_CASH,
      // recipientEmail: '3@mgail.com',
      // recipientName: '3@mgail.com',
      // mfn: true
    },
    tempData: {},
    nextDisabled: false
  })

  const {
    currentStep,
    prevStep,
    setCurrentStep,
    nextStep,
    completed,
    isLastStep
  } = useStepper(0, steps?.filter?.((p) => !p.hidden).length)
  const { showWarning } = useToast()

  useEffect(() => {
    const safeNoteId = searchParams.get('safeNoteId')

    if (safeNoteId) {
      navigate.toViewSafeNote(safeNoteId)
    }

    setNavbarTitle('Create SAFE')
  }, [])

  useEffect(() => {
    const safeNoteId = searchParams.get('safeNoteId')
    const listener = (event: BeforeUnloadEvent) => {
      event.preventDefault()

      if (safeNoteId) {
        return true
      }

      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''

      // Display the warning message
      return 'Are you sure you want to leave this page?'
    }

    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [searchParams])

  const addPaymentTabIfNeeded = useCallback(() => {
    const isSafePlusCash =
      state.data?.transactionType === TransactionType.SAFE_PLUS_CASH &&
      state.data?.safeFor === SafeFor.AGENCY

    setSteps((prev: IStep[]) =>
      prev.map((step: IStep) => {
        if (step.id === 'payment') {
          return {
            ...step,
            hidden: !isSafePlusCash
          }
        }
        return step
      })
    )
  }, [state.data])

  const updateTempData = useCallback(
    (newData: any, immediateUpdate = false) => {
      setState({ tempData: newData })

      if (immediateUpdate) {
        setState((prev) => ({ data: { ...prev.data, ...newData } }))
      }
    },
    []
  )

  // Update data from current profile
  useEffect(() => {
    updateTempData(
      {
        companyName: currentCompany?.name,
        stateOfIncorporation: currentCompany?.stateOfIncorporation,
        senderName: user?.fullName,
        currentCompany
      },
      true
    )
  }, [currentCompany, user, updateTempData])

  const checkIfSafeNoteCreated = useCallback(async () => {
    const id = searchParams.get('safeNoteId')

    if (!id) return undefined

    try {
      return fetchOne({ data: { id } })
    } catch (err) {
      return false
    }
  }, [searchParams])

  useEffect(() => {
    // checkIfSafeNoteCreated().then((exist) => {
    //   if (exist && !completed) navigate.toDashboard()
    // })
  }, [checkIfSafeNoteCreated])

  const createSafeNote = useCallback(
    async (extraData?: any) => {
      const { data } = state

      if (!currentCompany) {
        showWarning(
          "Cannot create a SAFE. Seems like you do not have entrepreneur profile. Let's get one first"
        )
        return undefined
      }

      const response = await createSafeNoteRequest({
        data: {
          senderCompanyId: companyId,
          recipientEmail: data.recipientEmail,
          recipientName: data.recipientName,
          mfn: data.mfn,
          discountRate: data?.discountRate ? Number(data?.discountRate) : 0,
          safeAmount: Number(data?.safeAmount),
          cashAmount: Number(data?.cashAmount),
          paymentId: data.paymentId,
          signature: data.signatureData?.signature,
          signName: data.signatureData?.name,
          safeFor: data.safeFor,
          ...(data?.valuationCap
            ? {
                safeAllowance: Number(data?.safeAllowance),
                safeAllocation: Number(data?.safeAllocation)
              }
            : {}),
          ...extraData
        }
      })

      if (response) {
        setSearchParams((prev) => ({
          ...prev,
          safeNoteId: response.id
        }))
      }

      return response
    },
    [companyId, state.data]
  )

  const handleDownloadPdf = useCallback(() => {
    const template = getRawSafeTemplate()
    const fileName = `SAFE_${state.data?.recipientName}`

    generatePdf({
      data: {
        html: template,
        fileName
      }
    })
  }, [state.data, generatePdf])

  const handleBack = useCallback(() => {
    if (currentStep === 0) {
      navigate.toDashboard()
      return
    }

    prevStep()
    addPaymentTabIfNeeded()
  }, [currentStep, prevStep])

  const handleSelectStep = useCallback(
    (index: number) => {
      if (!state.nextDisabled || index < currentStep) {
        setCurrentStep(index)
      }
    },
    [state.nextDisabled, setCurrentStep]
  )

  const handleNext = useCallback(async () => {
    setState((prev: StateType) => ({
      data: { ...prev.data, ...state.tempData }
    }))
    nextStep()
    addPaymentTabIfNeeded()

    if (isLastStep) {
      if (viewMode) {
        navigate.toDashboard()
        return
      }

      if (!(await checkIfSafeNoteCreated())) {
        createSafeNote()
      }
    }
  }, [state, createSafeNote, checkIfSafeNoteCreated, nextStep, isLastStep])

  useEffect(() => {
    const header = document.querySelector('#safe-note-layout__header header')
    const content = document.querySelector('#safe-note-layout__content')

    if (currentStep === 2) {
      if (header) {
        header.classList.add(styles.static)
      }

      if (content) {
        content.classList.add(styles.fitContent)
      }
    } else if (header) {
      header.classList.remove(styles.static)

      if (content) {
        content.classList.remove(styles.fitContent)
      }
    }

    if (currentStep === 3 && content) {
      content.classList.add(styles.fitContent)
    }
  }, [currentStep])

  const ComponentMapper = [
    <RecipientStep key="recipientStep" />,
    <TermsStep key="termsStep" />,
    <ReviewStep key="reviewStep" />,
    <PaymentStep key="paymentStep" onPayLater={handleNext} />
  ]

  return (
    <div className={styles.container}>
      <FullScreenLoading loading={generatePdfLoading} />
      <ConditionalRender
        condition={!completed}
        fallbackElement={
          <SafeNoteSummary
            safeAmount={state.data?.safeAmount}
            cashAmount={state.data?.cashAmount}
            safePercent={state.data?.safePercent}
            cashPercent={state.data?.cashPercent}
            safeFor={state.data?.recipientName}
          />
        }
      >
        <CreateSafeContextProvider
          data={state.data}
          updateData={updateTempData}
          setNextDisabled={
            !viewMode
              ? (value: boolean) => setState({ nextDisabled: value })
              : noop
          }
          createSafeNote={createSafeNote}
          checkIfSafeNoteCreated={checkIfSafeNoteCreated}
          viewMode={viewMode}
          downloadPdf={handleDownloadPdf}
        >
          <div
            className={clsx(
              styles.content,
              (currentStep === 2 || currentStep === 3) && styles.hidden
            )}
          >
            {ComponentMapper[currentStep]}
          </div>
        </CreateSafeContextProvider>

        <div
          className={clsx(styles.footer, currentStep === 2 && styles.static)}
        >
          <Button
            fullHeight
            width="default"
            onClick={handleNext}
            disabled={state.nextDisabled || createSafeNoteLoading}
            uppercase
          >
            {isLastStep ? 'Finish' : 'Next'}
          </Button>
          <StepperNavigation
            type="small"
            activeStep={currentStep}
            className={styles.navigation}
            steps={steps}
            itemTextType={TextTypes.BODY_DEFAULT}
            markPreviousAsCompleted={false}
            allowManuallyChangeStep
            onSelectStep={handleSelectStep}
          />

          <Button
            fullHeight
            width="default"
            className={styles.backBtn}
            appearance="secondary"
            onClick={handleBack}
            uppercase
          >
            <SharedIcons.Arrow size={30} className={styles.exitIcon} />
            Back
          </Button>
        </div>
      </ConditionalRender>
    </div>
  )
}

export default withStore(mapStateToProps)(SafeNoteView)
