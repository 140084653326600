import React, { FC, useState } from 'react'

import { ToastService } from '@/common/services'
import { StoreType, useStore } from '@/common/store'
import { ISafeNote } from '@/features/safe-note'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  Button,
  FontWeight,
  Heading,
  HeadingTypes,
  Modal,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'
import { SAFE } from '@/packages/ui/table/columns'
import { useNavigator } from '@/router'

import styles from './PendingSafesModal.module.scss'

interface PendingSafesModalProps {
  closeModal: () => void
  safesList: ISafeNote[]
  type: ProfileTypes
}

const PendingSafesModal: FC<PendingSafesModalProps> = (props) => {
  const { type, closeModal, safesList = [] } = props

  const [loading, setLoading] = useState(false)
  const navigate = useNavigator()

  const isEntrepreneur = type === ProfileTypes.ENTREPRENEUR

  const sendReminder = useStore(
    (store: StoreType) => store.safeNote.notifyToSign
  )

  const handleSendReminder = async () => {
    setLoading(true)

    try {
      await Promise.all(
        safesList.map((safe: ISafeNote) =>
          sendReminder({
            data: { id: safe.id }
          })
        )
      )

      closeModal()
      ToastService.showSuccess('All reminders were sent')
    } catch (e) {
      ToastService.showError('We faced some problems with sending reminders')
    }

    setLoading(false)
  }

  return (
    <Modal size="sm" id="addCompanyModal" visible setVisible={closeModal}>
      <div>
        <Heading
          type={HeadingTypes.H3}
          align={TextAlign.LEFT}
          className="tw-mb-[12px]"
        >
          Pending SAFEs
        </Heading>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th colSpan={2}>Email</th>
          </tr>
        </thead>
        <tbody>
          {safesList.map((safeNote) => (
            <tr key={safeNote.id}>
              <td>
                <SAFE row={safeNote} />
              </td>
              <td>
                {isEntrepreneur
                  ? safeNote.recipient.email
                  : safeNote.sender.email}
              </td>
              <td>
                <Text
                  type={TextTypes.BODY_SMALL}
                  color={Color.primary400}
                  weight={FontWeight.SEMIBOLD}
                  clickable
                  onClick={() => navigate.toViewSafeNote(safeNote.id)}
                >
                  View SAFE
                </Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Row justify="between" items="center" gap={15}>
        <Button
          width="default"
          type="button"
          appearance="secondary"
          onClick={closeModal}
          uppercase
        >
          Cancel
        </Button>

        <Button
          width="default"
          type="button"
          appearance="primary"
          onClick={handleSendReminder}
          uppercase
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Send Reminder'}
        </Button>
      </Row>
    </Modal>
  )
}

export default PendingSafesModal
