import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import Sticky from 'react-stickynode'

import { ConditionalRender } from '@/common/components'
import OpacityTransition from '@/common/transitions/OpacityTransition'
import { SAFE_NOTE_TEMPLATE_ANCHORS } from '@/features/safe-note'
import {
  NavigationMenu,
  SAFE_NOTE_COLORS
} from '@/features/safe-note/components'
import { MenuItemProps } from '@/features/safe-note/components/NavigationMenu/MenuItem'
import { defaultStyles } from '@/features/safe-note/components/SafeNote/components/SafeNoteTemplate/SafeNoteTemplate'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'
import { SharedIcons } from '@/packages/icons'
import { Row, Spacer, Switch, Text, TextTypes } from '@/packages/ui'

import styles from './SafeNoteNavigation.module.scss'

interface SafeNavigationProps {}

const SafeNoteNavigation: FC<SafeNavigationProps> = () => {
  const {
    data,
    sidebarCollapsed,
    toggleSidebar,
    fullScreen,
    showColors,
    setShowColors,
    onNavigationItemClick: onItemClick,
    viewMode
  } = useSafeNoteContext()

  const {
    companyName,
    recipientName,
    senderName,
    mfn,
    safeAmount,
    discountRateActive,
    discountRate,
    valuationCapActive,
    valuationCap,
    hasSenderSignature,
    hasInvestorSignature
  } = data

  const hasInvestmentAmount = !!safeAmount
  const hasDiscountRate = viewMode
    ? discountRate
    : discountRateActive && discountRate
  const hasValuationCap = viewMode
    ? valuationCap
    : valuationCapActive && valuationCap

  const [generalMenu, setGeneralMenu] = useState<MenuItemProps>({
    id: 'generalMenuItem',
    title: 'General',
    status: true,
    withBackground: true,
    required: false,
    subMenu: []
  })

  const [termsMenu, setTermsMenu] = useState<MenuItemProps>({
    id: 'termsMenuItem',
    title: 'Terms',
    status: true,
    withBackground: true,
    required: false,
    subMenu: []
  })

  const [signatureMenu, setSignatureMenu] = useState<MenuItemProps>({
    id: 'signatureMenuItem',
    title: 'Signatures',
    status: true,
    withBackground: true,
    required: true,
    subMenu: []
  })

  const hasMissingItems = (_menu: any) => _menu.some((p: any) => !p.status)

  const updateGeneralMenu = () => {
    const subMenu = [
      {
        id: 'companyName',
        title: 'Company Name',
        status: !!companyName,
        statusRequired: true,
        onClick: () => onItemClick?.(SAFE_NOTE_TEMPLATE_ANCHORS.COMPANY)
      },
      {
        id: 'recipientName',
        title: 'Investor Name',
        status: !!recipientName,
        statusRequired: true,
        onClick: () => onItemClick?.(SAFE_NOTE_TEMPLATE_ANCHORS.INVESTOR)
      },
      {
        id: 'safeAmount',
        title: 'Investment Amount',
        status: hasInvestmentAmount,
        statusRequired: true,
        onClick: () => onItemClick?.(SAFE_NOTE_TEMPLATE_ANCHORS.AMOUNT)
      }
    ]

    setGeneralMenu((prev) => ({
      ...prev,
      status: !hasMissingItems(subMenu),
      subMenu
    }))
  }

  const updateTermsMenu = () => {
    const discountRateColors = [SAFE_NOTE_COLORS.DISCOUNT_RATE]
    const valuationCapColors = [SAFE_NOTE_COLORS.VALUATION_CAP]

    if (hasDiscountRate && hasValuationCap) {
      discountRateColors.push(SAFE_NOTE_COLORS.DISCOUNT_AND_VALUATION)
      valuationCapColors.push(SAFE_NOTE_COLORS.DISCOUNT_AND_VALUATION)
    }

    const mfnColors = []
    if (mfn) mfnColors.push(SAFE_NOTE_COLORS.MFN)

    const subMenu = [
      {
        id: 'mfn',
        title: 'MFN',
        status: mfn,
        required: false,
        colors: mfn ? [SAFE_NOTE_COLORS.MFN] : [],
        showColors
      },
      {
        id: 'discountRate',
        title: 'Discount Rate',
        status: hasDiscountRate,
        required: false,
        colors: discountRateColors,
        showColors,
        onClick: () =>
          onItemClick?.(
            SAFE_NOTE_TEMPLATE_ANCHORS.DISCOUNT_RATE,
            defaultStyles.discountRate
          )
      },
      {
        id: 'valuationCap',
        title: 'Valuation Cap',
        status: hasValuationCap,
        required: false,
        colors: valuationCapColors,
        showColors,
        onClick: () =>
          onItemClick?.(
            SAFE_NOTE_TEMPLATE_ANCHORS.VALUATION_CAP,
            defaultStyles.valuationCap
          )
      }
    ]

    setTermsMenu((prev) => ({
      ...prev,
      status: !hasMissingItems(subMenu),
      subMenu
    }))
  }

  const updateMenu = () => {
    updateGeneralMenu()
    updateTermsMenu()

    const signatureSubMenu = [
      {
        id: 'mySignature',
        title: senderName || 'Company',
        status: hasSenderSignature,
        required: true,
        onClick: () =>
          onItemClick?.(SAFE_NOTE_TEMPLATE_ANCHORS.MY_SIGNATURE, {
            backgroundColor: 'transparent'
          })
      },
      {
        id: 'theirSignature',
        title: recipientName || 'Investor',
        status: hasInvestorSignature,
        required: true,
        onClick: () =>
          onItemClick?.(SAFE_NOTE_TEMPLATE_ANCHORS.THEIR_SIGNATURE, {
            backgroundColor: 'transparent'
          })
      }
    ]

    setSignatureMenu((prev) => ({
      ...prev,
      status: !hasMissingItems(signatureSubMenu),
      subMenu: signatureSubMenu
    }))
  }

  useEffect(() => {
    updateMenu()
  }, [data])

  const Content = (
    <div
      id="safe-note-navigation-container"
      className={clsx(
        styles.container,
        fullScreen && styles.fullScreen,
        sidebarCollapsed && styles.collapsed
      )}
    >
      <ConditionalRender condition={fullScreen}>
        <div className={styles.collapseButton} onClick={toggleSidebar}>
          <SharedIcons.Chevron clickable />
        </div>
      </ConditionalRender>

      <OpacityTransition
        key="sidebar-collapsing-animation"
        visible={!sidebarCollapsed}
        active={fullScreen}
      >
        <NavigationMenu menu={[generalMenu, termsMenu]} />
        <Spacer size={5} />
        <Row
          items="center"
          justify="between"
          className={styles.highlightControl}
        >
          <Text
            type={TextTypes.BODY_DEFAULT}
            tooltip="Lorem ipsum"
            showTooltipTriggerIcon
          >
            Highlights
          </Text>
          <Spacer size={10} vertical />
          <Switch
            value={showColors}
            onChange={(e) => setShowColors?.(e.target.checked)}
          />
        </Row>
        <Spacer size={10} />
        <NavigationMenu menu={[signatureMenu]} />
      </OpacityTransition>
    </div>
  )

  return fullScreen ? Content : <Sticky top={0}>{Content}</Sticky>
}

export default SafeNoteNavigation
