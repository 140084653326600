import clsx from 'clsx'
import { noop } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ConditionalRender, Search } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import {
  AngelInvestmentCompany,
  Breadcrumbs,
  DashboardCompanyHeader,
  DashboardSummaryGoal,
  DashboardSummarySafe,
  DashboardTermSheet,
  DashboardUpdates,
  SafeNoteList
} from '@/features/dashboard'
import { useDashboardNavigationContext } from '@/features/dashboard/context'
import { ISummaryItem } from '@/features/profile/api/types'
import { defaultSummary } from '@/features/profile/constants'
import { ICompany } from '@/features/profile/types'
import { ISafeNote } from '@/features/safe-note'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Heading,
  HeadingTypes,
  Loader,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'
import { NoItems } from '@/packages/ui/table/views'
import { useNavigator } from '@/router'

import styles from './DashboardAngelView.module.scss'

const mapStateToProps = ({ profile, user, safeNote }: StoreType) => ({
  userId: user.me?.id,
  fetchSafeNotes: safeNote.fetchAll,
  fetchCompanySenders: safeNote.fetchCompanySenders,
  safeNotesList: safeNote.safeNoteList,
  investmentCompanies: safeNote.investmentCompanies,
  fetchSingleSummary: profile.fetchSingleSummary,
  fetchSummaries: profile.fetchSummaries
})

const DashboardAngelView: FC<
  { currentCompany: ICompany } & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    userId,
    fetchSafeNotes,
    fetchCompanySenders,
    safeNotesList,
    investmentCompanies,
    fetchSingleSummary,
    fetchSummaries,
    currentCompany
  } = props
  const { setSelectedProfile } = useDashboardNavigationContext()
  const navigate = useNavigator()

  const { innerCompanyId } = useParams()

  const [companySendersSummary, setCompanySendersSummary] = useState<{
    [key: string]: ISummaryItem
  }>({})
  const [summary, setSummary] = useState<ISummaryItem>(() => defaultSummary)
  const [search, setSearch] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const companyId = currentCompany.id

  const innerCompany = investmentCompanies?.find?.(
    (company) => company.id === innerCompanyId
  )

  const permission = useMemo(() => {
    if (!userId || !companyId) return TeamMemberPermission.VIEW

    if (userId === currentCompany?.owner?.id) return TeamMemberPermission.CREATE

    return (
      currentCompany?.teamMembers?.find?.(
        (teamMember: any) => teamMember.userId === userId
      )?.permission || TeamMemberPermission.VIEW
    )
  }, [currentCompany, userId, companyId])

  const goToProfileDashboard = () => {
    setSelectedProfile(ProfileTypes.ANGEL)
    navigate.toDashboard()
  }

  const toUpperLevel = () => {
    setSelectedProfile(ProfileTypes.ANGEL)
    navigate.toDashboardCompany(companyId)
  }

  const fetchData = async () => {
    setLoading(() => true)

    try {
      if (!innerCompanyId) {
        const summaryResponse = await fetchSingleSummary({
          data: { id: companyId }
        })

        setSummary(summaryResponse?.summary || defaultSummary)

        await fetchCompanySenders({
          data: { angelCompanyId: companyId }
        })

        const sendersSummaries = await fetchSummaries({
          data: { angelCompanyId: companyId }
        })

        if (Array.isArray(sendersSummaries)) {
          setCompanySendersSummary(
            sendersSummaries.reduce((acc, next) => {
              acc[next.id] = next.summary
              return acc
            }, {})
          )

          setLoading(() => false)
          return
        }

        setCompanySendersSummary({})

        setLoading(() => false)
        return
      }

      if (!investmentCompanies.length) {
        await fetchCompanySenders({
          data: { angelCompanyId: companyId }
        })
      }

      await fetchSafeNotes({
        data: {
          entrepreneurCompanyId: innerCompanyId,
          angelCompanyId: companyId
        }
      })

      setLoading(() => false)
    } catch {
      setLoading(() => false)
    }
  }

  useEffect(() => {
    if (!!innerCompanyId) {
      const customSummary = (safeNotesList || []).reduce(
        (_result: ISummaryItem, nextSafe: ISafeNote) => {
          const newResult = cloneDeep(_result)

          if (nextSafe.paid) {
            newResult.paidAmount += nextSafe.safeAmount
          } else {
            newResult.unpaidAmount += nextSafe.safeAmount
            newResult.unpaidCount += 1
          }

          newResult.totalSafeCount += 1

          return newResult
        },
        defaultSummary
      )

      setSummary(() => customSummary)
    }
  }, [safeNotesList])

  useEffect(() => {
    fetchData()
  }, [companyId, innerCompanyId])

  if (loading) {
    return <Loader width="100%" />
  }

  const OuterLevel = (
    <>
      <Row items="center" justify="between" gap={20}>
        <Row items="center" gap={14}>
          <Heading
            type={HeadingTypes.H3}
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral500}
          >
            Companies I’ve Invested In
          </Heading>
          <div className={styles.spacer} />
          <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
            {investmentCompanies.length} companies
          </Text>
        </Row>

        <div className={styles.sortingAndSearch}>
          {/* <Select */}
          {/*   small */}
          {/*   name="sort" */}
          {/*   placeholder="Sort By" */}
          {/*   value={sort} */}
          {/*   options={sortBy} */}
          {/*   onChange={(value: string) => setSort(value)} */}
          {/* /> */}
          <Search
            search={search}
            onSearchChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </Row>

      <ConditionalRender
        condition={!!investmentCompanies.length}
        fallbackElement={<NoItems />}
      >
        <div className={styles.investmentsCompanies}>
          {investmentCompanies.map((company) => (
            <AngelInvestmentCompany
              key={company.id}
              name={company.name}
              logo={company.image}
              owner={company.owner.fullName}
              onClick={() =>
                navigate.toDashboardInnerCompany(
                  companyId,
                  company.id.toString()
                )
              }
              paidSafes={
                (companySendersSummary[company.id]?.totalSafeCount || 0) -
                (companySendersSummary[company.id]?.unpaidCount || 0)
              }
              unpaidSafes={companySendersSummary[company.id]?.unpaidCount || 0}
            />
          ))}
        </div>
      </ConditionalRender>
    </>
  )

  // useEffect(() => {
  // if (!!innerCompanyId && safeNotesList?.length) {
  //   setInnerCompany(safeNotesList.filter((safeNote) => safeNote.senderCompany.id === innerCompanyId))
  // }
  // }, [innerCompanyId, safeNotesList])

  // useEffect(() => {
  //   if (innerCompanyId && !innerCompany) {
  //     navigate.toDashboardCompany(companyId)
  //   }
  // }, [innerCompany, innerCompanyId])

  const Summary = (
    <>
      <DashboardSummarySafe
        withoutAction={!!innerCompanyId}
        type="sent"
        count={summary.totalSafeCount}
        amount={summary.paidAmount + summary.unpaidAmount}
        role={ProfileTypes.ANGEL}
        permission={permission}
      />

      <DashboardSummarySafe
        withoutAction={!!innerCompanyId}
        type="unpaid"
        count={summary.unpaidCount}
        amount={summary.unpaidAmount}
        role={ProfileTypes.ANGEL}
        permission={permission}
      />
    </>
  )

  return (
    <div className="tw-w-full">
      <Breadcrumbs
        onInnerClick={toUpperLevel}
        innerCompany={innerCompany?.name}
        type={currentCompany.type}
        company={currentCompany.name}
        onTypeClick={goToProfileDashboard}
      />

      <DashboardCompanyHeader
        anotherOwner={
          !!innerCompanyId ? innerCompany?.owner?.fullName : undefined
        }
        company={innerCompanyId ? innerCompany : currentCompany}
      />

      <section
        className={clsx(
          styles.summarySection,
          !!innerCompanyId && styles.innerLevel
        )}
      >
        <ConditionalRender
          condition={!!innerCompanyId}
          fallbackElement={Summary}
        >
          <div className={styles.summarySafe}>{Summary}</div>
        </ConditionalRender>

        <DashboardSummaryGoal
          savedGoal={0}
          columnMode={!!innerCompanyId}
          safesAmount={summary.paidAmount + summary.unpaidAmount}
          pendingAmount={summary.unpaidAmount}
          role={ProfileTypes.ANGEL}
          permission={permission}
          updateGoal={noop}
          pendingCount={summary.unpaidCount}
          openModal={noop}
        />

        {!!innerCompanyId && (
          <>
            <DashboardUpdates
              role={ProfileTypes.ANGEL}
              permission={permission}
            />
            <DashboardTermSheet
              role={ProfileTypes.ANGEL}
              permission={permission}
            />
          </>
        )}
      </section>

      <ConditionalRender
        condition={!!innerCompanyId}
        fallbackElement={OuterLevel}
      >
        <SafeNoteList
          permission={TeamMemberPermission.VIEW}
          currentCompany={currentCompany}
          data={safeNotesList}
          role={ProfileTypes.ANGEL}
        />
      </ConditionalRender>
    </div>
  )
}

export default withStore(mapStateToProps)(DashboardAngelView)
