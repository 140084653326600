import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { SafeNoteIcons } from '@/features/safe-note/assets'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Spacer,
  Switch,
  Text,
  TextTypes,
  Tooltip
} from '@/packages/ui'

import styles from './TermsControlItem.module.scss'

interface TermsControlItemProps {
  title: string
  description: string
  value: string
  actionButtonText: string
  onActionClick: () => void
  switchActive?: boolean
  onSwitch?: (value: boolean) => void
  disabled?: boolean
}

const TermsControlItem: FC<TermsControlItemProps> = ({
  title,
  description,
  value,
  actionButtonText,
  onActionClick,
  switchActive = false,
  onSwitch,
  disabled = false
}: TermsControlItemProps) => {
  const hasValue = !!value

  const TooltipContent = (
    <>
      <Text type={TextTypes.BODY_SMALL} weight={FontWeight.BOLD}>
        {title}
      </Text>
      <Spacer size={8} />
      <Text type={TextTypes.BODY_SMALL}>{description}</Text>

      <ConditionalRender condition={!!actionButtonText}>
        <Spacer size={10} />
        <Text
          type={TextTypes.BODY_SMALL}
          color={Color.primary400}
          clickable
          onClick={onActionClick}
        >
          <u>
            <b>{actionButtonText}</b>
          </u>
        </Text>
      </ConditionalRender>
    </>
  )

  return (
    <Tooltip
      content={TooltipContent}
      placement="bottom"
      className={styles.tooltip}
      interactive
      disabled={disabled}
    >
      <div className={styles.container}>
        <SafeNoteIcons.Document
          color={hasValue ? Color.primary400 : Color.neutral300}
        />

        <Spacer size={6} vertical />
        <Text type={TextTypes.BODY_DEFAULT}>
          {title}:&nbsp;
          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.primary400}
            weight={FontWeight.BOLD}
            asSpan
          >
            {value}
          </Text>
        </Text>

        <Spacer size={6} vertical />
        <ConditionalRender
          condition={hasValue}
          fallbackElement={
            <SharedIcons.Info size={20} color={Color.primary400} />
          }
        >
          <SharedIcons.Edit
            size={20}
            color={Color.primary400}
            clickable
            onClick={onActionClick}
          />
        </ConditionalRender>

        <Spacer size={13} vertical />
        <div className={styles.switcher}>
          <Switch
            value={switchActive}
            onChange={(e) => onSwitch?.(e.target.checked)}
            disabled={disabled}
          />
        </div>
      </div>
    </Tooltip>
  )
}

export default TermsControlItem
