import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import NoteIcon from '@/features/dashboard/assets/images/note.png'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './NoItems.module.scss'

interface NoItemsProps {
  img?: string
  title?: string
  imgWidth?: number
  description?: string
  action?: string
  onActionClick?: () => void
}

const DEFAULT_TITLE = "You don't have any SAFE Notes"
const DEFAULT_DESCRIPTION =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'

const NoItems: FC<NoItemsProps> = ({
  action,
  onActionClick,
  img = NoteIcon,
  imgWidth = 118,
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION
}) => (
  <div className={styles.wrapper}>
    <img src={img} alt="Note Icon" width={imgWidth} />
    <Heading type={HeadingTypes.H3}>{title}</Heading>
    <Text
      align={TextAlign.CENTER}
      type={TextTypes.BODY_DEFAULT}
      className={styles.description}
    >
      {description}
    </Text>

    <ConditionalRender condition={!!action}>
      <Button width="default" onClick={onActionClick} className="tw-mt-[16px]">
        <Row items="center" gap={5}>
          <SharedIcons.PlusThin color={Color.neutral0} size={20} />
          {action}
        </Row>
      </Button>
    </ConditionalRender>
  </div>
)

export default NoItems
