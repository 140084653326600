import React, { FC, useCallback, useEffect, useState } from 'react'

import { FormatterHelper } from '@/common/helpers'
import { useSafeNoteContext } from '@/features/safe-note/components/SafeNote/context'

import { TermsControlItem } from './components/TermsControlItem'
import styles from './TermsControl.module.scss'

const description =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor elit incididunt ut labore et dolore .'

interface TermsControlProps {
  disabled?: boolean
}

const TermsControl: FC<TermsControlProps> = ({
  disabled = false
}: TermsControlProps) => {
  const {
    data: {
      discountRate,
      discountRateActive,
      valuationCap,
      valuationCapActive,
      mfn
    },
    onEditDiscountRate,
    onSwitchDiscountRate,
    onSwitchValuationCap,
    onSwitchMfn,
    onEditValuationCap
  } = useSafeNoteContext()

  const [controls, setControls] = useState<any>([])

  const generateControls = useCallback(() => {
    setControls([
      {
        name: 'mfn',
        title: 'MFN',
        description,
        showEdit: false,
        showValue: false,
        value: null,
        switchActive: mfn,
        onSwitch: onSwitchMfn
      },
      {
        name: 'discountRate',
        title: 'Discount Rate',
        description,
        actionButtonText: 'Edit Discount Rate',
        onActionClick: onEditDiscountRate,
        onSwitch: onSwitchDiscountRate,
        showValue: true,
        value: !!discountRate
          ? FormatterHelper.toPercentage(discountRate)
          : undefined,
        switchActive: discountRateActive
      },
      {
        name: 'valuationCap',
        title: 'Valuation Cap',
        description,
        onSwitch: onSwitchValuationCap,
        onActionClick: onEditValuationCap,
        actionButtonText: 'Edit Valuation Cap',
        showValue: true,
        value: !!valuationCap
          ? FormatterHelper.toCurrency(valuationCap)
          : undefined,
        switchActive: valuationCapActive
      }
    ])
  }, [
    mfn,
    onSwitchMfn,
    discountRate,
    discountRateActive,
    onEditDiscountRate,
    valuationCap,
    valuationCapActive,
    onEditValuationCap
  ])

  useEffect(() => {
    generateControls()
  }, [generateControls])

  const renderControls = useCallback(
    () =>
      controls.map((control: any) => (
        <TermsControlItem key={control.name} disabled={disabled} {...control} />
      )),
    [controls]
  )

  return (
    <div className={styles.container} id="terms-control-item">
      {renderControls()}
    </div>
  )
}

export default TermsControl
