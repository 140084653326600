import clsx from 'clsx'
import React, { FC } from 'react'
import { TooltipRenderProps } from 'react-joyride'

import { ConditionalRender } from '@/common/components'
import step1 from '@/features/onboarding/assets/images/Step1.svg'
import step3 from '@/features/onboarding/assets/images/Step3.svg'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  ClickEvent,
  Disabled,
  FontWeight,
  Heading,
  HeadingTypes,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './OnboardingTooltip.module.scss'
import TooltipProgressBar from './TooltipProgressBar'

const images = [step1, undefined, step3]

export const OnboardingTooltip: FC<TooltipRenderProps> = ({
  isLastStep,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
  size,
  index
}: TooltipRenderProps) => {
  const content = [
    <Text
      type={TextTypes.BODY_SMALL}
      color={Color.neutral400}
      key="step-1-content"
    >
      Lorem ipsum dolor sit amet, <span className={styles.toggleOff} />{' '}
      consectetur adipiscing elit, sed <span className={styles.toggleOn} /> e
      iusmod tempor incididunt ut labore et dolore magna aliqua lore ipsum dolor
      sit amet, consectetur adipiscing elit, sed do.
    </Text>,
    <Text
      type={TextTypes.BODY_SMALL}
      color={Color.neutral400}
      key="step-2-content"
    >
      Lorem ipsum dolor sit amet,{' '}
      <div className={styles.svgWrapper}>
        <SharedIcons.Search />
      </div>{' '}
      consectetur adipiscing elit, sed{' '}
      <div className={styles.svgWrapper}>
        <SharedIcons.Share />
      </div>{' '}
      e iusmod tempor incididunt ut labore et dolore magna aliqua{' '}
      <div className={styles.svgWrapper}>
        <SharedIcons.FullSize />
      </div>{' '}
      lore ipsum dolor sit{' '}
      <div className={styles.svgWrapper}>
        <SharedIcons.Download />
      </div>{' '}
      amet, consectetur adipiscing elit, sed do.
    </Text>,
    <Text
      type={TextTypes.BODY_SMALL}
      color={Color.neutral400}
      key="step-3-content"
    >
      Lorem ipsum dolor sit amet, <div className={clsx(styles.circles)} />
      consectetur adipiscing elit <span className={styles.toggleOn} />, sed e
      iusmod tempor incididunt ut labore et dolore magna aliqua lore ipsum dolor
      sit <div className={clsx(styles.circles)} /> amet, consectetur adipiscing
      elit, sed do.
    </Text>
  ]

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <div {...tooltipProps} className={styles.onboarding}>
      <TooltipProgressBar size={size} step={index} />
      <ConditionalRender condition={[0, 2].includes(index)}>
        <div className={styles.imgWrapper}>
          <img src={images[index]} alt="tooltipMask" />
        </div>
      </ConditionalRender>
      {step.title ? (
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-[12px]">
          <Heading
            type={HeadingTypes.H2}
            weight={FontWeight.BOLD}
            className={styles.title}
          >
            {step.title}
          </Heading>
          <div>
            <Text type={TextTypes.BODY_SMALL}>{index + 1}</Text>/
            <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
              {size}
            </Text>
          </div>
        </div>
      ) : null}
      {content[index]}
      <div className={styles.footer}>
        <Disabled
          disabled={index === 0}
          onClick={backProps.onClick as ClickEvent<Element>}
        >
          <SharedIcons.Arrow size={30} className={styles.backIcon} />
        </Disabled>
        <Button
          uppercase
          appearance="ordinary"
          onClick={skipProps.onClick}
          className={styles.skipBtn}
          width="fit"
        >
          {isLastStep ? 'Got it' : 'Skip'}
        </Button>
        <SharedIcons.Arrow
          size={30}
          className={styles.nextIcon}
          onClick={primaryProps.onClick as ClickEvent<Element>}
        />
      </div>
    </div>
  )
}
