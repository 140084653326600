export const tourStyles = {
  spotlight: {
    borderRadius: '20px',
    padding: 0
  },
  options: {
    overlayColor: 'rgba(11, 20, 31, 0.8)',
    beaconSize: 60,
    width: 500,
    arrowColor: 'white'
  }
}
