import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import ReactJoyride, { Step } from 'react-joyride'
import { useLocalStorage, useLockedBody } from 'usehooks-ts'

import { StoreType, useStore } from '@/common/store'
import { OnboardingTooltip, state, tourStyles } from '@/features/onboarding'
import { ViewSafeAs } from '@/features/safe-note'
import { SafeNote } from '@/features/safe-note/components'
import { useCreateSafeContext } from '@/features/safe-note/context'
import { SignatureData } from '@/features/safe-note/modals'

import styles from './ReviewStep.module.scss'

const ReviewStep = () => {
  const { me } = useStore((store: StoreType) => store.user)

  const { data, viewMode, updateData, downloadPdf, setNextDisabled } =
    useCreateSafeContext()
  const [, setLocked] = useLockedBody(false, 'root')
  const { steps } = state

  const noTermsSelected =
    !data.mfn && !data.valuationCapActive && !data.discountRateActive

  const finishDisabled = noTermsSelected || !data.signatureData?.signature

  const [viewedOnboarding, setViewedOnboarding] = useLocalStorage(
    'viewedOnboarding',
    { [me?.id || '']: false }
  )

  const joyrideCallback = React.useCallback((joyrideState: any) => {
    if (joyrideState.action === 'reset') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })

      setViewedOnboarding({ [me?.id || '']: true })
    }
  }, [])

  useEffect(() => {
    if (viewedOnboarding) setLocked(true)
    setLocked(false)
  }, [viewedOnboarding])

  useEffect(() => {
    setNextDisabled(false)
  }, [])

  useEffect(() => {
    setNextDisabled(finishDisabled)
  }, [finishDisabled])

  return (
    <div className={styles.container}>
      {!viewedOnboarding?.[me?.id || ''] && (
        <ReactJoyride
          steps={steps as Step[]}
          continuous
          disableScrolling
          floaterProps={{ disableFlip: true }}
          tooltipComponent={OnboardingTooltip}
          styles={tourStyles}
          showProgress
          callback={joyrideCallback}
        />
      )}
      <SafeNote
        {...data}
        viewAs={ViewSafeAs.SENDER}
        signatureData={{
          senderName: data.signatureData?.name || data.senderName,
          senderSignDate: new Date(),
          senderSignature: data.signatureData,
          allowSign: true,
          onAddSignature: (_data: SignatureData) => {
            updateData({ signatureData: _data }, true)
          }
        }}
        viewMode={viewMode}
        onUpdateData={updateData}
        onDownload={downloadPdf}
      />
    </div>
  )
}

export default observer(ReviewStep)
