import 'tippy.js/animations/shift-away-subtle.css'
import './tippy-styles.scss'

import Tippy, { TippyProps } from '@tippyjs/react'
import clsx from 'clsx'
import React, { FC, PropsWithChildren, ReactElement, ReactNode } from 'react'

import { ConditionalRender } from '@/common/components'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Row, Text, TextTypes, TooltipAppearance } from '@/packages/ui'

import styles from './Tooltip.module.scss'
import { TooltipKind } from './types'

interface IProps {
  kind?: TooltipKind
  appearance?: TooltipAppearance
  disabled?: boolean
}

type TooltipProps = PropsWithChildren<
  Omit<TippyProps, 'children' | 'animation'> & IProps
>

const getIcon = (type: TooltipKind, big?: boolean): ReactNode | null => {
  const sizeProps = {
    width: big ? 30 : 15,
    height: big ? 30 : 15
  }

  switch (type) {
    case TooltipKind.INFO: {
      return <SharedIcons.Info {...sizeProps} color={Color.primary400} />
    }
    case TooltipKind.WARNING: {
      return <SharedIcons.Warning {...sizeProps} color={Color.warning300} />
    }
    default: {
      return null
    }
  }
}

const Tooltip: FC<TooltipProps> = ({
  kind,
  content,
  children,
  placement = 'top',
  className,
  disabled,
  appearance,
  ...rest
}: TooltipProps) => {
  const TooltipContent = (
    <div
      className={clsx(
        styles.tooltip,
        !!kind && styles[kind],
        appearance && styles[appearance],
        className
      )}
    >
      <ConditionalRender
        condition={!!kind}
        fallbackElement={content as ReactElement}
      >
        <Row gap={16}>
          {getIcon(kind || TooltipKind.INFO, true)}
          <Text type={TextTypes.BODY_SMALL}>{content}</Text>
        </Row>
      </ConditionalRender>
    </div>
  )

  if (disabled) return children as JSX.Element

  return (
    <Tippy
      arrow={false}
      content={TooltipContent}
      placement={placement}
      animation="shift-away-subtle"
      {...rest}
    >
      {!!kind && !children ? (
        <div className={styles.iconHolder}>{getIcon(kind)}</div>
      ) : (
        (children as JSX.Element)
      )}
    </Tippy>
  )
}

export default Tooltip
