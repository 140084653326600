import React, { FC, useCallback, useMemo } from 'react'

import { Table } from '@/common/components'
import { SafeNoteButton } from '@/features/dashboard'
import { SAFE_NOTES_TABS } from '@/features/dashboard/enums'
import { useModalContext } from '@/features/modals'
import { ICompany } from '@/features/profile/types'
import { containsMissedFields } from '@/features/profile/utils'
import { ISafeNote } from '@/features/safe-note'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { NothingFound } from '@/packages/ui'
import { HandleActionFnParams } from '@/packages/ui/table'
import { TableTab } from '@/packages/ui/table/Table'
import { BASE_TABLE_ACTIONS, RowsData } from '@/packages/ui/table/types'
import { NoItems } from '@/packages/ui/table/views/NoItems'
import { useNavigator } from '@/router'

import columns from './columns'

type SafeNotesProps = {
  data: ISafeNote[]
  role: ProfileTypes
  emailVerified?: boolean
  permission: TeamMemberPermission
  onDelete?: (id: string) => void
  currentCompany: ICompany
}

const SafeNoteList: FC<SafeNotesProps> = ({
  data,
  role,
  emailVerified,
  permission,
  onDelete,
  currentCompany
}: SafeNotesProps) => {
  const navigate = useNavigator()

  const { fillCompanyInfoModal, verifyEmailModal, dontHavePermissionModal } =
    useModalContext()
  const isEntrepreneur = ProfileTypes.ENTREPRENEUR === role

  // const { companyInfoModal } = useModalContext()

  const tabs: TableTab[] = useMemo(
    () => [
      {
        accessor: SAFE_NOTES_TABS.ALL,
        title: 'All SAFE Notes',
        filterFunc: (rows: RowsData) => rows
      },
      {
        accessor: SAFE_NOTES_TABS.UNPAID,
        title: 'Unpaid',
        filterFunc: (rows: RowsData) => rows?.filter((item) => !item.paid)
      },
      ...(isEntrepreneur
        ? [
            {
              accessor: SAFE_NOTES_TABS.DRAFT,
              title: 'Drafts',
              filterFunc: (rows: RowsData) => rows?.filter((item) => item.draft)
            }
          ]
        : [])
    ],
    []
  )

  const handleCreateSafe = () => {
    if (!emailVerified) {
      verifyEmailModal.openModal()
      return
    }

    if (permission !== TeamMemberPermission.CREATE) {
      dontHavePermissionModal.openModal()
      return
    }

    if (containsMissedFields(currentCompany)) {
      fillCompanyInfoModal.openModal()
      return
    }

    navigate.toCreateSafeNote(currentCompany.id)
  }

  const handleAction = useCallback(
    ({ action, row }: HandleActionFnParams) => {
      switch (action) {
        case BASE_TABLE_ACTIONS.DELETE: {
          onDelete?.(row.id)
          break
        }

        case BASE_TABLE_ACTIONS.LINK_CLICK: {
          navigate.toViewSafeNote(row.id)
          break
        }

        default:
          break
      }
    },
    [onDelete]
  )

  return (
    <Table
      title="My SAFE Notes"
      searchFields={['username']}
      columns={columns(!isEntrepreneur)}
      data={data}
      customActions={
        <SafeNoteButton
          hidden={currentCompany.type === ProfileTypes.ANGEL}
          onClick={handleCreateSafe}
        />
      }
      handleAction={handleAction}
      tabs={tabs}
      noResults={{
        byDefault: <NoItems />,
        afterSearch: <NothingFound isSearch />
      }}
    />
  )
}

export default SafeNoteList
