import clsx from 'clsx'
import { noop } from 'lodash'
import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { FontWeight, Radio, Spacer, Text, TextTypes } from '@/packages/ui'

import styles from './CardItem.module.scss'

interface CardItemProps {
  radioGroup?: string
  image: string
  title: string
  description: string
  tooltip?: string
  active?: boolean
  smallImage?: boolean
  onSelect?: () => void
  disabled?: boolean
}

const CardItem: FC<CardItemProps> = ({
  radioGroup = 'radio',
  active,
  image,
  title,
  description,
  tooltip,
  smallImage = false,
  onSelect,
  disabled = false
}: CardItemProps) => (
  <div
    className={clsx(styles.container, disabled && styles.disabled)}
    onClick={!disabled ? onSelect : noop}
  >
    <ConditionalRender condition={!!image}>
      <img
        className={clsx(styles.image, smallImage && styles.smallImage)}
        src={image}
        alt={title}
      />
    </ConditionalRender>

    <div className={styles.content}>
      <Text
        type={TextTypes.BODY_MAIN}
        weight={FontWeight.SEMIBOLD}
        tooltip={tooltip}
        showTooltipTriggerIcon={!!tooltip}
      >
        {title}
      </Text>
      <Spacer size={8} />
      <Text type={TextTypes.BODY_DEFAULT}>{description}</Text>
    </div>

    <ConditionalRender
      condition={!disabled}
      fallbackElement={<div className={styles.comingSoon}>Coming Soon</div>}
    >
      <Radio
        className={styles.radio}
        name={radioGroup}
        checked={active}
        disabled={disabled}
      />
    </ConditionalRender>
  </div>
)

export default CardItem
