import React, { FC } from 'react'

import { SafeNoteImages } from '@/features/safe-note/assets'
import {
  Button,
  Col,
  Heading,
  IModalWithCloseFn,
  Info,
  Modal,
  Row,
  Spacer,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './ConfirmSwitchMfn.module.scss'

type ConfirmSwitchMfnProps = IModalWithCloseFn & {
  onConfirm: () => void
}

const ConfirmSwitchMfn: FC<ConfirmSwitchMfnProps> = ({
  onConfirm,
  closeModal
}) => (
  <Modal
    size="sm"
    id="confirmSwitchMfnModal"
    className={styles.container}
    visible
    setVisible={closeModal}
  >
    <Col items="center">
      <img src={SafeNoteImages.EnableMfn} alt="warning" />
      <Spacer size={20} />

      <Heading>Do you want to turn on MFN?</Heading>

      <Spacer size={20} />
      <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.CENTER}>
        lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua, dolor sit amet,
        consectetur adipiscing elit.
      </Text>

      <Spacer size={10} />
      <Info>
        MFN holders need to get get this, this is the better rate you have given
        so far. We will automatically update their SAFEs.
      </Info>

      <Spacer size={40} />
      <Row gap={50} justify="between">
        <Button
          appearance="secondary"
          width="default"
          onClick={closeModal}
          uppercase
        >
          Cancel
        </Button>
        <Button
          width="default"
          onClick={() => {
            onConfirm()
            closeModal?.()
          }}
          uppercase
        >
          yes
        </Button>
      </Row>
    </Col>
  </Modal>
)

export default ConfirmSwitchMfn
