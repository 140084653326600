import clsx from 'clsx'
import { noop } from 'lodash'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { ConditionalRender, FloatingBanner } from '@/common/components'
import { fileToDataUrl } from '@/common/utils'
import { DeleteTeamMemberModal, TeamMember } from '@/features/auth/components'
import { AddTeamMemberModal, SubscriptionsModal } from '@/features/auth/modals'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  FontWeight,
  Heading,
  HeadingTypes,
  IFile,
  Spacer,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './AddTeamMembers.module.scss'

export const permissions = [
  {
    value: TeamMemberPermission.VIEW,
    label: 'Viewer',
    description: 'Can view SAFEs'
  },
  {
    value: TeamMemberPermission.CREATE,
    label: 'Collaborator',
    description: 'Can view, edit and create SAFEs'
  }
]

export interface IOwner {
  name: string
  position: string
  photo?: IFile
  email: string
}

export interface IComponentTeamMember {
  id: string
  name: string
  email: string
  permission: string
}

interface AddTeamMembersProps {
  teamMembers: IComponentTeamMember[]
  company: string
  onUpdateTeamMembers: (teamMembers: IComponentTeamMember[]) => void
  secondaryView?: boolean
  owner: IOwner
  type: ProfileTypes
}

const AddTeamMembers: FC<AddTeamMembersProps> = ({
  type,
  owner,
  company,
  teamMembers,
  onUpdateTeamMembers,
  secondaryView
}: AddTeamMembersProps) => {
  const { visible, closeModal, openModal } = useModal<
    'delete' | 'add' | 'edit' | 'upgrade'
  >()

  const [itemToEdit, setItemToEdit] = useState<
    IComponentTeamMember | undefined
  >()

  const [photoUrl, setPhotoUrl] = useState<any>()

  const needUpgrade = teamMembers.length > 1

  useEffect(() => {
    if (owner.photo) {
      if (owner.photo instanceof File) {
        fileToDataUrl(owner.photo).then(setPhotoUrl)
      } else {
        setPhotoUrl(owner.photo)
      }
    } else {
      setPhotoUrl(undefined)
    }
  }, [owner.photo])

  const handleOpenDeleteModal = useCallback(
    (id: string) => {
      setItemToEdit(teamMembers.find((member) => member.id === id)!)
      openModal('delete')
    },
    [openModal, teamMembers]
  )

  const handleOpenEditModal = useCallback(
    (id: string) => {
      setItemToEdit(teamMembers.find((member) => member.id === id)!)
      openModal('edit')
    },
    [openModal, teamMembers]
  )

  const handleCloseModal = useCallback(() => {
    setItemToEdit(undefined)
    closeModal()
  }, [])

  const handleDelete = useCallback(() => {
    if (!itemToEdit?.id) return
    handleCloseModal()

    const newTeamMembers = teamMembers.filter((p) => p.id !== itemToEdit.id)
    onUpdateTeamMembers(newTeamMembers)
  }, [itemToEdit, teamMembers, onUpdateTeamMembers])

  const handleUpdate = (updatedValue: IComponentTeamMember, add?: boolean) => {
    if (add) {
      onUpdateTeamMembers([...teamMembers, updatedValue])
    } else {
      onUpdateTeamMembers(
        teamMembers.map((member) =>
          member.id === updatedValue.id ? updatedValue : member
        )
      )
    }

    closeModal()
  }

  const renderTeamMembers = useCallback(
    () =>
      teamMembers.map(({ id, ...rest }: IComponentTeamMember) => (
        <TeamMember
          key={id}
          id={id}
          onClick={() => handleOpenEditModal(id)}
          allowDelete
          onDelete={handleOpenDeleteModal}
          {...rest}
        />
      )),
    [teamMembers, handleOpenDeleteModal]
  )

  return (
    <div className={clsx(styles.container, secondaryView && styles.secondary)}>
      {visible === 'delete' && (
        <DeleteTeamMemberModal
          closeModal={handleCloseModal}
          name={itemToEdit?.name || ''}
          handleDelete={handleDelete}
        />
      )}

      {['add', 'edit'].includes(visible!) && (
        <AddTeamMemberModal
          item={itemToEdit}
          handleUpdate={handleUpdate}
          type={type}
          closeModal={closeModal}
        />
      )}

      {visible === 'upgrade' && <SubscriptionsModal closeModal={closeModal} />}

      <Heading type={HeadingTypes.H2}>Who is on your team?</Heading>
      <Spacer size={16} />
      <Text type={TextTypes.BODY_DEFAULT}>
        Invite people to join the {company} team. Collaborate with co-founders
        or advisors, and show your team the SAFEs you’re creating.
      </Text>

      <Spacer size={24} />
      <div className={styles.teamMembers}>
        <TeamMember
          id="owner"
          isMe
          clickable={false}
          image={photoUrl}
          name={owner.name}
          email={owner.email}
          position={owner.position}
        />
        {renderTeamMembers()}
      </div>

      <Spacer size={24} />
      <div
        className={clsx(styles.addTeamMember, needUpgrade && styles.withBanner)}
        onClick={() => (needUpgrade ? noop() : openModal('add'))}
      >
        <ConditionalRender condition={needUpgrade}>
          <Button
            uppercase
            width="fit"
            className={styles.upgradeBtn}
            onClick={() => openModal('upgrade')}
          >
            Upgrade
          </Button>

          <FloatingBanner
            text="You`ve reached the max number of team members for the Basic
            subscription."
          />
        </ConditionalRender>

        {needUpgrade ? (
          <SharedIcons.Lock color={Color.neutral200} />
        ) : (
          <span className={styles.icon}>
            <SharedIcons.User color={Color.neutral0} size={10} />
          </span>
        )}

        <Text
          color={Color.primary400}
          weight={FontWeight.BOLD}
          type={TextTypes.BODY_DEFAULT}
        >
          Add team member
        </Text>
      </div>
    </div>
  )
}

export default AddTeamMembers
