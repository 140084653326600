import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { AddTeamMemberImages } from '@/features/auth/assets'
import {
  IComponentTeamMember,
  permissions
} from '@/features/auth/components/EntrepreneurForm/AddTeamMembers/AddTeamMembers'
import { TeamMemberSchema } from '@/features/auth/schemas/AuthSchema'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  Modal,
  Select,
  TextInput
} from '@/packages/ui'

import styles from './AddTeamMemberModal.module.scss'

interface AddTeamMemberModalProps {
  closeModal: () => void
  type: ProfileTypes
  item?: IComponentTeamMember
  handleUpdate: (teamMember: IComponentTeamMember, add?: boolean) => void
}

const AddTeamMemberModal: FC<AddTeamMemberModalProps> = (props) => {
  const { closeModal, item, type, handleUpdate } = props

  const {
    register,
    control,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(TeamMemberSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      name: item?.name || '',
      email: item?.email || '',
      permission: item?.permission || ''
    }
  })

  const handleSave = () => {
    const values = getValues()

    const addMode = !item?.id

    handleUpdate(
      {
        id: item?.id || Date.now().toString(),
        ...values
      } as IComponentTeamMember,
      addMode
    )
  }

  return (
    <Modal id="add-team-member-modal" size="sm" visible setVisible={closeModal}>
      <Col items="center" gap={24}>
        <img
          width={198}
          src={
            type === ProfileTypes.ENTREPRENEUR
              ? AddTeamMemberImages.EntrepreneurAddTeamMember
              : AddTeamMemberImages.AngelAddTeamMember
          }
          alt=""
        />

        <Heading type={HeadingTypes.H2} color={Color.neutral500}>
          Add team member
        </Heading>

        <div className="tw-self-stretch">
          <Form schema={TeamMemberSchema}>
            <FormItem errors={errors.name?.message}>
              <TextInput
                {...register('name')}
                label="Name"
                placeholder="Full name"
                invalid={FormHelper.isFieldInvalid('name', errors)}
              />
            </FormItem>

            <FormItem errors={errors.email?.message}>
              <TextInput
                {...register('email')}
                label="Email"
                placeholder="Email"
                invalid={FormHelper.isFieldInvalid('email', errors)}
              />
            </FormItem>

            <FormItem errors={errors.permission?.message}>
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    custom
                    withPortal
                    name="permission"
                    label="Permissions"
                    placeholder="Permissions"
                    options={permissions}
                    value={value!}
                    onChange={(val: string) => onChange(val)}
                  />
                )}
                name="permission"
              />
            </FormItem>
          </Form>
        </div>

        <div className={styles.footer}>
          <Button
            uppercase
            appearance="secondary"
            width="full"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            disabled={!isValid}
            uppercase
            width="full"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Col>
    </Modal>
  )
}

export default AddTeamMemberModal
