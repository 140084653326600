import React, { FC, MouseEvent } from 'react'

import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Button, Row } from '@/packages/ui'

interface EditButtonProps {
  edit: boolean
  className?: string
  onClick?: (e: MouseEvent<HTMLElement>) => void
}

const EditButton: FC<EditButtonProps> = ({ edit, onClick, className }) => (
  <Button
    appearance="secondary"
    width="fit"
    onClick={onClick}
    className={className}
  >
    <Row items="center" className="tw-font-medium tw-px-[15px]">
      {edit ? (
        <>
          <SharedIcons.EditComplicated className="tw-mr-[5px]" />
          Edit
        </>
      ) : (
        <>
          <SharedIcons.PlusThin color={Color.primary500} />
          Add
        </>
      )}
    </Row>
  </Button>
)

export default EditButton
