import { Stripe } from '@stripe/stripe-js'
import Lottie from 'lottie-react'
import { ReactNode, useCallback, useEffect } from 'react'

import { LottiePaymentLoading } from '@/assets/lottie'
import { FormatterHelper } from '@/common/helpers'
import { StoreType, withStore } from '@/common/store'
import config from '@/config'
import {
  BankTransferData,
  BankTransferDto,
  CardDataDto,
  CardPaymentData,
  IPaymentIntent,
  PaymentFailedView,
  PaymentStatus,
  PaymentSuccessView,
  PaymentType,
  PaymentVerificationView
} from '@/features/payment'
import { useDebounceEffect, useSmartState } from '@/packages/hooks'

import styles from './PaymentBridge.module.scss'

const mapStateToProps = ({ user, payment, safeNote }: StoreType) => ({
  user: user.me,
  getById: payment.getById,
  downloadReceipt: payment.downloadReceipt,
  loading: payment.loading || safeNote.functionLoading.create
})

export interface PaymentBridgeChildrenProps {
  processPayment: (
    type: PaymentType,
    createdPayment: IPaymentIntent,
    data: CardPaymentData | BankTransferData
  ) => Promise<PaymentStatus>
  constructCardData: (data: CardPaymentData) => CardDataDto | undefined
  constructBankTransferData: (
    data: BankTransferData
  ) => BankTransferDto | undefined
}

interface PaymentBridgeProps extends ReturnType<typeof mapStateToProps> {
  payAmount?: number
  children: (props: PaymentBridgeChildrenProps) => ReactNode
  initialStatus?: PaymentStatus
  initialDetails?: any
}

interface PaymentBridgeState {
  verificationPopup: any | undefined
  verificationUrl: string | undefined
  stripeLoading: boolean
  paymentStatus: PaymentStatus | undefined
  paymentDetails: any | undefined
  last4Digits: string | number | undefined
  paymentId: string | undefined
}

const PaymentBridge = ({
  children,
  payAmount = 1,
  user,
  loading,
  getById,
  downloadReceipt,
  initialStatus,
  initialDetails
}: PaymentBridgeProps) => {
  const [state, setState] = useSmartState<PaymentBridgeState>({
    stripeLoading: false,
    verificationPopup: undefined,
    verificationUrl: undefined,
    paymentStatus: initialStatus,
    paymentDetails: initialDetails,
    last4Digits: undefined,
    paymentId: undefined
  })

  const tryAgain = useCallback(() => {
    setState({ paymentStatus: undefined })
  }, [])

  const openVerificationPopup = useCallback(() => {
    if (!state.verificationUrl) return

    const name = 'VerificationWindow'

    const popupWindow = window.open(
      state.verificationUrl,
      name,
      'width=600,height=900'
    )
    popupWindow?.focus()

    setState({ verificationPopup: popupWindow })
  }, [state.verificationUrl])

  const updateAndGetPaymentDetails = useCallback(async () => {
    if (!state.paymentId) return Promise.resolve(undefined)

    const details = await getById({
      data: { id: state.paymentId }
    })

    setState({
      paymentDetails: details,
      stripeLoading: false
    })
    return details
  }, [state.paymentId])

  const checkPaymentStatusForBankTransfer = useCallback(async () => {
    const details = await updateAndGetPaymentDetails()

    if (!details) return
    const { status } = details

    if (status === PaymentStatus.CANCELED || status === PaymentStatus.FAILED) {
      setState({ paymentStatus: PaymentStatus.CANCELED })
    } else if (
      [PaymentStatus.PENDING, PaymentStatus.SUCCESS].includes(status)
    ) {
      setState({ paymentStatus: PaymentStatus.PENDING })
    }
  }, [updateAndGetPaymentDetails])

  useEffect(() => {
    if (state.verificationUrl) {
      setTimeout(() => {
        openVerificationPopup()
      }, 1500)
    }
  }, [state.verificationUrl, openVerificationPopup])

  // To check if popup is closed and fetch new payment data
  useEffect(() => {
    const popup = state.verificationPopup

    const interval = setInterval(() => {
      if (popup && popup.closed) {
        clearInterval(interval)
        checkPaymentStatusForBankTransfer()
      }
    }, 2000)

    if (popup && popup.closed) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [state.verificationPopup, checkPaymentStatusForBankTransfer])

  const constructCardData = useCallback(
    (data: CardPaymentData) => {
      const expirationDate = data.expirationDate?.split('/')

      if (!expirationDate) return undefined

      return {
        cvv: +data.cvv,
        cardNumber: String(data.cardNumber),
        expirationMonth: +expirationDate[0],
        expirationYear: +expirationDate[1]
      }
    },
    [payAmount]
  )

  const constructBankTransferData = useCallback(
    (data: BankTransferData) => ({
      accountHolderType: data.accountHolderType,
      accountNumber: data.accountNumber,
      routingNumber: data.routingNumber,
      billingDetails: data.billingDetails
    }),
    [payAmount]
  )

  const confirmCardPayment = useCallback(
    (stripe: Stripe | null, clientSecret: string) => {
      if (!clientSecret || !stripe) return undefined

      return stripe?.confirmCardPayment(clientSecret)
    },
    []
  )

  const confirmBankTransferPayment = useCallback(
    (stripe: Stripe | null, clientSecret: string) => {
      if (!clientSecret || !stripe) return undefined

      return stripe?.confirmUsBankAccountPayment(clientSecret)
    },
    []
  )

  useDebounceEffect(
    async () => {
      if (!state.paymentStatus) return

      const statusToSkipFetch = [PaymentStatus.FAILED, PaymentStatus.CANCELED]
      if (!statusToSkipFetch.includes(state.paymentStatus)) {
        await updateAndGetPaymentDetails()
      }
    },
    1500,
    [state.paymentStatus, updateAndGetPaymentDetails]
  )

  const processPayment = useCallback(
    async (
      type: PaymentType,
      paymentIntent: IPaymentIntent,
      data: CardPaymentData | BankTransferData
    ) => {
      const { clientSecret, payment } = paymentIntent

      try {
        if (clientSecret && payment?.id) {
          setState({
            stripeLoading: true,
            paymentId: payment?.id
          })

          const stripe = await (
            await import('@stripe/stripe-js')
          ).loadStripe(config.stripePkKey)

          const confirmPaymentResponse =
            type === PaymentType.CARD
              ? await confirmCardPayment(stripe, clientSecret)
              : await confirmBankTransferPayment(stripe, clientSecret)

          const status = confirmPaymentResponse?.paymentIntent?.status

          if (status === PaymentStatus.REQUIRES_ACTION) {
            const verificationUrl = (
              confirmPaymentResponse?.paymentIntent?.next_action as any
            )?.verify_with_microdeposits?.hosted_verification_url

            setState({ verificationUrl })
          }

          setState({
            stripeLoading: false,
            paymentStatus: (status as PaymentStatus) || PaymentStatus.FAILED,

            ...(type === PaymentType.CARD && {
              last4Digits: FormatterHelper.getLast4DigitsFromCard(
                (data as CardPaymentData).cardNumber
              )
            })
          })

          return (status as PaymentStatus) || PaymentStatus.FAILED
        }

        setState({ paymentStatus: PaymentStatus.FAILED })

        return PaymentStatus.FAILED
      } catch (err) {
        setState({ paymentStatus: PaymentStatus.FAILED, stripeLoading: false })
        return PaymentStatus.FAILED
      }
    },
    [state]
  )

  const handleDownloadReceipt = useCallback(() => {
    const paymentId = state.paymentDetails?.id
    if (!paymentId) return

    downloadReceipt({
      data: {
        paymentId,
        fileName: 'Receipt'
      }
    })
  }, [state.paymentDetails])

  const getView = useCallback(() => {
    switch (state.paymentStatus) {
      case PaymentStatus.SUCCESS:
      case PaymentStatus.PENDING:
        return (
          <PaymentSuccessView
            amount={payAmount}
            transactionId={state.paymentDetails?.transactionId}
            paymentDate={state.paymentDetails?.createdAt}
            paymentMethod={state.paymentDetails?.paymentMethodType}
            holderName={user?.fullName}
            cardType="Visa"
            last4Digits={state.last4Digits}
            inProcessing={state.paymentStatus === PaymentStatus.PENDING}
            onDownloadReceipt={handleDownloadReceipt}
          />
        )

      case PaymentStatus.REQUIRES_ACTION:
      case PaymentStatus.REQUIRES_CONFIRMATION:
      case PaymentStatus.REQUIRES_PAYMENT_METHOD:
        return <PaymentVerificationView onOpenPopup={openVerificationPopup} />

      case PaymentStatus.CANCELED:
      case PaymentStatus.FAILED:
        return <PaymentFailedView onTryAgain={tryAgain} />

      default:
        return null
    }
  }, [state, handleDownloadReceipt, tryAgain, openVerificationPopup])

  if (loading || state.stripeLoading) {
    return (
      <Lottie
        animationData={LottiePaymentLoading}
        className={styles.paymentLoading}
      />
    )
  }

  if (!state.paymentStatus) {
    return children({
      constructCardData,
      constructBankTransferData,
      processPayment
    })
  }

  return <div className={styles.container}>{getView()}</div>
}

export default withStore(mapStateToProps)(PaymentBridge)
