import isCreditCard from 'validator/lib/isCreditCard'
import * as yup from 'yup'

import { DateHelper } from '@/common/helpers'

export const CardPaymentSchema = yup.object({
  cardNumber: yup
    .string()
    .min(16, 'Invalid card number')
    .max(16, 'Invalid card number')
    .test('credit-card', 'Credit card is invalid', (value) =>
      isCreditCard(value ?? '')
    )
    .required('Credit card number is required'),
  expirationDate: yup
    .string()
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Invalid expiration date')
    .test('year-validation', 'Invalid year', (value) => {
      const currentYear = new Date().getFullYear()
      const century = DateHelper.getCurrentCentury()

      const yearNumber = value?.split('/')?.[1]

      const fullYear = Number(String(century + (yearNumber || '')))

      return fullYear >= currentYear && fullYear <= currentYear + 10
    })
    .test('month-validation', 'Invalid month', (value) => {
      const currentYear = new Date().getFullYear()
      const currentMonth = +(new Date().getMonth() + 1)
        .toString()
        .padStart(2, '0')
      const century = DateHelper.getCurrentCentury()

      const monthNumber = Number(value?.split('/')?.[0])
      const yearNumber = value?.split('/')?.[1]

      const fullYear = Number(String(century + (yearNumber || '')))
      const isSameYear = fullYear === currentYear

      if (!isSameYear) {
        return true
      }

      return monthNumber > currentMonth
    })

    .required('Expiration date is required'),
  cvv: yup
    .string()
    .matches(/^\d{3,4}$/, 'Invalid CVV code')
    .required('CVV code is required')
})

export const BankPaymentSchema = yup.object({
  accountNumber: yup.string().required('Account number is required'),
  routingNumber: yup.string().required('Routing number is required'),
  accountHolderType: yup.string().required('Account holder type  is required'),
  billingDetails: yup.string().required('Billing details is required')
})
