export enum Permissions {
  EDIT = 'edit',
  VIEW_ONLY = 'view_only'
}

export enum CapStatus {
  VERIFIED = 'verified',
  DEFAULT = 'default',
  PENDING = 'pending'
}
