import React from 'react'

import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Row, Tooltip } from '@/packages/ui'

import styles from './CompanyOptions.module.scss'

const CompanyOptions = () => (
  <Tooltip
    trigger="click"
    interactive
    placement="bottom-start"
    content={
      <ul className={styles.list}>
        <li>
          <Row items="center" gap={6}>
            <SharedIcons.Pencil color={Color.neutral500} size={15} />
            Edit
          </Row>
        </li>
        <li>
          <Row items="center" gap={6}>
            <SharedIcons.Trash color={Color.neutral500} size={18} />
            Delete
          </Row>
        </li>
      </ul>
    }
  >
    <div onClick={(event) => event.stopPropagation()}>
      <SharedIcons.Dots color={Color.neutral400} className={styles.more} />
    </div>
  </Tooltip>
)

export default CompanyOptions
