import { action, makeObservable } from 'mobx'

import { downloadPdfFile } from '@/common/api'
import { ToastService } from '@/common/services'
import { RootStoreType } from '@/common/store'
import StoreHandler from '@/common/store/StoreHandler'
import {
  CreatePaymentIntentDto,
  IPayment,
  IPaymentIntent,
  PaymentRequests
} from '@/features/payment'

class PaymentStore extends StoreHandler {
  constructor(rootStore: RootStoreType) {
    super(rootStore)
    makeObservable(this)
  }

  @action
  public createSafeNotePaymentIntent = this.execute<
    CreatePaymentIntentDto & { safeNoteId: string },
    IPaymentIntent
  >(
    async ({ data: { safeNoteId, ...data } }) =>
      PaymentRequests.createSafeNotePaymentIntent(safeNoteId, data),
    'createSafeNotePaymentIntent'
  )

  @action
  public getById = this.execute<{ id: string }, IPayment | undefined>(
    async ({ data }) => PaymentRequests.getById(data.id),
    'getById'
  )

  @action
  public downloadReceipt = this.execute<{
    paymentId: string
    fileName: string
  }>(
    async ({ data: { paymentId, fileName } }) => {
      try {
        const invoice = await PaymentRequests.downloadReceipt(paymentId)

        if (invoice) {
          downloadPdfFile(invoice, fileName)
        }
      } catch (err) {
        ToastService.showWarning('We cannot prepare the invoice at the moment')
      }
    },
    { loaderName: 'downloadReceipt' }
  )
}

export default PaymentStore
