import clsx from 'clsx'
import React, { FC, useMemo, useState } from 'react'

import { ArrowNavigation, Avatar, ConditionalRender } from '@/common/components'
import { FormatterHelper } from '@/common/helpers'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { ProfileTypes } from '@/features/user/enums'
import { SafeMini } from '@/packages/icons/Icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  FontWeight,
  Heading,
  HeadingTypes,
  Info,
  InfoType,
  Row,
  Spacer,
  Text,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './PendingSafes.module.scss'

interface PendingSafesProps {
  pendingSafes: any[]
  selectedRole?: ProfileTypes
}
const PendingSafes: FC<PendingSafesProps> = (props) => {
  const { pendingSafes, selectedRole } = props

  const [activeIndex, setActiveIndex] = useState<number>(0)

  const selectedSafe = pendingSafes[activeIndex]
  const { safeAmount } = selectedSafe

  const wrongRoleSelected = selectedRole && selectedRole !== ProfileTypes.ANGEL

  const moreThanOne = pendingSafes?.length > 1

  const footerMessage = useMemo(() => {
    const safeNumberMessage = pendingSafes?.length === 1 ? 'SAFE' : "SAFE's"

    if (wrongRoleSelected) {
      return `You can’t pay with this role for your ${pendingSafes?.length} pending ${safeNumberMessage}`
    }

    return `You can pay with this role for your ${pendingSafes?.length} pending ${safeNumberMessage}`
  }, [wrongRoleSelected, pendingSafes?.length])

  return (
    <div className={styles.container}>
      <Heading
        weight={FontWeight.BOLD}
        type={HeadingTypes.H1}
        color={Color.neutral500}
      >
        You have <span className={styles.number}>{pendingSafes?.length}</span>{' '}
        pending {pendingSafes.length === 1 ? 'SAFE' : 'SAFEs'}.
      </Heading>
      <Spacer size={moreThanOne ? 35 : 10} />
      <div
        className={clsx(
          styles.selectedSafe,
          wrongRoleSelected && styles.disabled
        )}
      >
        <ConditionalRender condition={moreThanOne}>
          <div className={clsx(styles.background, styles.level1)} />
          <div className={clsx(styles.background, styles.level2)} />
          <div className={clsx(styles.background, styles.level3)} />
        </ConditionalRender>

        {!!selectedRole && (
          <Info
            type={wrongRoleSelected ? InfoType.WARNING : InfoType.SUCCESS}
            textType={TextTypes.BODY_SMALL}
            className={styles.warningBanner}
            iconSize={15}
          >
            You {wrongRoleSelected ? 'can’t' : 'can'} pay with this role for
            this pending SAFE
          </Info>
        )}

        <Row
          items="center"
          justify="between"
          gap={20}
          className={styles.borderBottom}
        >
          <Text type={TextTypes.BODY_DEFAULT}>SAFE from</Text>
          <Row items="center" gap={8}>
            <Avatar
              image={selectedSafe?.sender?.image}
              name={selectedSafe?.sender?.fullName}
              size={30}
            />
            <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.SEMIBOLD}>
              {selectedSafe?.sender?.fullName}
            </Text>
          </Row>
        </Row>
        <Row
          items="center"
          justify="between"
          gap={20}
          className={styles.borderBottom}
        >
          <Text type={TextTypes.BODY_DEFAULT}>Company</Text>
          <Row items="center" gap={8}>
            <Avatar
              image={selectedSafe?.senderCompany?.logo}
              name={selectedSafe?.senderCompany?.name}
              size={30}
            />
            <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.SEMIBOLD}>
              {selectedSafe?.senderCompany?.name}
            </Text>
          </Row>
        </Row>
        <Spacer size={16} />

        <div className={styles.safeAmount}>
          <Row items="center" gap={10}>
            <div className={styles.safeIcon}>
              <img src={SafeNoteImages.OnlySafe} alt="safe-amount" />
            </div>

            <Row items="center" gap={8}>
              <Text type={TextTypes.BODY_DEFAULT}>SAFE Amount</Text>
              <Tooltip kind={TooltipKind.INFO} />
            </Row>

            <Badge size="large">{FormatterHelper.toCurrency(safeAmount)}</Badge>
          </Row>
        </div>

        <Spacer size={16} />
        <ConditionalRender condition={!!selectedSafe.discountRate}>
          <Row
            items="center"
            justify="between"
            gap={20}
            className={
              selectedSafe.valuationCap ? styles.borderBottom : 'tw-p-[8px]'
            }
          >
            <Text type={TextTypes.BODY_DEFAULT}>Discount Rate</Text>
            {selectedSafe.discountRate ? (
              <Text
                type={TextTypes.BODY_DEFAULT}
                weight={FontWeight.SEMIBOLD}
                color={Color.primary400}
              >
                {100 - selectedSafe.discountRate}%
              </Text>
            ) : (
              <Badge size="small">TBD</Badge>
            )}
          </Row>
        </ConditionalRender>

        <ConditionalRender condition={!!selectedSafe.valuationCap}>
          <Row items="center" justify="between" gap={20} className="tw-p-[8px]">
            <Text type={TextTypes.BODY_DEFAULT}>Valuation Cap</Text>
            {selectedSafe.valuationCap ? (
              <Text
                type={TextTypes.BODY_DEFAULT}
                weight={FontWeight.SEMIBOLD}
                color={Color.primary400}
              >
                {FormatterHelper.toCurrency(selectedSafe?.valuationCap)}
              </Text>
            ) : (
              <Badge size="small">TBD</Badge>
            )}
          </Row>
        </ConditionalRender>
      </div>
      <ConditionalRender condition={moreThanOne}>
        <ArrowNavigation
          active={activeIndex}
          setActive={setActiveIndex}
          size={pendingSafes.length}
        />
      </ConditionalRender>
      <ConditionalRender condition={!!selectedRole}>
        <div
          className={clsx(
            styles.asideFooter,
            wrongRoleSelected && styles.wrongRole
          )}
        >
          <SafeMini
            color={wrongRoleSelected ? Color.warning300 : Color.success400}
          />
          {footerMessage}
        </div>
      </ConditionalRender>
    </div>
  )
}

export default PendingSafes
