import clsx from 'clsx'
import React, { FC, MouseEvent } from 'react'

import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { ClickEvent, FontWeight, Spacer, Text, TextTypes } from '@/packages/ui'

import styles from './SafeNoteButton.module.scss'

interface SafeNoteButtonProps {
  onClick?: ClickEvent
  hidden?: boolean
}

const SafeNoteButton: FC<SafeNoteButtonProps> = ({
  onClick,
  hidden
}: SafeNoteButtonProps) => {
  if (hidden) return null

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    onClick?.(e)
  }

  return (
    <>
      <button className={clsx(styles.button)} onClick={handleClick}>
        <SharedIcons.PlusThin size={24} color={Color.neutral0} />
        <Spacer size={5} vertical />
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          className="tw-whitespace-nowrap"
        >
          Create SAFE
        </Text>
      </button>
    </>
  )
}

export default SafeNoteButton
