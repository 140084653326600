import React, { FC } from 'react'

import { SharedIcons } from '@/packages/icons'
import { IButtonProps } from '@/packages/ui'

import { Button } from '../Button'
import styles from './BackButton.module.scss'

type BackButtonProps = IButtonProps

const BackButton: FC<BackButtonProps> = ({ children, ...rest }) => (
  <Button {...rest} appearance="ordinary" textClassName={styles.text}>
    <SharedIcons.Arrow size={25} className={styles.arrow} />
    {children || 'Back'}
  </Button>
)

export default BackButton
