import React, { FC } from 'react'

import sentIcon from '@/features/profile-settings/assets/feedback-sent.png'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

const MODAL_ID = 'feedback-sent-modal'

const FeedbackSentModal: FC<IModalWithCloseFn> = (props) => {
  const { closeModal } = props

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <Col items="center" gap={24}>
        <img width={254} src={sentIcon} alt="Feedback Sent" />

        <Col items="center" gap={2}>
          <Heading
            type={HeadingTypes.H2}
            align={TextAlign.CENTER}
            color={Color.neutral500}
          >
            Welldone!
          </Heading>

          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral400}
            align={TextAlign.CENTER}
          >
            You have successfully sent your feedback! We appreciate it a lot
          </Text>
        </Col>

        <Button
          uppercase
          width="default"
          onClick={closeModal}
          className="tw-mt-8"
        >
          Close
        </Button>
      </Col>
    </Modal>
  )
}

export default FeedbackSentModal
