/*  eslint-disable */
// @ts-nocheck
import Mark from 'mark.js'

interface MarkerOptions {
  withTimeout?: boolean
  defaultStyles?: any
}

class Marker {
  constructor(el, options = {}) {
    this.el = typeof el === 'string' ? document.getElementById(el) : el
    this.currentMarkAttribute = 'data-mark-current'
    this.selectedNodeIndex = 0
    this.selectedNode = null
  }

  #setCurrentMarkToNode = (node) => {
    if (!node) return
    node.setAttribute(this.currentMarkAttribute, true)
  }

  #removeCurrentMarkFromNode = (node) => {
    if (!node) return
    node.removeAttribute(this.currentMarkAttribute)
  }

  isInViewPort = (node, bottomGap = 0) => {
    if (!node) return false

    const rect = node.getBoundingClientRect()
    const inViewport =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) -
          bottomGap &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    return inViewport
  }

  mark = (word, options = {}) => {
    if (!this.el) return

    const instance = new Mark(this.el)

    const {
      withTimeout = false,
      disableMoving = false,
      clearPrevious = true,
      ...markerOptions
    } = options

    if (clearPrevious) {
      // unmark first
      instance.unmark()
    }

    const nodes = []

    instance.mark(word, {
      ...markerOptions,
      each: (node) => nodes.push(node)
    })

    // assigning nodes
    this.nodes = nodes

    // get first node from list
    const currentSelectedNode = nodes[0]

    if (!disableMoving) {
      // add attribute to mark as current
      this.#setCurrentMarkToNode(currentSelectedNode)
    }

    // and current node with index to global
    this.selectedNode = currentSelectedNode
    this.selectedNodeIndex = 0

    if (withTimeout) {
      setTimeout(
        () => instance.unmark({ className: markerOptions.className }),
        2000
      )
    }

    if (!this.isInViewPort(currentSelectedNode)) {
      currentSelectedNode?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }

    return nodes.length
  }

  // direction 1 -> forward, -1 - backward
  markCurrentElement = (direction) => {
    const index = this.selectedNodeIndex

    if (!direction) throw new Error('Direction not defined!')

    let newIndex = null

    if (direction === 1) {
      // increase current node index
      newIndex = index + 1

      // if index is greater than nodes count then skip
      if (newIndex >= this.nodes.length) return null
    }

    if (direction === -1) {
      // decrease current node index
      newIndex = index - 1

      // if index is less than 0 then skip
      if (newIndex < 0) return null
    }

    // remove selection from current
    this.#removeCurrentMarkFromNode(this.selectedNode)

    // get new currentNode
    const currentNode = this.nodes[newIndex]

    // highlight current node
    this.#setCurrentMarkToNode(currentNode)

    // change current selected node
    this.selectedNode = currentNode

    // change current selected index
    this.selectedNodeIndex = newIndex

    currentNode.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })

    return newIndex
  }

  markPrev = () => {
    return this.markCurrentElement(-1)
  }

  markNext = () => {
    return this.markCurrentElement(1)
  }

  markElement({ withTimeout, defaultStyles }?: MarkerOptions) {
    // Get the element you want to wrap
    const element = this.el

    if (!element) return

    // Clone the original element and its contents
    const clonedElement = element.cloneNode(true)

    // Create a new <mark> element
    const markElement = document.createElement('mark')

    const tagName = element.tagName.toLowerCase()
    const blockTags = ['p', 'div']

    if (!this.isInViewPort(element)) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }

    setTimeout(() => {
      // Check if the element is a block tag
      if (blockTags.includes(tagName)) {
        element.style.background = '#FFBA27'
        element.style.color = 'black'

        if (withTimeout) {
          setTimeout(() => {
            element.style.background = defaultStyles?.backgroundColor
            element.style.color = defaultStyles?.color || ''
          }, 2000)
        }

        return
      }

      // Replace the original element with the <mark> element
      element.parentNode.replaceChild(markElement, element)

      // Append the cloned element to the <mark> element
      markElement.appendChild(clonedElement)

      if (withTimeout) {
        setTimeout(() => {
          // replace element with clonedElement
          markElement.replaceWith(clonedElement)
        }, 2000)
      }
    })
  }
}

export default Marker
