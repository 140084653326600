import clsx from 'clsx'
import React, { FC, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Checkbox, Col, Row, Tooltip } from '@/packages/ui'
import { useTableContext } from '@/packages/ui/table/context'
import { FilterOption } from '@/packages/ui/table/types'

import styles from './TableHeader.module.scss'

const SortingBlock = () => (
  <span className={styles.sortingBlock}>
    <SharedIcons.SortingArrow
      className={styles.sortingIcon}
      color={Color.neutral500}
    />
    <SharedIcons.SortingArrow
      className={styles.sortingIcon}
      color={Color.neutral500}
    />
  </span>
)

const TableHeader: FC = () => {
  const { columns } = useTableContext()

  const [openedFilter, setOpenedFilter] = useState<string | null>(null)

  return (
    <thead>
      <tr>
        {columns.map((column) => {
          const th = (
            <ConditionalRender
              condition={!!column.filterOptions?.length}
              fallbackElement={column.title}
            >
              <Tooltip
                interactive
                trigger="click"
                onShow={() => setOpenedFilter(column.accessor)}
                onHide={() => setOpenedFilter(null)}
                content={
                  <Col gap={2}>
                    {column.filterOptions?.map((option: FilterOption) => (
                      <Checkbox
                        small
                        key={option.accessor}
                        name={option.accessor}
                        label={option.title}
                      />
                    ))}
                  </Col>
                }
                className="tw-py-[12px]"
                placement="bottom-start"
              >
                <div className={styles.filteredHeader}>
                  {column.title}{' '}
                  <SharedIcons.MinimalisticArrow
                    className={clsx(
                      styles.arrow,
                      openedFilter === column.accessor && styles.opened
                    )}
                  />
                </div>
              </Tooltip>
            </ConditionalRender>
          )

          return (
            <th key={`header-td-${column.accessor}`}>
              <ConditionalRender
                condition={column.withSorting}
                fallbackElement={th}
              >
                <Row
                  gap={4}
                  items="center"
                  justify="start"
                  className="tw-cursor-pointer"
                >
                  {th}
                  <SortingBlock />
                </Row>
              </ConditionalRender>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
