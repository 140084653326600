import React, { FC, useMemo } from 'react'

import { ConditionalRender } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import { SendReminder } from '@/features/dashboard/assets/icons/TableOptionsIcons'
import sentIcon from '@/features/dashboard/assets/images/summary/safes-sent.png'
import unpaindIcon from '@/features/dashboard/assets/images/summary/unpaid-safes.png'
import { useModalContext } from '@/features/modals'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from './DashboardSummarySafe.module.scss'

interface DashboardSummarySafeProps {
  type: 'sent' | 'unpaid'
  count: number
  amount: number
  role: ProfileTypes
  onClick?: () => void
  withoutAction?: boolean
  permission: TeamMemberPermission
}

const DashboardSummarySafe: FC<DashboardSummarySafeProps> = ({
  type,
  count,
  withoutAction,
  amount,
  permission,
  role,
  onClick
}) => {
  const { dontHavePermissionModal } = useModalContext()

  const isEntrepreneur = role === ProfileTypes.ENTREPRENEUR
  const sent = type === 'sent'

  const allowAction = TeamMemberPermission.CREATE === permission

  const title = useMemo(() => {
    if (sent) {
      if (isEntrepreneur) return count === 1 ? 'SAFE Sent' : 'SAFEs Sent'

      return count === 1 ? 'SAFE' : 'SAFEs'
    }

    return count === 1 ? 'Unpaid SAFE' : 'Unpaid SAFEs'
  }, [sent, count, isEntrepreneur])

  const handleClick = () => {
    if (!allowAction) {
      dontHavePermissionModal.openModal()
      return
    }

    onClick?.()
  }

  return (
    <Row className={styles.safesBlock} items="center" gap={16}>
      <Col className="tw-flex-1" gap={8}>
        <ConditionalRender condition={!isEntrepreneur && !withoutAction}>
          <Row justify="between" gap={20}>
            <img
              width={43.5}
              src={sent ? sentIcon : unpaindIcon}
              alt=""
              className={styles.icon}
            />

            <ConditionalRender condition={!withoutAction}>
              <Text
                type={TextTypes.BODY_DEFAULT}
                weight={FontWeight.MEDIUM}
                color={Color.primary500}
                onClick={onClick}
                className="tw-cursor-pointer"
              >
                View all
              </Text>
            </ConditionalRender>
          </Row>
        </ConditionalRender>

        <Row items="center" justify="between" gap={15}>
          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral500}
            className={styles.numberWrapper}
          >
            <span className={styles.number}>{count}</span>
            {title}
          </Text>

          <ConditionalRender condition={isEntrepreneur}>
            <Row
              gap={4}
              items="center"
              className="tw-cursor-pointer"
              onClick={handleClick}
            >
              {sent ? (
                <SharedIcons.PlusThin size={20} color={Color.primary500} />
              ) : (
                <SendReminder size={20} color={Color.primary500} />
              )}
              <Text
                type={TextTypes.BODY_DEFAULT}
                weight={FontWeight.MEDIUM}
                color={Color.primary500}
              >
                {sent ? 'Create' : 'Remind'}
              </Text>
            </Row>
          </ConditionalRender>
        </Row>

        <Row items="center" gap={5}>
          <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
            Total Amount:
          </Text>
          <Text
            type={TextTypes.BODY_DEFAULT}
            weight={FontWeight.SEMIBOLD}
            color={sent ? Color.primary500 : Color.warning300}
          >
            {toCurrencyFormat(amount, '$')}
          </Text>
        </Row>
      </Col>
    </Row>
  )
}

export default DashboardSummarySafe
