import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect } from 'react'

import { useToast } from '@/common/hooks'
import { StoreType, useStore } from '@/common/store'
import {
  PayAs,
  PaymentMethod,
  PaymentMethodData,
  PaymentOverview,
  PaymentStatus,
  PaymentType
} from '@/features/payment'
import { PaymentBridgeChildrenProps } from '@/features/payment/components'
import { ISafeNote } from '@/features/safe-note'
import { useCreateSafeContext } from '@/features/safe-note/context'

interface PaymentStepProps {
  onPayLater: () => void
}

const PaymentStep: FC<PaymentStepProps> = (props) => {
  const { onPayLater } = props
  const { createSafeNotePaymentIntent } = useStore(
    (store: StoreType) => store.payment
  )

  const {
    data,
    createSafeNote,
    updateData,
    setNextDisabled,
    checkIfSafeNoteCreated
  } = useCreateSafeContext()
  const { showInfo } = useToast()

  const decideToDisableButton = useCallback(
    (method: PaymentMethod) => {
      if (data.paid) return

      if (method === PaymentMethod.RECORD_PAYMENT) {
        setNextDisabled(false)
        return
      }

      setNextDisabled(true)
    },
    [data?.paid]
  )

  const handleSelectPaymentMethod = useCallback(
    (method: PaymentMethod) => {
      decideToDisableButton(method)

      if (method === PaymentMethod.RECORD_PAYMENT) {
        updateData({ paid: true }, true)
        return
      }
      updateData({ paid: false }, true)
    },
    [decideToDisableButton]
  )

  const handlePay = useCallback(
    ({
        processPayment,
        constructCardData,
        constructBankTransferData
      }: PaymentBridgeChildrenProps) =>
      async (_data: PaymentMethodData) => {
        let safeNote: ISafeNote | undefined = await checkIfSafeNoteCreated()

        if (!safeNote) {
          safeNote = await createSafeNote()
        }

        if (!safeNote?.id) {
          showInfo(
            'Payment cannot be completed because SAFE note is not exist!'
          )
          return
        }

        if (safeNote.paid) {
          showInfo('Payment was already completed!')
          return
        }

        if (_data.method === PaymentMethod.CARD) {
          const payment = await createSafeNotePaymentIntent({
            data: {
              type: PaymentType.CARD,
              data: constructCardData(_data),
              safeNoteId: safeNote.id,
              payAs: PayAs.ENTREPRENEUR
            }
          })

          const status = await processPayment(PaymentType.CARD, payment, _data)
          updateData(
            {
              paymentStatus: status,
              paid: status === PaymentStatus.SUCCESS
            },
            true
          )
        }

        if (_data.method === PaymentMethod.BANK_TRANSFER) {
          const payment = await createSafeNotePaymentIntent({
            data: {
              type: PaymentType.BANK_TRANSFER,
              data: constructBankTransferData(_data),
              safeNoteId: safeNote.id,
              payAs: PayAs.ENTREPRENEUR
            }
          })

          const status = await processPayment(
            PaymentType.BANK_TRANSFER,
            payment,
            _data
          )
          updateData(
            {
              paymentStatus: status,
              paid: status === PaymentStatus.SUCCESS
            },
            true
          )
        }
      },
    [
      checkIfSafeNoteCreated,
      createSafeNote,
      updateData,
      createSafeNotePaymentIntent
    ]
  )

  useEffect(() => {
    setNextDisabled(!data.paid)
  }, [data.paid])

  return (
    <PaymentOverview
      platformFee={0}
      stripeFee={0}
      safeAmount={data?.safeAmount}
      cashAmount={data?.cashAmount}
      onSelectPaymentMethod={handleSelectPaymentMethod}
      onPay={handlePay}
      onPayLater={onPayLater}
      recipientName={data?.recipientName}
      status={data.paymentStatus}
    />
  )
}

export default observer(PaymentStep)
