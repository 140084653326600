import clsx from 'clsx'
import { ChangeEvent, DragEvent, FC, useEffect, useState } from 'react'

import { useToast } from '@/common/hooks'
import { Label } from '@/packages/ui'

import { SharedIcons } from '../../icons'
import { Row } from '../grid'
import { Spacer } from '../spacer'
import { FontWeight, Text, TextAlign, TextTypes } from '../typography'
import { FileItem } from './FileItem'
import styles from './FileSelect.module.scss'
import { ReactComponent as ImportIcon } from './import.svg'
import { IFile, IFileProgressInfo } from './types'

interface FileSelectProps {
  appearance?: 'compact' | 'default'
  caption?: string
  label?: string
  allowedExtensions?: string
  multiple?: boolean
  fileIcon?: string
  initialFiles?: IFile[]
  progressInfo?: IFileProgressInfo
  hideSpotIfFilePresented?: boolean
  hideFileList?: boolean
  disableDnd?: boolean

  onClick?: () => void
  onSelectFiles?: (files: IFile[]) => void
  onDelete?: (id: string, fileToDelete: IFile) => void
}

const FileSelect: FC<FileSelectProps> = ({
  appearance,
  caption = 'Upload file',
  allowedExtensions = '.txt',
  multiple = false,
  fileIcon,
  progressInfo = {},
  onDelete,
  initialFiles = [],
  onSelectFiles,
  onClick,
  label,
  hideSpotIfFilePresented,
  hideFileList = false,
  disableDnd = false
}: FileSelectProps) => {
  const { showWarning } = useToast()

  const [files, setFiles] = useState<IFile[]>([])
  const [onDropZone, setOnDropZone] = useState<boolean>(false)

  const isCompactAppearance = appearance === 'compact'
  const atLeastOneFilePresented = files.length >= 1

  useEffect(() => {
    if (!initialFiles?.length) return

    setFiles((prev) => [...prev, ...initialFiles])
  }, [JSON.stringify(initialFiles)])

  const handleDeleteFile = (id: string, fileToDelete: IFile) => {
    setFiles((prevFiles: IFile[]) => prevFiles.filter((file) => file.id !== id))

    onDelete?.(id, fileToDelete)
  }

  useEffect(() => {
    onSelectFiles?.(files)
  }, [JSON.stringify(files)])

  const applySelectedFiles = (fileList: FileList | null) => {
    if (!fileList || disableDnd) return

    const selectedFiles: IFile[] = Array.from(fileList)
      .map((file) =>
        Object.assign(file, {
          id: Date.now().toString()
        })
      )
      .filter((file) => {
        if (!allowedExtensions) return true

        const type = file?.name?.split?.('.')?.pop?.()?.toLowerCase?.()
        const extentions = allowedExtensions
          ?.split?.(',')
          .map((item: string) => item.toLowerCase().replaceAll('.', ''))

        const isAllowed = type ? extentions.includes(type) : false

        if (!isAllowed) {
          showWarning(
            `${file.name} has prohibited file type. List of allowed types: ${allowedExtensions}`
          )
        }

        return isAllowed
      })

    setFiles((prevFiles) => [...prevFiles, ...selectedFiles])
  }

  const handleSelectFiles = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    applySelectedFiles(event.target.files)
  }

  const onDropLeave = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    applySelectedFiles(event.dataTransfer.files)
    setOnDropZone(false)
  }

  const onDragEnter = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    applySelectedFiles(event.dataTransfer.files)
  }

  const onDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    applySelectedFiles(event.dataTransfer.files)
    setOnDropZone(true)
  }

  const renderFiles = (files_: IFile[]) =>
    files_.map((file: IFile) => (
      <FileItem
        key={file.id}
        id={file.id}
        name={file.name}
        icon={fileIcon}
        showUploadProgress={progressInfo[file.id]?.showUploadProgress}
        uploadProgress={progressInfo[file.id]?.percent}
        onDelete={(id: string) => handleDeleteFile(id, file)}
      />
    ))

  const DefaultAppearance = (
    <>
      <div className={styles.img}>
        <SharedIcons.Image size={24} />
      </div>
      <Spacer size={10} />
      <Text
        className={styles.caption}
        type={TextTypes.BODY_DEFAULT}
        align={TextAlign.CENTER}
      >
        {caption}
      </Text>

      <Spacer size={25} />

      <Row items="center">
        <ImportIcon />
        <Spacer size={10} vertical />
        <Text
          className={styles.textImport}
          weight={FontWeight.SEMIBOLD}
          type={TextTypes.BODY_DEFAULT}
        >
          Upload
        </Text>
      </Row>
    </>
  )

  const CompactAppearance = (
    <Row gap={10} items="center">
      <SharedIcons.Attachment />
      <Text
        className={styles.caption}
        type={TextTypes.BODY_DEFAULT}
        align={TextAlign.CENTER}
      >
        {caption}
      </Text>
    </Row>
  )

  const DndSpot = (
    <div>
      {!!label && <Label bold className="tw-mb-[8px]" label={label} />}
      <label
        htmlFor="fileUploader"
        className={styles.uploaderInputWrapper}
        onDrop={onDropLeave}
        onDragEnter={onDragEnter}
        onDragLeave={onDropLeave}
        onDragOver={onDragOver}
        onClick={onClick}
      >
        <div
          className={clsx(
            styles.customFileInput,
            appearance && styles[appearance],
            !disableDnd && onDropZone && styles.active
          )}
        >
          {isCompactAppearance ? CompactAppearance : DefaultAppearance}
        </div>
        {!onClick && (
          <input
            id="fileUploader"
            type="file"
            accept={allowedExtensions}
            multiple={multiple}
            onChange={handleSelectFiles}
            className={styles.fileUploadInput}
          />
        )}
      </label>
    </div>
  )

  return (
    <div className={styles.filesUploader}>
      {!hideFileList && renderFiles(files)}

      {hideSpotIfFilePresented ? !atLeastOneFilePresented && DndSpot : DndSpot}
    </div>
  )
}

export default FileSelect
