import clsx from 'clsx'
import React, { FC, useCallback } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Col,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './TeamMember.module.scss'

interface TeamMemberProps {
  id: string
  isMe?: boolean
  image?: any
  name?: string
  email?: string
  clickable?: boolean
  allowDelete?: boolean
  position?: string
  hideEmail?: boolean
  defaultImage?: string
  onClick?: (id: string) => void
  onDelete?: (id: string) => void
}

const TeamMember: FC<TeamMemberProps> = ({
  id,
  isMe,
  name,
  email,
  allowDelete,
  image,
  hideEmail,
  position,
  defaultImage,
  onClick,
  onDelete,
  clickable = true
}: TeamMemberProps) => {
  const handleClick = useCallback(() => {
    onClick?.(id)
  }, [id, onClick])

  const handleDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      onDelete?.(id)
    },
    [id, onDelete]
  )

  return (
    <div
      id={`team-member-${id}`}
      className={clsx(
        styles.teamMember,
        clickable && styles.clickable,
        (name || email) && styles.fullWidth
      )}
      onClick={handleClick}
    >
      <Row items="center" gap={12}>
        <Avatar
          size={44}
          defaultImage={defaultImage}
          className={styles.image}
          image={image}
          name={name}
        />

        <Col>
          <Row items="center" gap={3}>
            <Text
              className={styles.name}
              type={TextTypes.BODY_DEFAULT}
              weight={FontWeight.SEMIBOLD}
              color={Color.neutral500}
            >
              {name}
            </Text>
            <ConditionalRender condition={!!name && isMe}>
              <Text
                className={styles.name}
                type={TextTypes.BODY_DEFAULT}
                color={Color.neutral500}
              >
                (you)
              </Text>
            </ConditionalRender>
          </Row>

          <ConditionalRender condition={!hideEmail}>
            <Text
              className={styles.titleOrEmail}
              type={TextTypes.BODY_SMALL}
              color={Color.neutral400}
            >
              {email}
            </Text>
          </ConditionalRender>
        </Col>
      </Row>

      <Row items="center" gap={12}>
        <ConditionalRender condition={!!position}>
          <Badge type={BadgeType.WARNING} className={styles.badge}>
            {position}
          </Badge>
        </ConditionalRender>

        <ConditionalRender condition={allowDelete}>
          <span className={styles.delete} onClick={handleDelete}>
            <SharedIcons.Trash size={12} color={Color.neutral0} />
          </span>
        </ConditionalRender>
      </Row>
    </div>
  )
}

export default TeamMember
