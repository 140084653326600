import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import { StoreType } from '@/common/store'
import { withStore } from '@/common/store/withStore'
import { decodeToken } from '@/common/utils'
import config from '@/config'
import { SignUpDto } from '@/features/auth'
import { AsideImages } from '@/features/auth/assets'
import { AuthLayout } from '@/features/auth/layouts'
import { SignUpSchema } from '@/features/auth/schemas/AuthSchema'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Form,
  FormHelper,
  FormItem,
  FullScreenLoading,
  Heading,
  HeadingTypes,
  NavLink,
  OAuthButton,
  PasswordInput,
  Spacer,
  Text,
  TextInput,
  TextTypes
} from '@/packages/ui'
import { Button } from '@/packages/ui/button'
import { NavLinkType } from '@/packages/ui/button/NavLink/NavLInk'
import { ROUTES, useNavigator } from '@/router'

const mapStateToProps = ({ auth }: StoreType) => ({
  failedFields: auth.failedFields,
  loading: auth.functionLoading.signUp,
  signUp: auth.signUp,
  validateToken: auth.validateToken
})

type LoginView = ReturnType<typeof mapStateToProps>

const SignUpView: FC<LoginView> = ({
  failedFields,
  signUp,
  loading,
  validateToken
}) => {
  const navigate = useNavigator()
  const [searchParams] = useSearchParams()
  const [lockedUserEmail, setLockedUserEmail] = useState(undefined)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
    trigger
  } = useForm({
    resolver: yupResolver(SignUpSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const signUpToken = useMemo(() => searchParams.get('token'), [searchParams])

  useEffect(() => {
    if (signUpToken && signUpToken.trim() !== '') {
      const decodedData = decodeToken(signUpToken)

      validateToken({
        data: {
          token: signUpToken
        }
      }).then(({ valid }) => {
        if (valid) {
          reset(decodedData)
          if (decodedData?.profileId) {
            setLockedUserEmail(decodedData?.email)
          }
        }
      })
    }
  }, [signUpToken])

  useEffect(() => {
    if (isDirty && Object.keys(failedFields).length > 0) {
      trigger()
    }
  }, [isDirty, JSON.stringify(failedFields)])

  const onSubmit = (values: FieldValues) => {
    const redirectParams =
      !!signUpToken && !!decodeToken(signUpToken)?.profileId
        ? {
            token: signUpToken
          }
        : undefined

    signUp({
      data: {
        ...values,
        email: lockedUserEmail || values.email,
        token: signUpToken
      } as SignUpDto,
      options: { onSuccess: () => navigate.toCompleteSignUp(redirectParams) }
    })
  }

  return (
    <AuthLayout
      asideImage={AsideImages.SignUpAside}
      title="Let’s get you set up with us!"
      description="MySAFEnotes is the perfect place for you to raise or provide funding for startups using SAFEs."
    >
      <div className="sign-up-page">
        <FullScreenLoading loading={loading} />

        <div className="content-header">
          <Heading type={HeadingTypes.H2} color={Color.neutral500}>
            Sign Up
          </Heading>
          <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
            Let’s get you started. It takes just a few minutes!
          </Text>
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormItem errors={errors.fullName?.message}>
            <TextInput
              {...register('fullName')}
              name="fullName"
              label="Name"
              placeholder="Your full name"
              invalid={FormHelper.isFieldInvalid('fullName', errors)}
            />
          </FormItem>
          <FormItem errors={errors.email?.message}>
            <TextInput
              {...register('email')}
              name="email"
              label="Email"
              placeholder="Email address"
              invalid={FormHelper.isFieldInvalid('email', errors)}
              disabled={!!lockedUserEmail}
            />
          </FormItem>
          <FormItem errors={errors.password?.message}>
            <PasswordInput
              {...register('password')}
              name="password"
              label="Create your password"
              placeholder="Password (6 or more characters)"
              invalid={FormHelper.isFieldInvalid('password', errors)}
            />
          </FormItem>
          <Spacer size={10} ignoreGridGap />
          <Button type="submit" width="default" disabled={!isValid} uppercase>
            Sing me up
          </Button>
        </Form>

        <div className="social-section">
          <Text
            className="social-section__description"
            type={TextTypes.BODY_DEFAULT}
          >
            You can also sign up with
          </Text>

          <Spacer size={10} vertical />
          <NavLink
            href={config.googleAuthUrl}
            type={NavLinkType.NONE}
            width="auto"
            useActiveState={false}
          >
            <OAuthButton type="google" />
          </NavLink>

          <Spacer size={10} vertical />
          <NavLink
            href={config.linkedInAuthUrl}
            type={NavLinkType.NONE}
            width="auto"
            useActiveState={false}
          >
            <OAuthButton type="linkedin" />
          </NavLink>
        </div>
        <div className="bottom-section">
          <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.MEDIUM}>
            Already a member?
          </Text>
          <Spacer size={5} vertical />
          <NavLink
            href={ROUTES.LOGIN}
            width="auto"
            className="auth-goto-link"
            useActiveState={false}
          >
            <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.BOLD} asSpan>
              Login
            </Text>
            <Spacer size={5} vertical />
            <SharedIcons.Arrow size={16} />
          </NavLink>
        </div>
      </div>
    </AuthLayout>
  )
}

export default withStore(mapStateToProps)(SignUpView)
