import React, { FC, useMemo } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { CompanyOptions } from '@/features/dashboard'
import { BellIcon } from '@/features/dashboard/assets'
import { ISummaryItem } from '@/features/profile/api/types'
import { defaultSummary } from '@/features/profile/constants'
import { ICompany } from '@/features/profile/types'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { FontWeight, Row, Text, TextTypes } from '@/packages/ui'
import { useNavigator } from '@/router'

import { DashboardTeamMembers } from '../DashboardTeamMembers'
import styles from './CompanyCard.module.scss'

interface CompanyCardProps {
  company: ICompany
  summary?: ISummaryItem
  myId?: string
}

const CompanyCard: FC<CompanyCardProps> = (props) => {
  const { summary = defaultSummary, company, myId } = props

  const navigate = useNavigator()

  const isCompanyOwner = company.owner.id === myId
  const permission = useMemo(
    () =>
      company.teamMembers.find((member) => member.userId === myId)?.permission,
    []
  )
  const isCreator = isCompanyOwner || permission === TeamMemberPermission.CREATE

  const entrepreneurCompany = company.type === ProfileTypes.ENTREPRENEUR
  const paidSafes = summary.totalSafeCount - summary.unpaidCount

  return (
    <div
      key={company.id}
      className={styles.companyCard}
      onClick={() => navigate.toDashboardCompany(company.id)}
    >
      <Row justify="between" gap={20} className="tw-mb-[10px]">
        <Avatar
          size={56}
          name={company.name}
          image={company.image}
          className={styles.companyLogo}
        />

        <Row items="center" gap={8}>
          <BellIcon color={Color.neutral400} />
          <ConditionalRender condition={isCreator}>
            <CompanyOptions />
          </ConditionalRender>
        </Row>
      </Row>

      <Text
        color={Color.neutral500}
        type={TextTypes.BODY_MAIN}
        weight={FontWeight.BOLD}
      >
        {company.name}
      </Text>

      <Row items="center" gap={4} className="tw-mb-[4px]">
        <Text
          color={Color.neutral400}
          type={TextTypes.BODY_SMALL}
          weight={FontWeight.MEDIUM}
        >
          {entrepreneurCompany ? 'SAFEs Sent' : 'Paid SAFEs'}:{' '}
          <span
            className={
              entrepreneurCompany ? styles.blueNumber : styles.greenNumber
            }
          >
            {entrepreneurCompany ? summary.totalSafeCount : paidSafes}
          </span>
        </Text>
        <span className={styles.circle} />
        <Text
          color={Color.neutral400}
          type={TextTypes.BODY_SMALL}
          weight={FontWeight.MEDIUM}
        >
          Unpaid SAFEs:{' '}
          <span className={styles.yellowNumber}>{summary.unpaidCount}</span>
        </Text>
      </Row>

      <Text
        color={Color.neutral400}
        type={TextTypes.BODY_SMALL}
        weight={FontWeight.MEDIUM}
        className="tw-mb-[20px]"
      >
        {entrepreneurCompany ? 'Company owner' : 'Invested companies'}:{' '}
        <span className={styles.owner}>
          {entrepreneurCompany
            ? company.owner.fullName
            : summary.numberOfInvestedCompanies}
        </span>
      </Text>

      <Row items="center" justify="between">
        <DashboardTeamMembers
          minimizedInviteButton
          companyId={company.id}
          companyName={company.name}
          teamMembers={company.teamMembers}
          owner={company.owner}
        />
        <SharedIcons.Arrow color={Color.primary400} size={24} />
      </Row>
    </div>
  )
}

export default CompanyCard
