import clsx from 'clsx'
import React, { FC, MouseEvent, useRef } from 'react'

import { ConditionalRender, LearnMoreLink } from '@/common/components'
import { FormatterHelper } from '@/common/helpers'
import { EditButton, LearnMoreModals, Terms } from '@/features/safe-note'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  Col,
  FontWeight,
  Radio,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './TermsListItem.module.scss'

export interface TermsListItemProps {
  id: Terms
  title: string
  description: string
  image: string
  imageOnActive?: string
  valuationCap?: number | null
  discountRate?: number | null
  active?: boolean
  onSelect?: (id: string) => void
  disabled?: boolean
  handleClearTerms: (id: string) => void
  openModal: (modalId: 'valuationCap' | 'discountRate') => void
  handleOpenLearnMore: (e: MouseEvent<HTMLElement>, id: LearnMoreModals) => void
}

const customTermsItems: {
  id: 'discountRate' | 'valuationCap'
  title: string
  description: string
  img: string
}[] = [
  {
    id: 'discountRate',
    title: 'Discount Rate',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
    img: SafeNoteImages.DiscountBig
  },
  {
    id: 'valuationCap',
    title: 'Valuation Cap',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
    img: SafeNoteImages.ValuationBig
  }
]

interface IValues {
  valuationCap?: number | null
  discountRate?: number | null
}

const TermsListItem: FC<TermsListItemProps> = ({
  id,
  title,
  description,
  image,
  imageOnActive,
  active,
  openModal,
  valuationCap,
  discountRate,
  onSelect,
  disabled = false,
  handleClearTerms,
  handleOpenLearnMore
}: TermsListItemProps) => {
  const values: IValues = { valuationCap, discountRate }
  const ref = useRef<any>()

  const onItemClick = () => {
    if (!disabled) {
      onSelect?.(id)
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' })
      }, 0)
    }
  }

  return (
    <div
      ref={ref}
      id="terms-list-item"
      className={clsx(
        styles.container,
        active && styles.active,
        disabled && styles.disabled
      )}
      onClick={onItemClick}
    >
      <Row items="start" justify="between" gap={10}>
        <div className={styles.radio}>
          <Radio name="terms" checked={active} />
        </div>

        <Col items="stretch" gap={18}>
          <Row items="start" justify="between" gap={10}>
            <Col items="start" gap={10}>
              <Text weight={FontWeight.SEMIBOLD} type={TextTypes.BODY_MAIN}>
                {title}
              </Text>
              <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.LEFT}>
                {description}
              </Text>
              <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.CENTER}>
                <LearnMoreLink
                  align="center"
                  onClick={(e) =>
                    handleOpenLearnMore(
                      e!,
                      id === Terms.HELP_SET_TERMS
                        ? LearnMoreModals.HELP_SET_TERMS
                        : LearnMoreModals.MFN
                    )
                  }
                />
              </Text>
            </Col>

            <ConditionalRender
              condition={
                active && (!!valuationCap || !!discountRate) && !!imageOnActive
              }
              fallbackElement={<img src={image} alt={title} />}
            >
              <img src={imageOnActive} alt={title} />
            </ConditionalRender>
          </Row>

          <ConditionalRender condition={id === Terms.HELP_SET_TERMS && active}>
            <Col items="stretch" gap={10}>
              {customTermsItems.map((item) => (
                <Row
                  items="start"
                  key={item.id}
                  gap={12}
                  className={styles.customTermsItem}
                >
                  <img src={item.img} alt={item.id} />

                  <Col gap={5}>
                    <Text
                      weight={FontWeight.SEMIBOLD}
                      type={TextTypes.BODY_DEFAULT}
                      className={styles.customItemTitle}
                    >
                      {!!values[item.id] && <SharedIcons.Checkmark size={15} />}
                      {item.title}
                      {!!values[item.id] && (
                        <Badge size="small">
                          {item.id === 'valuationCap'
                            ? FormatterHelper.toCurrency(values[item.id]!)
                            : FormatterHelper.toPercentage(values[item.id]!)}
                        </Badge>
                      )}
                    </Text>
                    <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.LEFT}>
                      {item.description} <br /> <LearnMoreLink />
                    </Text>
                  </Col>

                  <Col
                    items="center"
                    gap={5}
                    justify="center"
                    className="tw-ml-[10px] tw-self-center"
                  >
                    <EditButton
                      edit={!!values[item.id]}
                      onClick={(e) => {
                        e.stopPropagation()
                        openModal(item.id)
                      }}
                    />
                    <ConditionalRender condition={!!values[item.id]}>
                      <Row
                        items="center"
                        gap={5}
                        className={styles.clearBtn}
                        onClick={(e: MouseEvent<HTMLElement>) => {
                          e.stopPropagation()
                          handleClearTerms(item.id)
                        }}
                      >
                        <div className={styles.iconWrapper}>
                          <SharedIcons.Trash color={Color.neutral0} size={15} />
                        </div>
                        <Text
                          type={TextTypes.BODY_SMALL}
                          color={Color.error300}
                        >
                          Clear
                        </Text>
                      </Row>
                    </ConditionalRender>
                  </Col>
                </Row>
              ))}
            </Col>
          </ConditionalRender>
        </Col>
      </Row>
    </div>
  )
}

export default TermsListItem
