import { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { Verified } from '@/common/components/UserProfile/VerificationStatusIcons'
import { toCurrencyFormat } from '@/common/utils'
import { Color } from '@/packages/palette'
import { Row, Text, TextTypes } from '@/packages/ui'

import styles from './MoneyColumn.module.scss'

interface MoneyColumnProps {
  row: any
  accessor: string
  withApproveState?: boolean
}

const MoneyColumn: FC<MoneyColumnProps> = (props) => {
  const { row, accessor, withApproveState } = props
  const { isApproved } = row

  const formattedValue = toCurrencyFormat(row[accessor], '$')

  return (
    <Row items="center" gap={5}>
      <Text type={TextTypes.BODY_SMALL}>
        <ConditionalRender condition={isApproved && withApproveState}>
          <Verified color={Color.success400} />{' '}
        </ConditionalRender>
        {formattedValue}
      </Text>
      <div className={styles.line} />
    </Row>
  )
}

export default MoneyColumn
