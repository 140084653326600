import React, { FC } from 'react'

import deleteIcon from '@/features/auth/assets/images/delete-team-member.svg'
import { Color } from '@/packages/palette'
import {
  Button,
  Heading,
  HeadingTypes,
  Modal,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './DeleteTeamMemberModal.module.scss'

interface DeleteTeamMembersProps {
  closeModal: () => void
  name: string
  handleDelete: () => void
}

const DeleteTeamMembersModal: FC<DeleteTeamMembersProps> = (props) => {
  const { closeModal, name, handleDelete } = props

  return (
    <Modal
      id="delete-team-member-modal"
      size="sm"
      visible
      setVisible={closeModal}
    >
      <div className={styles.wrapper}>
        <img
          src={deleteIcon}
          alt="delete team member"
          className="tw-mb-[32px]"
        />
        <Heading
          type={HeadingTypes.H3}
          align={TextAlign.CENTER}
          className="tw-mb-[12px]"
        >
          Are you sure you want to delete {name || 'this team member'}?
        </Heading>
        <Text
          className="tw-mb-[32px]"
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral500}
          align={TextAlign.CENTER}
        >
          This action cannot be undone.
        </Text>
        <div className={styles.footer}>
          <Button
            width="default"
            type="button"
            appearance="secondary"
            onClick={closeModal}
            uppercase
          >
            Cancel
          </Button>

          <Button
            width="default"
            type="button"
            appearance="primary"
            onClick={handleDelete}
            uppercase
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteTeamMembersModal
