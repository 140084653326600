import * as Sentry from '@sentry/react'
import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ProtectedRouteLayout } from '@/common/layouts'
import {
  CompleteSignUpView,
  LoginView,
  LogoutView,
  OAuthLoginCallback,
  SignUpView,
  VerifyEmailView
} from '@/features/auth'
import {
  DashboardCompaniesLevelView,
  DashboardLayout,
  DashboardView
} from '@/features/dashboard'
import { MakeInvestmentsView } from '@/features/investments'
import {
  PaymentSettingsView,
  ProfileLayout,
  ProfileSettingsView,
  SupportView
} from '@/features/profile-settings'
import { PreviewSafe, SafeNoteView, ViewSafe } from '@/features/safe-note'
import { SafeNoteLayout } from '@/features/safe-note/layouts/SafeNoteLayout'
import RouterDispatcher from '@/router/RouterDispatcher'
import ROUTES from '@/router/routes'

const isProduction = import.meta.env.PROD
const SentryRoutes = isProduction
  ? Sentry.withSentryReactRouterV6Routing(Routes)
  : Routes

const RootRouter: FC = () => (
  <SentryRoutes>
    <Route path={ROUTES.LOGIN} element={<LoginView />} />
    <Route path={ROUTES.SIGN_UP} element={<SignUpView />} />
    <Route path={ROUTES.COMPLETE_SIGN_UP} element={<CompleteSignUpView />} />
    <Route
      path={ROUTES.OAUTH_LOGIN_CALLBACK}
      element={<OAuthLoginCallback />}
    />
    <Route path={ROUTES.LOGOUT} element={<LogoutView />} />
    <Route path={ROUTES.VERIFY_EMAIL} element={<VerifyEmailView />} />
    <Route path={ROUTES.PREVIEW_SAFE} element={<PreviewSafe />} />

    <Route element={<ProtectedRouteLayout />}>
      <Route element={<DashboardLayout />}>
        <Route
          path={ROUTES.DASHBOARD}
          element={<DashboardCompaniesLevelView />}
        />
        <Route path={ROUTES.DASHBOARD_COMPANY} element={<DashboardView />} />
        <Route
          path={ROUTES.DASHBOARD_ANGEL_DETAILS}
          element={<DashboardView />}
        />
        <Route
          path={ROUTES.MAKE_INVESTMENT}
          element={<MakeInvestmentsView />}
        />
      </Route>

      <Route element={<SafeNoteLayout />}>
        <Route
          path={ROUTES.CREATE_SAFE}
          element={<SafeNoteView viewMode={false} />}
        />
        <Route path={ROUTES.VIEW_SAFE} element={<ViewSafe />} />
      </Route>

      <Route element={<ProfileLayout />}>
        <Route path={ROUTES.PROFILE} element={<ProfileSettingsView />} />
        <Route
          path={ROUTES.PAYMENT_SETTINGS}
          element={<PaymentSettingsView />}
        />
        <Route path={ROUTES.SUPPORT} element={<SupportView />} />
      </Route>
    </Route>

    <Route path="*" element={<RouterDispatcher />} />
  </SentryRoutes>
)

export default RootRouter
