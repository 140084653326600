import { ProfileTypes } from '@/features/user/enums'

import Icons from './icons'

const advantages = {
  [ProfileTypes.ENTREPRENEUR]: [
    {
      icon: Icons.ChartSuccess,
      title: 'SAFEs',
      description:
        'Easily create, negotiate, and send SAFEs in exchange for seed investment. Efficiency is the name of the game.'
    },
    {
      icon: Icons.ClipboardTick,
      title: 'Term Sheets',
      description:
        'Prepare term sheets for prospective investors and get funding. Get your start-up start-ed.'
    },
    {
      icon: Icons.DollarCircle,
      title: 'Get Organized',
      description:
        'View and manage your SAFEs in one place. We like to keep things tidy around here.'
    },
    {
      icon: Icons.Cup,
      title: 'Set Goals',
      description:
        'Make investment goals for your company and check your progress. Score!'
    },
    {
      icon: Icons.Cup,
      title: 'Collaborate',
      description:
        'Add your co-founders or advisors as team members. Good company for your good company.'
    },
    {
      icon: Icons.DollarCircle,
      title: 'Link Your Bank',
      description:
        'When investors use this platform, you can link your bank account and get the money. Easy-peasy.'
    }
  ],
  [ProfileTypes.ANGEL]: [
    {
      icon: Icons.ClipboardTick,
      title: 'Term Sheets',
      description:
        'Prepare and send term sheets to startups you’re looking to invest in. Seal the deals you want.'
    },
    {
      icon: Icons.ChartSuccess,
      title: 'Sign Confidently',
      description:
        'Easily execute and add legal signatures to your SAFEs. Using ‘SAFE’ quite literally.'
    },
    {
      icon: Icons.DollarCircle,
      title: 'Investing Made Easy',
      description:
        'Use a bank account or any major credit card to invest through our platform. Plastic turned to potential.'
    },
    {
      icon: Icons.Cup,
      title: 'Get Organized',
      description:
        'View and manage your SAFEs in one place. We like to keep things tidy around here.'
    },
    {
      icon: Icons.Cup,
      title: 'Be Advised',
      description:
        'Invite your legal advisors and attorneys to view and manage your SAFEs via their own workspace. Come one, come all.'
    }
  ]
}

export default advantages
