import React, { FC, PropsWithChildren } from 'react'

import { TooltipKind } from '@/packages/ui'

import { Row } from '../grid'
import { Spacer } from '../spacer'
import { Tooltip } from '../tooltip'
import { FontWeight, Text, TextTypes } from '../typography'

export type LabelProps = {
  label?: string
  htmlFor?: string
  bold?: boolean
  className?: string
  color?: string | string[]
  tooltip?: string
}

const Label: FC<PropsWithChildren<LabelProps>> = ({
  label,
  htmlFor,
  bold = false,
  color,
  children,
  className,
  tooltip
}) => (
  <Row items="center">
    <label htmlFor={htmlFor} className={className}>
      {children}
      <Text
        type={TextTypes.BODY_DEFAULT}
        color={color}
        weight={bold ? FontWeight.MEDIUM : FontWeight.REGULAR}
        asSpan
      >
        {label}
      </Text>
    </label>
    <Spacer size={10} vertical />
    <Tooltip content={tooltip} kind={TooltipKind.INFO} disabled={!tooltip} />
  </Row>
)

export default Label
