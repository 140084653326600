import './SafeSlider.scss'

import React, { CSSProperties, FC } from 'react'
import ReactSlider from 'react-slider'

import { FontWeight, Text, TextTypes } from '@/packages/ui'

interface SafeSliderProps {
  value?: number
  onChange?: (value: number) => void
}

const SafeSlider: FC<SafeSliderProps> = ({
  value = 50,
  onChange
}: SafeSliderProps) => (
  <div
    className="safe-slider"
    style={{ '--safeValue': 100 - value } as CSSProperties}
  >
    <Text
      className="safe-slider__left-text"
      type={TextTypes.BODY_EXTRA_SMALL}
      weight={FontWeight.BOLD}
    >
      SAFE
    </Text>
    <Text
      className="safe-slider__right-text"
      type={TextTypes.BODY_EXTRA_SMALL}
      weight={FontWeight.BOLD}
    >
      Cash
    </Text>
    <ReactSlider
      data-content="test"
      className="safe-slider__slider"
      thumbClassName="safe-slider__thumb"
      trackClassName="safe-slider__track"
      max={100}
      value={value}
      onChange={(_value: number) => onChange?.(_value || 0)}
      renderThumb={(props) => (
        <span
          data-safe-value={`${value}%`}
          data-cash-value={`${100 - value}%`}
          {...props}
        />
      )}
    />
  </div>
)

export default SafeSlider
