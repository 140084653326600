import './Select.scss'

import clsx from 'clsx'
import React, {
  ChangeEvent,
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import Select from 'react-select'

import { ConditionalRender } from '@/common/components'
import { TeamMemberPermission } from '@/features/user'
import { useScreenSize } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import {
  Button,
  FontWeight,
  FormInputContext,
  IInputProps,
  RequiredFieldStar,
  Row,
  Spacer,
  Text,
  TextTypes
} from '@/packages/ui'

import { DropdownOption } from '../dropdown'
import { Label } from '../label'
import styles from './Select.module.scss'

// @ts-ignore
interface ISelectProps extends Omit<IInputProps, 'onChange'> {
  name: string
  required?: boolean
  options: DropdownOption[]
  onChange?: (value: string) => void
  disabled?: boolean
  className?: string
  label?: string
  small?: boolean
  withoutBorders?: boolean
  withSearch?: boolean
  tooltip?: string
  toTopOnFocus?: boolean
  custom?: boolean
  withPortal?: boolean
  CustomOption?: FC<any>
  NoOptionMessage?: FC<any>
  valueHolderClassName?: string
}

const FormatOptionLabel: FC<any> = ({ value, label, description }) => (
  <div className="custom-option">
    <Row gap={30} justify="between">
      <Row items="center" gap={4}>
        {value === TeamMemberPermission.CREATE && (
          <SharedIcons.Lock className="custom-option__icon" />
        )}
        <Text
          type={TextTypes.BODY_SMALL}
          weight={FontWeight.SEMIBOLD}
          className="custom-option__label"
        >
          {label}
        </Text>
      </Row>

      {value === TeamMemberPermission.CREATE && (
        <Button width="fit" className="custom-option__btn">
          Upgrade
        </Button>
      )}
    </Row>
    <Text type={TextTypes.BODY_SMALL} className="custom-option__description">
      {description}
    </Text>
  </div>
)

export const SelectComponent: FC<ISelectProps> = (props: ISelectProps) => {
  const {
    name,
    withPortal,
    withSearch = true,
    withoutBorders,
    required,
    value,
    disabled,
    className,
    options,
    NoOptionMessage,
    onChange,
    CustomOption,
    label,
    small,
    tooltip,
    toTopOnFocus,
    boldLabel = true,
    placeholder = 'Select...',
    custom,
    valueHolderClassName
    // invalid,
    // containerClassName
  } = props
  const { requiredFields } = useContext(FormInputContext)
  const _required = requiredFields?.includes?.(name) || required

  const ref: any = useRef()
  const { isMobile } = useScreenSize()

  const [selectedOption, setSelectedOption] = useState<unknown | null>()

  const selectedOptionObject = useMemo(() => {
    if (selectedOption) {
      return options.find((item) => item.value === selectedOption)
    }

    return null
  }, [selectedOption])

  const handleChange = (option: any | null) => {
    setSelectedOption(option)
    if (option?.value) {
      onChange?.(option.value)
    }
  }

  const onFocus = (e: ChangeEvent<HTMLInputElement>) => {
    if (!toTopOnFocus || !isMobile) return
    e.preventDefault()
    ref.current.scrollIntoView(true)
  }

  useEffect(() => {
    if (value) {
      setSelectedOption(value)
    } else {
      setSelectedOption(undefined)
    }
  }, [value])

  // @ts-ignore
  return (
    <div
      ref={ref}
      className={clsx(
        disabled && 'disabled-element',
        styles.container,
        className,
        small && styles.small
      )}
    >
      <ConditionalRender condition={!!label}>
        <RequiredFieldStar required={_required}>
          <Label
            className={clsx(disabled && styles.disabled)}
            label={label}
            bold={boldLabel}
            tooltip={tooltip}
          />
        </RequiredFieldStar>
        <Spacer size={8} />
      </ConditionalRender>

      <Select
        styles={{
          menuPortal: (_styles) => ({ ..._styles, zIndex: 9999 })
        }}
        isSearchable={withSearch}
        menuPortalTarget={withPortal ? document.body : undefined}
        placeholder={placeholder}
        value={selectedOptionObject}
        onChange={handleChange}
        options={options}
        onFocus={onFocus}
        classNamePrefix="react-select"
        isDisabled={disabled}
        className={clsx(
          valueHolderClassName,
          small && 'small',
          withoutBorders && 'without-borders'
        )}
        noOptionsMessage={NoOptionMessage}
        isOptionDisabled={(option) => !!option.disabled}
        formatOptionLabel={
          custom ? CustomOption || FormatOptionLabel : undefined
        }
      />
    </div>
  )
}

export const MemoSelect = memo<ISelectProps>(SelectComponent)
