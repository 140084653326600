import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const ChartSuccess: FC<IconProps> = withIcon((props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 13.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5H13"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 13.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5H13"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 13.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5H13"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 19.93L17.58 21.5L21 17.5"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 11V14"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 11V14"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 11V14"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const ClipboardTick: FC<IconProps> = withIcon((props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.30859 15.2002L10.8086 16.7002L14.8086 12.7002"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 6.5H14C16 6.5 16 5.5 16 4.5C16 2.5 15 2.5 14 2.5H10C9 2.5 8 2.5 8 4.5C8 6.5 9 6.5 10 6.5Z"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 4.51953C19.33 4.69953 21 5.92953 21 10.4995V16.4995C21 20.4995 20 22.4995 15 22.4995H9C4 22.4995 3 20.4995 3 16.4995V10.4995C3 5.93953 4.67 4.69953 8 4.51953"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const DollarCircle: FC<IconProps> = withIcon((props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.67188 14.8298C8.67188 16.1198 9.66188 17.1598 10.8919 17.1598H13.4019C14.4719 17.1598 15.3419 16.2498 15.3419 15.1298C15.3419 13.9098 14.8119 13.4798 14.0219 13.1998L9.99187 11.7998C9.20187 11.5198 8.67188 11.0898 8.67188 9.86984C8.67188 8.74984 9.54187 7.83984 10.6119 7.83984H13.1219C14.3519 7.83984 15.3419 8.87984 15.3419 10.1698"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 6.5V18.5"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

const Cup: FC<IconProps> = withIcon((props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.1484 17V19.1"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.14844 22.5H17.1484V21.5C17.1484 20.4 16.2484 19.5 15.1484 19.5H9.14844C8.04844 19.5 7.14844 20.4 7.14844 21.5V22.5V22.5Z"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M6.14844 22.5H18.1484"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 16.5C8.13 16.5 5 13.37 5 9.5V6.5C5 4.29 6.79 2.5 9 2.5H15C17.21 2.5 19 4.29 19 6.5V9.5C19 13.37 15.87 16.5 12 16.5Z"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.46906 12.1496C4.71906 11.9096 4.05906 11.4696 3.53906 10.9496C2.63906 9.94961 2.03906 8.74961 2.03906 7.34961C2.03906 5.94961 3.13906 4.84961 4.53906 4.84961H5.18906C4.98906 5.30961 4.88906 5.81961 4.88906 6.34961V9.34961C4.88906 10.3496 5.09906 11.2896 5.46906 12.1496Z"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5312 12.1496C19.2812 11.9096 19.9412 11.4696 20.4612 10.9496C21.3612 9.94961 21.9612 8.74961 21.9612 7.34961C21.9612 5.94961 20.8612 4.84961 19.4612 4.84961H18.8112C19.0112 5.30961 19.1112 5.81961 19.1112 6.34961V9.34961C19.1112 10.3496 18.9013 11.2896 18.5312 12.1496Z"
      stroke="#618CFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default {
  Cup,
  ChartSuccess,
  ClipboardTick,
  DollarCircle
}
