import React, { useCallback, useEffect } from 'react'

import { Terms } from '@/features/safe-note'
import { SafeForBubble, TermsList } from '@/features/safe-note/components'
import { useCreateSafeContext } from '@/features/safe-note/context'
import { Heading, Spacer, TextAlign } from '@/packages/ui'

import styles from './TermsStep.module.scss'

const TermsStep = () => {
  const { data, viewMode, updateData, setNextDisabled } = useCreateSafeContext()

  const handleSelectTerms = useCallback(
    (values: any) => {
      updateData(values, true)
    },
    [updateData]
  )

  useEffect(() => {
    const noCap = !data.valuationCapActive
    const noDiscount = !data.discountRateActive
    const helpSelectedWithoutValues =
      data.selectedTermsId === Terms.HELP_SET_TERMS && noCap && noDiscount

    setNextDisabled(!data.selectedTermsId || helpSelectedWithoutValues)
  }, [data])

  return (
    <div className={styles.container}>
      <SafeForBubble center name={data.recipientName} />

      <Spacer size={14} />
      <Heading align={TextAlign.CENTER}>Terms</Heading>

      <Spacer size={22} />
      <TermsList onSelect={handleSelectTerms} disabled={viewMode} {...data} />
    </div>
  )
}

export default TermsStep
