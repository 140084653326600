import clsx from 'clsx'
import { FC } from 'react'

import styles from './TooltipProgressBar.module.scss'

interface ITooltipProgressBarProps {
  size: number
  step: number
}

const TooltipProgressBar: FC<ITooltipProgressBarProps> = ({ size, step }) => (
  <div className={styles.tooltipProgress}>
    {Array.from({ length: size }, (_, index) => (
      <span
        key={`step_${step}_${index}`}
        className={clsx(styles.step, index === step && styles.active)}
      />
    ))}
  </div>
)

export default TooltipProgressBar
