import AuthStore from '@/features/auth/store/AuthStore'
import PaymentStore from '@/features/payment/store/PaymentStore'
import ProfileStore from '@/features/profile/store/ProfileStore'
import SafeNoteStore from '@/features/safe-note/store/SafeNoteStore'
import UserStore from '@/features/user/store/UserStore'

class Store {
  auth: AuthStore

  user: UserStore

  safeNote: SafeNoteStore

  payment: PaymentStore

  profile: ProfileStore

  constructor() {
    this.auth = new AuthStore(this)
    this.user = new UserStore(this)
    this.safeNote = new SafeNoteStore(this)
    this.payment = new PaymentStore(this)
    this.profile = new ProfileStore(this)
  }
}

const store = new Store()

export type RootStoreType = Store
export type StoreType = typeof store
export { store }

export default store
