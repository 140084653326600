import clsx from 'clsx'
import React, { FC } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { Notifications } from '@/common/components/HeaderBar/Notifications'
import {
  CompanyOptions,
  DashboardTeamMembers,
  WithdrawButton
} from '@/features/dashboard'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from './DashboardCompanyHeader.module.scss'

const DashboardCompanyHeader: FC<{
  company: any
  anotherOwner?: string
}> = (props) => {
  const { anotherOwner, company } = props

  const { type } = company

  const isEntrepreneur = type === ProfileTypes.ENTREPRENEUR

  const CompanyName = (
    <Text
      weight={FontWeight.BOLD}
      color={Color.neutral500}
      type={TextTypes.BODY_MAIN}
    >
      {company.name}
    </Text>
  )

  const NotMineCompanyName = (
    <Col>
      {CompanyName}
      <Text
        weight={FontWeight.SEMIBOLD}
        color={Color.neutral300}
        type={TextTypes.BODY_SMALL}
      >
        Owner: <span className={styles.ownerName}>{anotherOwner}</span>
      </Text>
    </Col>
  )

  return (
    <section className={styles.companyInfo}>
      <Row items="center" justify="between" gap={20}>
        <Row
          items="center"
          justify={!!anotherOwner ? 'between' : 'start'}
          className={!!anotherOwner ? 'tw-w-full' : ''}
        >
          <Row items="center">
            <Avatar
              size={!!anotherOwner ? 50 : 42}
              className={styles.companyLogo}
              name={company.name}
              image={company.image}
            />

            <ConditionalRender
              condition={!!anotherOwner}
              fallbackElement={CompanyName}
            >
              {NotMineCompanyName}
            </ConditionalRender>
          </Row>

          <Row items="center">
            <ConditionalRender condition={isEntrepreneur && !anotherOwner}>
              <div className={clsx(styles.leftSpacer, styles.rightSpacer)}>
                <WithdrawButton />
              </div>
            </ConditionalRender>

            <div
              className={clsx(
                !anotherOwner && styles.rightSpacer,
                !isEntrepreneur && !anotherOwner && styles.leftSpacer
              )}
            >
              <Notifications size={28} color={Color.neutral400} minimalistic />
            </div>

            <ConditionalRender condition={!anotherOwner}>
              <CompanyOptions />
            </ConditionalRender>
          </Row>
        </Row>

        <ConditionalRender condition={!anotherOwner}>
          <DashboardTeamMembers
            companyId={company.id}
            owner={company.owner}
            companyName={company.name}
            teamMembers={company.teamMembers || []}
          />
        </ConditionalRender>
      </Row>
    </section>
  )
}

export default DashboardCompanyHeader
