import { Badge, FontWeight, Text, TextTypes } from '@/packages/ui'
import { BadgeType } from '@/packages/ui/badge'
import {
  BadgeColumn,
  DiscountAndCap,
  MoneyColumn,
  SAFE,
  SignedColumn
} from '@/packages/ui/table/columns'
import { TableColumn, TableColumnType } from '@/packages/ui/table/types'

const statusCombinations = {
  draft: BadgeType.DEFAULT,
  sent: BadgeType.INFO,
  completed: BadgeType.SUCCESS
}

const columns = (viewAsAngel?: boolean): TableColumn[] => [
  {
    title: viewAsAngel ? 'Sender' : 'Recipient',
    accessor: 'safe',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: any) => <SAFE row={row} />
  },
  {
    title: 'Date',
    accessor: 'createdAt',
    type: TableColumnType.DATE
  },
  {
    title: viewAsAngel ? 'Recipient' : 'Sender',
    accessor: 'author',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: any) => (
      <Text type={TextTypes.BODY_SMALL} weight={FontWeight.SEMIBOLD}>
        {viewAsAngel ? row?.recipient?.fullName : row?.sender?.fullName}
      </Text>
    )
  },
  {
    title: 'Amount',
    accessor: 'safeAmount',
    type: TableColumnType.CUSTOM,
    Component: (props) => <MoneyColumn {...props} withApproveState />
  },
  {
    title: 'Discount | Cap',
    accessor: 'discount',
    type: TableColumnType.CUSTOM,
    Component: DiscountAndCap
  },
  {
    title: 'Status',
    accessor: 'status',
    type: TableColumnType.CUSTOM,
    Component: (props) => (
      <BadgeColumn {...props} statusesCombinations={statusCombinations} />
    )
  },
  {
    title: 'Payment',
    accessor: 'paid',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: any) => (
      <Badge lightMode type={row.paid ? BadgeType.SUCCESS : BadgeType.ERROR}>
        {row.paid ? 'Paid' : 'Not Paid'}
      </Badge>
    )
  },
  {
    title: 'Signed',
    accessor: 'signedUsers',
    type: TableColumnType.CUSTOM,
    Component: SignedColumn
  },
  {
    title: '',
    accessor: 'safeLink',
    type: TableColumnType.LINK,
    valueExtractor: () => 'View'
  }
  // {
  //   title: ' ',
  //   accessor: 'options',
  //   type: TableColumnType.OPTIONS,
  //   options: [
  //     {
  //       accessor: BASE_TABLE_ACTIONS.DELETE,
  //       title: 'Delete',
  //       icon: <Cancel />
  //     }
  //   ]
  // }
]

export default columns
