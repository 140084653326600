import { Logout, PaymentIcon, Profile, Support } from './OptionsIcons'

const userProfileOptions = [
  { id: 'user-settings', title: 'My profile', icon: Profile },
  { id: 'payment', title: 'Billing and subscriptions', icon: PaymentIcon },
  { id: 'support', title: 'Support', icon: Support },
  { id: 'logout', title: 'Logout', icon: Logout }
]

export default userProfileOptions
