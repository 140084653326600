import React, { FC, useCallback, useEffect, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import {
  AngelForm,
  EntrepreneurForm,
  JoinToCompany,
  SelectRole
} from '@/features/auth/components'
import {
  SignUpSteps,
  useCompleteSignUpContext
} from '@/features/auth/views/CompleteSignUpView/context'
import { ISafeNote } from '@/features/safe-note'
import {
  CreateAngelProfileDto,
  CreateCorporateAngelProfileDto,
  CreateEntrepreneurProfileDto,
  CreateNewProfileDto
} from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { BackButton, Button, Loader, Row, Spacer } from '@/packages/ui'

import styles from './AddNewProfile.module.scss'

export enum Steps {
  JOIN_TO_COMPANY = 'joinToCompany',
  SELECT_ROLE = 'selectRole',
  FILL_INFO = 'fillInfo'
}

const mapStateToProps = ({ user }: StoreType) => ({
  user: user.me,
  loading: user.functionLoading.fetchMe,
  requestsToJoin: user.sortedCompanies.requests,
  addNewCompany: user.addNewCompany,
  creatingProfileLoading: user.functionLoading.addNewCompany
})

type AddNewProfileProps = ReturnType<typeof mapStateToProps> & {
  onSuccess?: () => void
  predefinedRole?: ProfileTypes
  secondaryView?: boolean
  pendingSafes?: ISafeNote[]
  onScrollToTop?: () => void
  setSelectedRole?: (type: ProfileTypes) => void
}

const AddNewProfile: FC<AddNewProfileProps> = ({
  user,
  loading,
  requestsToJoin,
  pendingSafes,
  addNewCompany,
  onSuccess,
  secondaryView,
  onScrollToTop,
  predefinedRole,
  setSelectedRole,
  creatingProfileLoading
}: AddNewProfileProps) => {
  const [role, setRole] = useState<ProfileTypes | undefined>(predefinedRole)
  const [step, setActiveStep] = useState<Steps>(Steps.SELECT_ROLE)
  const [joinRole, setJoinRole] = useState<ProfileTypes | undefined>(undefined)

  const { setStep } = useCompleteSignUpContext()

  const showCompanyRequests = !!requestsToJoin.length && !predefinedRole

  const handleSelectRole = useCallback((_value: ProfileTypes) => {
    setRole(_value)
    setSelectedRole?.(_value)
  }, [])

  // trigger if user refresh the page
  useEffect(() => {
    if (role) setActiveStep(Steps.FILL_INFO)
  }, [])

  useEffect(() => {
    if (pendingSafes?.length && !role) handleSelectRole(ProfileTypes.ANGEL)
  }, [pendingSafes])

  useEffect(() => {
    if (showCompanyRequests) {
      setActiveStep(Steps.JOIN_TO_COMPANY)
    }
  }, [requestsToJoin, showCompanyRequests])

  useEffect(() => {
    onScrollToTop?.()

    if (step === Steps.SELECT_ROLE) {
      setStep?.(SignUpSteps.SELECT_ROLE)
    }

    if (step === Steps.JOIN_TO_COMPANY) {
      setStep?.(
        joinRole === ProfileTypes.ANGEL
          ? SignUpSteps.ANGEL_TEAM
          : SignUpSteps.TEAM
      )
    }
  }, [step, joinRole])

  const onNextStep = useCallback(async () => {
    if (role && step === Steps.SELECT_ROLE) {
      setActiveStep(Steps.FILL_INFO)
    }
  }, [role, step])

  const handleBack = useCallback(() => {
    setActiveStep(Steps.SELECT_ROLE)
  }, [])

  const handleFinish = useCallback(
    (
      values:
        | CreateNewProfileDto
        | CreateAngelProfileDto
        | CreateCorporateAngelProfileDto
        | CreateEntrepreneurProfileDto
    ) => {
      addNewCompany({
        data: values as CreateNewProfileDto,
        options: {
          onSuccess
        }
      })
    },
    [onSuccess]
  )

  const toSelectRole = () => {
    setActiveStep(Steps.SELECT_ROLE)
  }

  const JoinToCompanyStep = (
    <JoinToCompany
      setJoinRole={setJoinRole}
      toSelectRole={toSelectRole}
      companies={requestsToJoin}
    />
  )

  const SelectRoleStep = (
    <>
      <SelectRole
        role={role}
        pendingSafesNumber={pendingSafes?.length || 0}
        onSelect={handleSelectRole}
        secondaryView={secondaryView}
      />

      <Spacer size={40} />
      <Row
        items="center"
        justify={showCompanyRequests ? 'between' : 'end'}
        gap={40}
        className={styles.footer}
      >
        <ConditionalRender condition={showCompanyRequests}>
          <BackButton
            onClick={() => setActiveStep(Steps.JOIN_TO_COMPANY)}
            uppercase
          >
            Back
          </BackButton>
        </ConditionalRender>
        <Button onClick={onNextStep} disabled={!role} uppercase width="default">
          Next step
        </Button>
      </Row>
    </>
  )

  const renderComponent = () => {
    if (role === ProfileTypes.ENTREPRENEUR) {
      return (
        <EntrepreneurForm
          user={user!}
          backDisabled={!!predefinedRole}
          onBack={handleBack}
          onFinish={handleFinish}
          secondaryView={secondaryView}
          onScrollToTop={onScrollToTop}
          loading={creatingProfileLoading}
        />
      )
    }

    if (role === ProfileTypes.ANGEL) {
      return (
        <AngelForm
          user={user!}
          backDisabled={!!predefinedRole}
          onBack={handleBack}
          onFinish={handleFinish}
          onScrollToTop={onScrollToTop}
          loading={creatingProfileLoading}
        />
      )
    }

    return <div>Something bad happened</div>
  }

  if (step === Steps.FILL_INFO) {
    return renderComponent()
  }

  if (step === Steps.JOIN_TO_COMPANY) {
    return JoinToCompanyStep
  }

  return !loading ? SelectRoleStep : <Loader width="100%" />
}

export default withStore(mapStateToProps)(AddNewProfile)
