import clsx from 'clsx'
import { isNumber } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import isUUID from 'validator/lib/isUUID'

import { ConditionalRender } from '@/common/components'
import { FormatterHelper } from '@/common/helpers'
import { useToast } from '@/common/hooks'
import { StoreType, withStore } from '@/common/store'
import { useAppContext } from '@/context'
import { useModalContext } from '@/features/modals'
import {
  PayAs,
  PaymentBridgeChildrenProps,
  PaymentMethod,
  PaymentMethodData,
  PaymentOverview,
  PaymentStatus,
  PaymentType
} from '@/features/payment'
import {
  ISafeNote,
  SafeFor,
  SignSafeAs,
  ViewSafeAs
} from '@/features/safe-note'
import { SafeNote } from '@/features/safe-note/components'
import { SignatureData } from '@/features/safe-note/modals'
import { getRawSafeTemplate } from '@/features/safe-note/utils/raw-template'
import { ProfileTypes } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  FontWeight,
  FullScreenLoading,
  IFile,
  Info,
  Row,
  Spacer,
  StepperNavigation,
  Tab,
  Tabs,
  TextTypes,
  useStepper
} from '@/packages/ui'
import { useNavigator } from '@/router'

import steps from './steps'
import styles from './ViewSafe.module.scss'

const mapStateToProps = ({ user, safeNote, payment }: StoreType) => ({
  user: user.me,
  fetchSafeNote: safeNote.fetchOne,
  notifyToSign: safeNote.notifyToSign,
  signSafeNote: safeNote.sign,
  generatePdf: safeNote.generatePdf,
  fetchFee: safeNote.fetchFee,
  loading:
    safeNote.functionLoading.fetchOne || safeNote.functionLoading.fetchFee,
  generatePdfLoading: safeNote.functionLoading.generatePdf,
  createSafeNotePaymentIntent: payment.createSafeNotePaymentIntent
})

type ViewSafeProps = ReturnType<typeof mapStateToProps>

const viewAsMapper: any = {
  [ProfileTypes.ENTREPRENEUR]: ViewSafeAs.SENDER,
  [ProfileTypes.ANGEL]: ViewSafeAs.RECIPIENT
}

const ViewSafe: FC<ViewSafeProps> = ({
  user,
  fetchSafeNote,
  loading,
  fetchFee,
  notifyToSign,
  signSafeNote,
  generatePdf,
  generatePdfLoading,
  createSafeNotePaymentIntent
}: ViewSafeProps) => {
  const { id: safeNoteId } = useParams()
  const { setNavbarTitle } = useAppContext()
  const { reminderSentModal } = useModalContext()
  const navigate = useNavigator()
  const { showInfo } = useToast()

  const [paymentInProcess, setPaymentInProcess] = useState<boolean>(false)
  const [fee, setFee] = useState<any>({ platformFee: 0, stripeFee: 0 })
  const [safeNote, setSafeNote] = useState<undefined | ISafeNote>(undefined)
  const [currentTabId, setCurrentTabId] = useState<'safe' | 'cash'>('safe')
  const [nextDisabled, setNextDisabled] = useState<boolean>(true)
  const [reminderSent, setReminderSent] = useState<boolean>(false)
  const { currentStep, prevStep, setCurrentStep, nextStep, isLastStep } =
    useStepper(0, 2)

  const [tempSignature, setTempSignature] = useState<
    SignatureData | undefined
  >()
  const [recordPaymentFiles, setRecordPaymentFiles] = useState<
    IFile[] | undefined
  >()

  const viewAsInvestor =
    safeNote?.viewAs === ViewSafeAs.RECIPIENT &&
    safeNote?.safeFor === SafeFor.ANGEL

  const runFetchSafeNote = async () => {
    if (!safeNoteId) return
    if (!isUUID(safeNoteId)) return

    const data: ISafeNote = await fetchSafeNote({ data: { id: safeNoteId } })
    setSafeNote(data)

    const fromMe = data.viewAs === ViewSafeAs.SENDER

    setNavbarTitle(
      <>
        {data?.cashAmount ? 'SAFE + Cash' : 'SAFE'} {fromMe ? 'to' : 'from'}
        &nbsp;
        <span className={Color.primary400}>
          <b>
            {fromMe
              ? data.recipient?.fullName || '-'
              : data?.sender?.fullName || '-'}
          </b>
        </span>
      </>
    )
  }

  const runFetchFee = async () => {
    const _fee = await fetchFee({ data: { id: safeNoteId as string } })

    if (_fee?.totalFee) {
      setFee({ stripeFee: _fee.stripeFee, platformFee: _fee.platformFee })
    }
  }

  useEffect(() => {
    if (safeNoteId) {
      runFetchSafeNote()
      runFetchFee()
    }
  }, [safeNoteId])

  useEffect(() => {
    if (currentStep === 1) {
      runFetchSafeNote()
    }
  }, [currentStep])

  const safeAmount = isNumber(safeNote?.safeAmount)
    ? FormatterHelper.toCurrency(safeNote!.safeAmount)
    : '$0'
  const cashAmount = isNumber(safeNote?.cashAmount)
    ? FormatterHelper.toCurrency(safeNote!.cashAmount)
    : '$0'
  const isSafePlusCash = !!safeNote?.cashAmount && !!safeNote?.safeAmount

  const safeNotePaymentStatus = useMemo(() => {
    if (safeNote?.paid) {
      setNextDisabled(false)
      return PaymentStatus.SUCCESS
    }
    if (safeNote?.paymentId && !safeNote.paid) {
      setNextDisabled(false)
      return PaymentStatus.PENDING
    }

    return undefined
  }, [safeNote])

  const handleSign = useCallback(
    async (data: SignatureData) => {
      if (
        !safeNoteId ||
        !safeNote?.viewAs ||
        safeNote?.viewAs === ViewSafeAs.TEAM_MEMBER
      ) {
        return
      }

      if (!tempSignature) {
        setTempSignature(data)
        return
      }

      setTempSignature(undefined)
      const mapper = {
        [ViewSafeAs.SENDER]: SignSafeAs.SENDER,
        [ViewSafeAs.RECIPIENT]: SignSafeAs.RECIPIENT
      }

      await signSafeNote({
        data: {
          id: safeNoteId,
          signature: data.signature,
          signAs: mapper[safeNote.viewAs],
          name: data.name || user!.fullName
        },
        options: {
          onSuccess: runFetchSafeNote
        }
      })
    },
    [safeNoteId, runFetchSafeNote, signSafeNote, tempSignature]
  )

  const handleDownload = useCallback(() => {
    const template = getRawSafeTemplate()
    const fileName = `SAFE_${safeNote?.recipient.fullName}`

    generatePdf({
      data: {
        html: template,
        fileName
      }
    })
  }, [generatePdf, safeNote])

  const handleSendReminder = useCallback(() => {
    if (!safeNoteId) return

    notifyToSign({
      data: { id: safeNoteId },
      options: {
        onSuccess: () => {
          reminderSentModal.openModal()
          setReminderSent(true)
        }
      }
    })
  }, [safeNoteId])

  const handlePay = useCallback(
    ({
        processPayment,
        constructCardData,
        constructBankTransferData
      }: PaymentBridgeChildrenProps) =>
      async (_data: PaymentMethodData) => {
        setPaymentInProcess(true)

        if (!safeNoteId) {
          showInfo('Safe note is not exits')
        }

        if (_data.method === PaymentMethod.CARD) {
          const payment = await createSafeNotePaymentIntent({
            data: {
              type: PaymentType.CARD,
              data: constructCardData(_data),
              safeNoteId: safeNoteId as string,
              payAs: PayAs.ANGEL
            }
          })

          const status = await processPayment(PaymentType.CARD, payment, _data)

          if (
            status === PaymentStatus.FAILED ||
            status === PaymentStatus.CANCELED
          ) {
            setPaymentInProcess(false)
            return
          }

          await handleSign(tempSignature!)
          setNextDisabled(false)
        }

        if (_data.method === PaymentMethod.BANK_TRANSFER) {
          const payment = await createSafeNotePaymentIntent({
            data: {
              type: PaymentType.BANK_TRANSFER,
              data: constructBankTransferData(_data),
              safeNoteId: safeNoteId as string,
              payAs: PayAs.ANGEL
            }
          })

          const status = await processPayment(
            PaymentType.BANK_TRANSFER,
            payment,
            _data
          )

          if (
            status === PaymentStatus.FAILED ||
            status === PaymentStatus.CANCELED
          ) {
            setPaymentInProcess(false)
            return
          }

          await handleSign(tempSignature!)
          setNextDisabled(false)
        }

        setPaymentInProcess(false)
      },
    [safeNoteId, runFetchSafeNote]
  )

  const handleBack = useCallback(() => {
    if (currentStep === 0) {
      navigate.toDashboard()
      return
    }

    prevStep()
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [currentStep, prevStep])

  const handleSelectStep = (index: number) => {
    if (index < currentStep) {
      setCurrentStep(index)
    }
  }

  const handleNext = async () => {
    if (isLastStep) {
      if (tempSignature) {
        await handleSign(tempSignature!)
        setTempSignature(undefined)
      }
      navigate.toDashboard()
    }

    nextStep()
    window.scrollTo({ top: 0 })
  }

  const SafeNoteTabContent = (
    <SafeNote
      discountRateActive
      valuationCapActive={!!safeNote?.valuationCap}
      viewAs={
        safeNote?.viewAs === ViewSafeAs.TEAM_MEMBER
          ? viewAsMapper[safeNote?.senderCompany?.type]
          : safeNote?.viewAs || ViewSafeAs.SENDER
      }
      safeAmount={safeNote?.safeAmount}
      cashAmount={safeNote?.cashAmount}
      senderName={safeNote?.sender.fullName}
      recipientName={safeNote?.recipient?.fullName}
      companyName={safeNote?.senderCompany?.name}
      discountRate={safeNote?.discountRate}
      valuationCap={safeNote?.valuationCap}
      stateOfIncorporation={safeNote?.senderCompany?.stateOfIncorporation}
      mfn={safeNote?.mfn}
      signatureData={{
        tempSignature,

        senderName: safeNote?.senderSignName || safeNote?.sender?.fullName,
        senderSignDate: safeNote?.senderSignDate,
        senderSignature: safeNote?.senderSignature,

        recipientName:
          safeNote?.recipientSignName ||
          (viewAsInvestor ? safeNote?.recipient?.fullName : ''),
        recipientSignDate: safeNote?.recipientSignDate,
        recipientSignature: safeNote?.recipientSignature,
        allowSign: safeNote?.permissions.canSign,
        showSignButton: true,
        onSign: handleSign
      }}
      viewMode
      onDownload={handleDownload}
    />
  )

  const PaymentContent = (
    <PaymentOverview
      withoutCash
      stripeFee={fee.stripeFee}
      platformFee={fee.platformFee}
      authorProfile={safeNote?.senderCompany}
      discountRate={safeNote?.discountRate}
      valuationCap={safeNote?.valuationCap}
      safeStatus={safeNote?.paid}
      status={safeNotePaymentStatus}
      safeAmount={safeNote?.safeAmount}
      setRecordPaymentFiles={setRecordPaymentFiles}
      cashAmount={safeNote?.cashAmount}
      recipientName={
        viewAsInvestor
          ? safeNote?.sender?.fullName
          : safeNote?.recipient?.fullName
      }
      payerName={
        viewAsInvestor
          ? safeNote?.recipient?.fullName
          : safeNote?.sender?.fullName
      }
      onPayLater={navigate.toDashboard}
      onPay={handlePay}
      details={safeNote?.payment}
    />
  )

  const Footer = (
    <div className={clsx(styles.footer, currentStep === 0 && styles.static)}>
      <Button
        fullHeight
        width="default"
        onClick={handleNext}
        disabled={
          currentStep === 0
            ? !safeNote?.recipientSignature && !tempSignature
            : nextDisabled && !recordPaymentFiles?.length
        }
        uppercase
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLastStep ? 'Exit' : 'Next'}
      </Button>
      <StepperNavigation
        type="small"
        activeStep={currentStep}
        className={styles.navigation}
        steps={steps}
        itemTextType={TextTypes.BODY_DEFAULT}
        markPreviousAsCompleted={false}
        allowManuallyChangeStep
        onSelectStep={handleSelectStep}
      />

      <Button
        fullHeight
        width="default"
        className={styles.backBtn}
        appearance="secondary"
        onClick={handleBack}
        disabled={paymentInProcess}
        uppercase
      >
        <SharedIcons.Arrow size={30} className={styles.exitIcon} />
        Back
      </Button>
    </div>
  )

  useEffect(() => {
    const header = document.querySelector('#safe-note-layout__header header')
    const content = document.querySelector('#safe-note-layout__content')

    if (header) {
      header.classList.add(styles.static)
    }

    if (content) {
      content.classList.add(styles.fitContent)
    }
  }, [])

  const showSendReminderCondition = useMemo(
    () =>
      safeNote?.viewAs === ViewSafeAs.SENDER &&
      !safeNote.recipientSignature &&
      !reminderSent &&
      currentTabId === 'safe',
    [safeNote?.viewAs, safeNote?.recipientSignature, reminderSent, currentTabId]
  )

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FullScreenLoading loading={loading || generatePdfLoading} />

        <Spacer size={20} />
        <ConditionalRender condition={isSafePlusCash}>
          <Row justify="center">
            <Tabs
              withoutContent
              kind="switchKind"
              className={styles.tabsHeader}
              initialTabId={currentTabId}
              onTabChange={(key: any) => setCurrentTabId(key)}
            >
              <Tab
                id="safe"
                title={
                  <span>
                    SAFE:&nbsp;
                    <span className={FontWeight.SEMIBOLD}>{safeAmount}</span>
                  </span>
                }
              />
              <Tab
                id="cash"
                title={
                  <span>
                    Cash:&nbsp;
                    <span className={FontWeight.SEMIBOLD}>{cashAmount}</span>
                  </span>
                }
              />
            </Tabs>
          </Row>
          <Spacer size={20} />
        </ConditionalRender>

        {/* For entrepreneur when safe for angel */}
        <ConditionalRender
          condition={
            [ViewSafeAs.SENDER, ViewSafeAs.TEAM_MEMBER].includes(
              safeNote?.viewAs as ViewSafeAs
            ) && safeNote?.safeFor === SafeFor.ANGEL
          }
        >
          {SafeNoteTabContent}
        </ConditionalRender>

        {/* For entrepreneur when safe for freelancer */}
        <ConditionalRender
          condition={
            safeNote?.viewAs === ViewSafeAs.SENDER &&
            safeNote?.safeFor === SafeFor.AGENCY
          }
        >
          <div className={styles.tabsContent}>
            {currentTabId === 'safe' && SafeNoteTabContent}
            {currentTabId === 'cash' && PaymentContent}
          </div>
        </ConditionalRender>

        {/* For angel */}
        <ConditionalRender condition={viewAsInvestor}>
          {currentStep === 0 ? SafeNoteTabContent : PaymentContent}
        </ConditionalRender>
      </div>

      <ConditionalRender condition={showSendReminderCondition}>
        <Info className={styles.alertContainer} rounded={false}>
          Signature: Awaiting the counterparty&rsquo;s signature.&nbsp;
          <span className={styles.sendReminder} onClick={handleSendReminder}>
            Send a reminder
            <SharedIcons.Arrow className="tw-ml-5" />
          </span>
        </Info>
      </ConditionalRender>
      <ConditionalRender
        condition={
          safeNote?.viewAs === ViewSafeAs.RECIPIENT &&
          safeNote?.safeFor === SafeFor.ANGEL
        }
      >
        {Footer}
      </ConditionalRender>
    </div>
  )
}

export default withStore(mapStateToProps)(ViewSafe)
