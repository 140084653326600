import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import updatesIcon from '@/features/dashboard/assets/images/summary/updates.png'
import { useModalContext } from '@/features/modals'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Text,
  TextAlign,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './DashboardUpdates.module.scss'

interface DashboardUpdatesProps {
  role: ProfileTypes
  permission: TeamMemberPermission
}

const DashboardUpdates: FC<DashboardUpdatesProps> = (props) => {
  const { role, permission } = props

  const isEntrepreneur = role === ProfileTypes.ENTREPRENEUR
  const { dontHavePermissionModal } = useModalContext()

  const handleClick = () => {
    if (permission !== TeamMemberPermission.CREATE) {
      dontHavePermissionModal.openModal()
    }
  }

  return (
    <Col
      gap={11}
      items="center"
      justify="center"
      className={styles.updatesBlock}
    >
      <div className={styles.tooltip}>
        <Tooltip
          kind={TooltipKind.INFO}
          content={
            isEntrepreneur
              ? 'Got news to share? Let your investors know. You can send them updates from here, like why their best decision was investing with you.'
              : 'Lorem'
          }
        />
      </div>

      <img width={60} src={updatesIcon} alt="Updates" />

      <Text
        weight={FontWeight.SEMIBOLD}
        type={TextTypes.BODY_MAIN}
        align={TextAlign.CENTER}
        className="!tw-leading-snug"
      >
        {isEntrepreneur ? (
          <>Give investors the latest</>
        ) : (
          <>
            You don’t have any <br /> updates yet.
          </>
        )}
      </Text>

      <ConditionalRender
        condition={isEntrepreneur}
        fallbackElement={
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
            Lorem ipsum dolor sit amet, <br /> consectetur adipiscing elit.
          </Text>
        }
      >
        <Button
          width="fit"
          appearance="primary"
          // disabled={TeamMemberPermission.VIEW === permission}
          className="!tw-px-[20px] !tw-py-[10px]"
          onClick={handleClick}
        >
          Send update
        </Button>
      </ConditionalRender>
    </Col>
  )
}

export default DashboardUpdates
