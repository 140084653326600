import { FC } from 'react'

import { IconProps, withIcon } from '@/common/hocs'

const IncomingSafesIcon: FC<IconProps> = withIcon((props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.4987 14.167V9.16699L5.83203 10.8337"
      stroke="#4B73D8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 9.16699L9.16667 10.8337"
      stroke="#4B73D8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3346 8.33366V12.5003C18.3346 16.667 16.668 18.3337 12.5013 18.3337H7.5013C3.33464 18.3337 1.66797 16.667 1.66797 12.5003V7.50033C1.66797 3.33366 3.33464 1.66699 7.5013 1.66699H11.668"
      stroke="#4B73D8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3346 8.33366H15.0013C12.5013 8.33366 11.668 7.50033 11.668 5.00033V1.66699L18.3346 8.33366Z"
      stroke="#4B73D8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
))

export default IncomingSafesIcon
