import { serialize } from 'object-to-formdata'

import api, { FORM_DATA_HEADERS, withParams, withQuery } from '@/common/api'
import { dataUrlToFile } from '@/common/utils'
import { ICompany } from '@/features/profile/types'

import { CreateSafeNoteDto, ISafeNote, SignSafeDto } from './types'

export const SAFE_NOTE_ENDPOINTS = {
  CREATE: '/safe-note',
  FETCH_ALL: '/safe-note',
  FETCH_ONE: '/safe-note/:id',
  FETCH_FEE: '/safe-note/:id/fee',
  FETCH_WITH_TOKEN: '/safe-note/with-token',
  DELETE: '/safe-note/:id',
  SIGN_SAFE_NOTE: '/safe-note/:id/sign',
  NOTIFY_TO_SIGN: '/safe-note/:id/notify-to-sign',
  GENERATE_PDF: '/generate-pdf',
  PAYMENT_INTENT: '/safe-note/:id/payment-intent',
  GET_COMPANIES_SENDERS: '/safe-note/company-senders',
  FETCH_PENDING_SAFES: '/safe-note/pending-safes',
  ACCEPT_SAFE_NOTE: '/safe-note/:id/assign-company/:companyId'
}

export class SafeNoteRequests {
  static async create(dto: CreateSafeNoteDto): Promise<ISafeNote> {
    const formData = serialize(dto)
    formData.delete('signature')

    if (dto.mfn === true) {
      formData.delete('discountRate')
      formData.delete('valuationCap')
    }

    if (!dto.cashAmount) {
      formData.delete('cashAmount')
    }

    if (!dto.safeAmount) {
      formData.delete('safeAmount')
    }
    if (dto.signature) {
      const signature = await dataUrlToFile(dto.signature, 'senderSignature')

      formData.set('senderSignature', signature)
      formData.set('senderSignName', dto.signName)
      formData.set('senderSignDate', new Date().toISOString())
    }

    return api.post(SAFE_NOTE_ENDPOINTS.CREATE, formData, {
      headers: FORM_DATA_HEADERS
    })
  }

  static async fetchAll(
    entrepreneurCompanyId?: string,
    angelCompanyId?: string
  ): Promise<ISafeNote[]> {
    const query: { entrepreneurCompanyId?: string; angelCompanyId?: string } =
      {}

    if (!!entrepreneurCompanyId) {
      query.entrepreneurCompanyId = entrepreneurCompanyId
    }

    if (!!angelCompanyId) {
      query.angelCompanyId = angelCompanyId
    }

    return api.get(withQuery(SAFE_NOTE_ENDPOINTS.FETCH_ALL, query))
  }

  static async fetchPendingSafes(): Promise<ISafeNote[]> {
    return api.get(SAFE_NOTE_ENDPOINTS.FETCH_PENDING_SAFES)
  }

  static async acceptSafeNote(id: string, companyId: string): Promise<boolean> {
    return api.post(
      withParams(SAFE_NOTE_ENDPOINTS.ACCEPT_SAFE_NOTE, { id, companyId })
    )
  }

  static async fetchOne(id: string): Promise<ISafeNote> {
    return api.get(withParams(SAFE_NOTE_ENDPOINTS.FETCH_ONE, { id: id.trim() }))
  }

  static async fetchCompaniesSenders(
    angelCompanyId: string
  ): Promise<ICompany[]> {
    return api.get(
      withQuery(SAFE_NOTE_ENDPOINTS.GET_COMPANIES_SENDERS, { angelCompanyId })
    )
  }

  static async fetchFee(id: string): Promise<any> {
    return api.get(withParams(SAFE_NOTE_ENDPOINTS.FETCH_FEE, { id: id.trim() }))
  }

  static async fetchWithToken(token: string): Promise<ISafeNote> {
    return api.get(withQuery(SAFE_NOTE_ENDPOINTS.FETCH_WITH_TOKEN, { token }))
  }

  static async delete(id: string): Promise<void> {
    return api.delete(withParams(SAFE_NOTE_ENDPOINTS.DELETE, { id }))
  }

  static async sign(id: string, dto: SignSafeDto) {
    const formData = new FormData()

    const signature = await dataUrlToFile(dto.signature, 'signature.png')

    formData.set('signature', signature)
    formData.set('signAs', dto.signAs)
    formData.set('name', dto.name)

    return api.post(
      withParams(SAFE_NOTE_ENDPOINTS.SIGN_SAFE_NOTE, { id }),
      formData,
      {
        headers: FORM_DATA_HEADERS
      }
    )
  }

  static notifyToSign(id: string) {
    return api.post(withParams(SAFE_NOTE_ENDPOINTS.NOTIFY_TO_SIGN, { id }))
  }

  static generatePdf(html: string) {
    return api.post(
      SAFE_NOTE_ENDPOINTS.GENERATE_PDF,
      { html },
      { responseType: 'blob' }
    )
  }
}
