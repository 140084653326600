import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { NavigateFunction, useNavigate } from 'react-router-dom'

import ROUTES from '@/router/routes'
import { withRouteId } from '@/router/utils'

class Navigator {
  navigate: NavigateFunction

  constructor(navigate: NavigateFunction) {
    this.navigate = navigate
  }

  public to = (path: string) => {
    this.navigate(path)
  }

  public withParams = (url: string, params?: any) => {
    if (isEmpty(params)) {
      return url
    }

    return `${url}?${queryString.stringify(params)}`
  }

  public goBack = () => {
    this.navigate(-1)
  }

  public toLogin = (params?: { token: string }) => {
    this.navigate(this.withParams(ROUTES.LOGIN, params))
  }

  public toLogout = () => {
    this.navigate(ROUTES.LOGOUT)
  }

  public toSignUp = (params?: { token: string }) => {
    this.navigate(this.withParams(ROUTES.SIGN_UP, params))
  }

  public toCompleteSignUp = (params?: { token: string }) => {
    this.navigate(this.withParams(ROUTES.COMPLETE_SIGN_UP, params))
  }

  public toDashboard = () => {
    this.navigate(ROUTES.DASHBOARD)
  }

  public toProfile = (page = 'user-settings') => {
    this.navigate(withRouteId(ROUTES.PROFILE_COMMON, page, 'page'))
  }

  public toDashboardCompany = (companyId: string) => {
    this.navigate(withRouteId(ROUTES.DASHBOARD_COMPANY, companyId))
  }

  public toDashboardInnerCompany = (
    companyId: string,
    innerCompanyId: string
  ) => {
    this.navigate(
      withRouteId(
        withRouteId(ROUTES.DASHBOARD_ANGEL_DETAILS, companyId),
        innerCompanyId,
        'innerCompanyId'
      )
    )
  }

  public toCompanyProfile = () => {
    this.navigate(ROUTES.COMPANY_PROFILE)
  }

  public toViewSafeNote = (safeNoteId: string) => {
    this.navigate(withRouteId(ROUTES.VIEW_SAFE, safeNoteId))
  }

  public toPreviewSafe = () => {
    this.navigate(ROUTES.PREVIEW_SAFE)
  }

  public toCreateSafeNote = (companyId: string) => {
    this.navigate(withRouteId(ROUTES.CREATE_SAFE, companyId, 'companyId'))
  }

  public toHome = () => {
    this.navigate(ROUTES.HOME)
  }

  public toPricing = () => {
    this.navigate(ROUTES.PRICING)
  }

  public toAngels = () => {
    this.navigate(ROUTES.ANGELS)
  }

  public toAgencies = () => {
    this.navigate(ROUTES.AGENCIES)
  }

  public toEntrepreneurs = () => {
    this.navigate(ROUTES.ENTREPRENEURS)
  }
}

export function useNavigator() {
  const navigate = useNavigate()

  return new Navigator(navigate)
}
