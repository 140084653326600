import { FC } from 'react'

import { capitalize } from '@/common/utils'
import { Badge, BadgeType } from '@/packages/ui'

interface Combinations {
  [key: string]: BadgeType
}

interface BadgeColumnProps {
  row: any
  accessor: string
  statusesCombinations: Combinations
}

const BadgeColumn: FC<BadgeColumnProps> = (props) => {
  const { row, statusesCombinations, accessor } = props

  const value = row[accessor]
  const type = statusesCombinations[value] || BadgeType.INFO

  return (
    <Badge lightMode type={type}>
      {capitalize(value)}
    </Badge>
  )
}

export default BadgeColumn
