import React, { FC, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { HeaderBar } from '@/common/components'
import { useToast } from '@/common/hooks'
import { StoreType, useStore } from '@/common/store'
import { useAppContext } from '@/context'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { useNavigator } from '@/router'

import styles from './SafeNoteLayout.module.scss'

const SafeNoteLayout: FC = () => {
  const { me: user } = useStore((store: StoreType) => store.user)
  const navigate = useNavigator()
  const { navbarTitle } = useAppContext()
  const location = useLocation()
  const { showWarning } = useToast()

  useEffect(() => {
    const isCreateSafe = location.pathname.includes('create-safe-note')

    if (isCreateSafe && user?.id && !user?.emailVerified) {
      navigate.toDashboard()
      showWarning('You cannot create safe until verify your email!')
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header} id="safe-note-layout__header">
        <HeaderBar title={navbarTitle} withExit onExit={navigate.toDashboard} />
      </div>

      <div className={styles.content} id="safe-note-layout__content">
        <Outlet />
        <img
          className={styles.bgElement}
          src={SafeNoteImages.BackgroundElement}
          alt="element"
        />
      </div>
    </div>
  )
}

export default SafeNoteLayout
