import React, { FC, useEffect, useState } from 'react'
import { NumberFormatValues } from 'react-number-format'

import { LearnMoreLink } from '@/common/components'
import { SafeNoteImages } from '@/features/safe-note/assets'
import {
  Button,
  Heading,
  IModalWithCloseFn,
  Info,
  Modal,
  NumberInput,
  Row,
  Spacer,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './EditDiscountRateModal.module.scss'

interface EditDiscountRateModalProps extends IModalWithCloseFn {
  discountRate?: number
  onSelect?: (discountRate: number | undefined) => void
}

const EditDiscountRateModal: FC<EditDiscountRateModalProps> = ({
  closeModal,
  discountRate,
  onSelect
}: EditDiscountRateModalProps) => {
  const [stepIndex, setStepIndex] = useState<number>(0)
  const [state, setState] = useState({
    discountRate: undefined
  })

  const updateState = (newState: any) =>
    setState((prev) => ({ ...prev, ...newState }))

  useEffect(() => {
    if (discountRate) setStepIndex(1)

    updateState({ discountRate })
  }, [discountRate])

  const Step1 = (
    <>
      <Row justify="center">
        <img
          className="tw-auto"
          src={SafeNoteImages.DiscountBig}
          alt="discount-rate-image"
        />
      </Row>
      <Spacer size={30} />

      <Heading align={TextAlign.CENTER}>
        Do you want to edit discount rate?
      </Heading>

      <Spacer size={20} />
      <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.CENTER}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua, dolor sit amet,
        consectetur adipiscing elit.
      </Text>

      <Spacer size={20} />
      <Info>
        MFN holders need to get get this, this is the better rate you have given
        so far. We will automatically update their SAFEs.
      </Info>
    </>
  )

  const Step2 = (
    <>
      <Row justify="between" className="tw-mb-[5px]">
        <img src={SafeNoteImages.DiscountBig} alt="discount" width={90} />
      </Row>

      <Heading>Edit Discount Rate</Heading>

      <Spacer size={20} />
      <Text type={TextTypes.BODY_DEFAULT}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua, dolor sit amet,
        consectetur adipiscing elit.
      </Text>

      <Spacer size={20} />
      <NumberInput
        suffix="%"
        label="Discount Rate"
        decimalScale={3}
        placeholder="Discount Rate"
        value={state.discountRate}
        isAllowed={({ floatValue }: NumberFormatValues) =>
          floatValue ? floatValue <= 99 : true
        }
        onValueChange={(values: NumberFormatValues) =>
          updateState({ discountRate: values.floatValue })
        }
      />

      <Spacer size={20} />
      <Info>
        Industry standard is 10-20%. <LearnMoreLink />
      </Info>
    </>
  )

  const handleNextOrFinish = () => {
    if (stepIndex === 0) {
      setStepIndex(1)
      return
    }

    onSelect?.(state.discountRate)
    closeModal?.()
  }

  return (
    <Modal
      size="sm"
      id="editDiscountRateModal"
      className={styles.container}
      visible
      setVisible={closeModal}
    >
      {stepIndex === 0 ? Step1 : Step2}

      <Spacer size={40} />
      <Row gap={50} justify="between">
        <Button
          appearance="secondary"
          width="default"
          onClick={closeModal}
          uppercase
        >
          Cancel
        </Button>
        <Button
          width="default"
          uppercase
          onClick={handleNextOrFinish}
          disabled={stepIndex === 1 && !state.discountRate}
        >
          {stepIndex === 1 ? 'Set new discount rate' : 'Next'}
        </Button>
      </Row>
    </Modal>
  )
}

export default EditDiscountRateModal
