import { SafeFor } from '@/features/safe-note'
import * as yup from 'yup'

import { Regex } from '@/common/regex'
import { store } from '@/common/store'

export const RecipientStepSchema = yup
  .object({
    safeFor: yup.string().required(),
    recipientName: yup.string().required('Name is required'),
    recipientEmail: yup
      .string()
      .matches(Regex.Email, 'Invalid email')
      .matches(Regex.Email)
      .required('Email is required')
      .test('not-me', 'You cannot create SAFE for yourself', (value) => {
        const { me } = store.user
        return value !== me?.email
      }),
    transactionType: yup.string(),
    safeAmount: yup
      .number()
      .required('SAFE amount is required')
      .min(1, 'SAFE Amount should be positive'),
    totalAmount: yup
      .number()
      .when(['safeFor', 'transactionType'], ([value, value1], schema) =>
        value === SafeFor.AGENCY && value1 === 'safePlusCash'
          ? yup
              .number()
              .required('Total amount is required')
              .min(1, 'Total amount should be positive')
          : schema
      )
  })
  .required()
