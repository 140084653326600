import clsx from 'clsx'
import React, { FC } from 'react'

import { StaticIcons } from '@/assets/icons'
import { ConditionalRender } from '@/common/components'
import { SafeNoteIcons } from '@/features/safe-note/assets'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Button, FontWeight, Row, Text, TextTypes } from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './NotAuthorizedHeader.module.scss'

const NotAuthorizedHeader: FC<{
  withoutLogo?: boolean
  token: string | null
}> = (props) => {
  const { withoutLogo, token } = props
  const navigate = useNavigator()

  const params = token ? { token } : undefined

  const Tag = withoutLogo ? 'footer' : 'header'

  return (
    <Tag className={clsx(styles.header, withoutLogo && styles.withoutLogo)}>
      <ConditionalRender condition={!withoutLogo}>
        <StaticIcons.Logo className={styles.logo} />
      </ConditionalRender>
      <Row gap={10} items="center">
        <SharedIcons.Info width={15} height={15} color={Color.primary400} />
        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.primary400}
          weight={FontWeight.MEDIUM}
        >
          Document preview. To sign it you need to login or register.
        </Text>
      </Row>
      <Row gap={16} items="center">
        <Button
          appearance="ordinary"
          width="fit"
          uppercase
          onClick={() => navigate.toLogin(params)}
        >
          <Row gap={7} items="center">
            <SafeNoteIcons.LoginUser />
            Login
          </Row>
        </Button>
        <Button
          width="fit"
          uppercase
          className="!tw-px-[30px]"
          onClick={() => navigate.toSignUp(params)}
        >
          Sign up
        </Button>
      </Row>
    </Tag>
  )
}

export default NotAuthorizedHeader
