import React, { FC } from 'react'

import { toCurrencyFormat } from '@/common/utils'
import amountImg from '@/features/dashboard/modals/IncomingSafesModal/icons/amount.png'
import discountImg from '@/features/dashboard/modals/IncomingSafesModal/icons/discount.png'
import valuationImg from '@/features/dashboard/modals/IncomingSafesModal/icons/valuation.png'
import styles from '@/features/dashboard/modals/IncomingSafesModal/IncomingSafesModal.module.scss'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Text, TextAlign, TextTypes } from '@/packages/ui'

const Terms: FC<{ amount: number; discount?: number; valuation?: number }> = ({
  amount,
  discount,
  valuation
}) => (
  <div className={styles.terms}>
    <Col items="center">
      <img src={amountImg} alt="" />
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.neutral400}
        align={TextAlign.CENTER}
        weight={FontWeight.MEDIUM}
      >
        Amount
      </Text>
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.primary400}
        align={TextAlign.CENTER}
        weight={FontWeight.BOLD}
      >
        {toCurrencyFormat(amount, '$')}
      </Text>
    </Col>

    {!!valuation && (
      <Col items="center">
        <img src={valuationImg} alt="" />
        <Text
          type={TextTypes.BODY_SMALL}
          color={Color.neutral400}
          align={TextAlign.CENTER}
          weight={FontWeight.MEDIUM}
        >
          Valuation Cap
        </Text>
        <Text
          type={TextTypes.BODY_SMALL}
          color={Color.primary400}
          align={TextAlign.CENTER}
          weight={FontWeight.BOLD}
        >
          {toCurrencyFormat(valuation || 0, '$')}
        </Text>
      </Col>
    )}

    {!!discount && (
      <Col items="center">
        <img src={discountImg} alt="" />
        <Text
          type={TextTypes.BODY_SMALL}
          color={Color.neutral400}
          align={TextAlign.CENTER}
          weight={FontWeight.MEDIUM}
        >
          Discount Rate
        </Text>
        <Text
          type={TextTypes.BODY_SMALL}
          color={Color.primary400}
          align={TextAlign.CENTER}
          weight={FontWeight.BOLD}
        >
          {discount || 0}%
        </Text>
      </Col>
    )}
  </div>
)

export default Terms
