import { IPayment } from '@/features/payment'
import { IEntrepreneurProfile } from '@/features/profile/api/types'
import { IUser } from '@/features/user'

export enum SafeNoteStatus {
  DRAFT = 'draft',
  SENT = 'sent',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed'
}

export enum ViewSafeAs {
  SENDER = 'sender',
  RECIPIENT = 'recipient',
  TEAM_MEMBER = 'teamMember'
}

export enum SignSafeAs {
  SENDER = 'sender',
  RECIPIENT = 'recipient'
}

export enum SafeFor {
  ANGEL = 'angel',
  AGENCY = 'agency'
}

export interface CreateSafeNoteDto {
  profileId: string
  recipientEmail: string
  recipientName: string
  safeAmount: number
  cashAmount: number
  discountRate?: number
  valuationCap?: number
  mfn?: boolean
  paymentId?: string
  signature: string
  signName: string
  safeFor: SafeFor
}

export interface SignSafeDto {
  signAs: SignSafeAs
  signature: string
  name: string
}

export interface SafeNotePermissions {
  canView: boolean
  canSign: boolean
}

export interface ISafeNote {
  id: string
  senderCompany: IEntrepreneurProfile
  safeAmount: number
  cashAmount: number
  discountRate: number
  mfn: boolean
  status: SafeNoteStatus
  paid: boolean
  paymentId: string
  payment: IPayment
  safeFor: SafeFor

  sender: IUser
  senderSignature: string
  senderSignDate: string
  senderSignName: string

  recipientId: string
  recipient: IUser
  recipientSignature: string
  recipientSignDate: string
  recipientSignName: string

  viewAs: ViewSafeAs
  permissions: SafeNotePermissions

  createdAt: string
  updatedAt: string

  valuationCap?: number
}
