import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'

import { ConditionalRender } from '@/common/components'
import { useStore } from '@/common/store'
import { SafeFor } from '@/features/safe-note'
import { SafeNoteImages } from '@/features/safe-note/assets'
import {
  CardItem,
  SafeForBubble,
  SafeForItem,
  SafeSlider
} from '@/features/safe-note/components'
import { useCreateSafeContext } from '@/features/safe-note/context'
import { RecipientStepSchema } from '@/features/safe-note/schemas'
import { useDebounceEffect, useSmartState } from '@/packages/hooks'
import {
  Col,
  Form,
  FormHelper,
  FormItem,
  Info,
  Label,
  NumberInput,
  Row,
  Spacer,
  TextInput
} from '@/packages/ui'

import styles from './RecipientStep.module.scss'

export enum TransactionType {
  ONLY_SAFE = 'onlySafe',
  SAFE_PLUS_CASH = 'safePlusCash'
}

const RecipientStep = () => {
  const { getPublicUserByEmail, me } = useStore((store) => store.user)
  const { data, viewMode, updateData, setNextDisabled } = useCreateSafeContext()

  const [userFound, setUserFound] = useState<boolean>(false)

  const onScroll = useCallback(() => {
    const element = document.getElementById('transaction-types')

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }, [])

  const [state, setState] = useSmartState({
    sliderValue: 50,
    safeAmountHint: '50%',
    cashAmountHint: '50%',
    cashPercent: 50,
    safePercent: 50
  })

  const {
    clearErrors,
    control,
    register,
    watch,
    getValues,
    setValue,
    trigger,
    reset,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(RecipientStepSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const recipientName = watch('recipientName')
  const transactionType = watch('transactionType')
  const isSafePlusCash = transactionType === TransactionType.SAFE_PLUS_CASH

  useEffect(() => {
    reset(data)
  }, [])

  useEffect(() => {
    setNextDisabled(!isValid)
    setNextDisabled(!isValid)
  }, [isValid])

  const updateSafeAndCashAmount = (totalAmount: number, percentage = 0) => {
    if (!totalAmount) return

    if (percentage === 0) {
      setValue('safeAmount', 0)
      setValue('cashAmount', totalAmount)
      return
    }

    if (percentage === 100) {
      setValue('safeAmount', totalAmount)
      setValue('cashAmount', 0)
      return
    }

    const safeAmount: number = +(
      Math.round(totalAmount * percentage) / 100
    ).toFixed(2)
    const cashAmount: number = +(totalAmount - safeAmount).toFixed(2)

    setValue('safeAmount', safeAmount)
    setValue('cashAmount', cashAmount)
  }

  useEffect(() => {
    if (isSafePlusCash) {
      updateSafeAndCashAmount(
        getValues().totalAmount,
        Number(state.sliderValue)
      )
    }
  }, [watch('totalAmount')])

  useEffect(() => {
    if (transactionType === TransactionType.SAFE_PLUS_CASH) {
      const { totalAmount, safeAmount } = getValues()
      setValue('totalAmount', totalAmount || safeAmount || 0)

      updateSafeAndCashAmount(
        totalAmount || safeAmount,
        Number(state.sliderValue)
      )
      trigger()
      return
    }

    if (transactionType === TransactionType.ONLY_SAFE) {
      const totalAmount = getValues().safeAmount
      const safeAmount = getValues().totalAmount

      setValue('safeAmount', totalAmount || safeAmount)
    }
  }, [transactionType, getValues])

  const handleSliderChange = (value: number) => {
    updateSafeAndCashAmount(getValues().totalAmount, value)

    setState({
      sliderValue: Number(value),
      safeAmountHint: `${value}%`,
      cashAmountHint: `${100 - value}%`,
      safePercent: value,
      cashPercent: 100 - value
    })
  }

  useDebounceEffect(
    async () => {
      const email = watch('recipientEmail')

      if (email && email === me?.email) {
        return
      }
      if (!errors?.recipientEmail?.message && email) {
        const potentialUser = await getPublicUserByEmail({ data: { email } })

        if (potentialUser && potentialUser.fullName) {
          setUserFound(true)
          setValue('recipientName', potentialUser.fullName)
          clearErrors('recipientName')

          return
        }

        setUserFound(false)
      }
    },
    500,
    [watch('recipientEmail')]
  )

  // clear immediately after changing recipientEmail
  useDebounceEffect(
    async () => {
      setUserFound(false)
    },
    0,
    [watch('recipientEmail')]
  )

  useDebounceEffect(
    () => {
      updateData(
        {
          ...getValues(),
          ...state
        },
        true
      )
    },
    300,
    [updateData, JSON.stringify(watch()), state]
  )

  const formDisabled = viewMode

  const CommonFields = (
    <>
      <FormItem errors={errors.recipientEmail?.message}>
        <TextInput
          {...register('recipientEmail')}
          autoFocus
          label="Email of Recipient"
          placeholder="Email of Recipient"
          invalid={FormHelper.isFieldInvalid('recipientEmail', errors)}
          disabled={formDisabled}
        />
      </FormItem>

      <FormItem errors={errors.recipientName?.message}>
        <TextInput
          {...register('recipientName')}
          label="Name of Recipient"
          placeholder="Name of Recipient"
          invalid={FormHelper.isFieldInvalid('recipientName', errors)}
          disabled={formDisabled || userFound}
        />
      </FormItem>
    </>
  )

  const SafeOnlyFields = (
    <>
      {CommonFields}
      <FormItem errors={errors.safeAmount?.message}>
        <Controller
          control={control}
          name="safeAmount"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              prefix="$"
              label="SAFE Amount"
              value={value}
              onValueChange={(val: NumberFormatValues) => {
                onChange(val.floatValue || 0)
              }}
              placeholder="$"
              name="safeAmount"
              invalid={FormHelper.isFieldInvalid('safeAmount', errors)}
              disabled={formDisabled}
            />
          )}
        />
      </FormItem>
    </>
  )

  const SafePlusCashFields = (
    <>
      <FormItem errors={errors.totalAmount?.message}>
        <Controller
          control={control}
          name="totalAmount"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              prefix="$"
              name="totalAmount"
              label="Total Amount"
              placeholder="Total Amount"
              invalid={FormHelper.isFieldInvalid('totalAmount', errors)}
              value={value}
              onValueChange={(val: NumberFormatValues) => {
                onChange(val.floatValue || 0)
              }}
              disabled={formDisabled}
            />
          )}
        />
      </FormItem>

      <Row gap={16}>
        <FormItem>
          <Controller
            control={control}
            name="safeAmount"
            render={({ field: { value } }) => (
              <NumberInput
                prefix="$"
                addonAfter={state.safeAmountHint}
                placeholder="$"
                label="SAFE Amount"
                name="safeAmount"
                value={value}
                disabled
              />
            )}
          />
        </FormItem>

        <FormItem>
          <Controller
            control={control}
            name="cashAmount"
            render={({ field: { value } }) => (
              <NumberInput
                prefix="$"
                addonAfter={state.cashAmountHint}
                placeholder="$"
                name="cashAmount"
                label="Cash Amount"
                value={value}
                disabled
              />
            )}
          />
        </FormItem>
      </Row>

      <SafeSlider value={state.sliderValue} onChange={handleSliderChange} />

      <Info>
        By splitting the amount, you intend to send a (1) <b>SAFE</b> and a (2){' '}
        <b>Cash</b> payment to your intended recipient. You will be prompted to
        make a cash payment (optional) in the last step.
      </Info>
    </>
  )

  const FreelancerFields = (
    <>
      {CommonFields}
      <Col>
        <Label label="Type of transaction" tooltip="Lorem" bold />
        <Spacer size={8} />
        <Row gap={16} id="transaction-types">
          <Controller
            control={control}
            name="transactionType"
            render={({ field: { onChange, value } }) => (
              <CardItem
                image={SafeNoteImages.OnlySafe}
                title="Only SAFE"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et."
                smallImage
                active={value === TransactionType.ONLY_SAFE}
                onSelect={() => {
                  onChange(TransactionType.ONLY_SAFE)
                  onScroll()
                }}
                disabled={formDisabled}
              />
            )}
          />

          <Controller
            control={control}
            name="transactionType"
            render={({ field: { onChange, value } }) => (
              <CardItem
                image={SafeNoteImages.SafePlusCash}
                title="SAFE + Cash"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et."
                smallImage
                active={value === TransactionType.SAFE_PLUS_CASH}
                onSelect={() => {
                  onChange(TransactionType.SAFE_PLUS_CASH)
                  onScroll()
                }}
                disabled={formDisabled}
              />
            )}
          />
        </Row>
      </Col>

      <ConditionalRender
        condition={!!transactionType && !isSafePlusCash}
        fallbackElement={isSafePlusCash ? SafePlusCashFields : <></>}
      >
        <FormItem errors={errors.safeAmount?.message}>
          <Controller
            control={control}
            name="safeAmount"
            render={({ field: { onChange, value } }) => (
              <NumberInput
                prefix="$"
                placeholder="$"
                name="safeAmount"
                label="SAFE Amount"
                value={value}
                onValueChange={(val: NumberFormatValues) => {
                  onChange(val.floatValue || 0)
                }}
                invalid={FormHelper.isFieldInvalid('safeAmount', errors)}
                disabled={formDisabled}
              />
            )}
          />
        </FormItem>
      </ConditionalRender>
    </>
  )

  const renderFields = () => {
    const safeFor = watch('safeFor')

    if (safeFor === SafeFor.ANGEL) {
      return SafeOnlyFields
    }

    if (safeFor === SafeFor.AGENCY) {
      return FreelancerFields
    }

    return <></>
  }

  return (
    <div className={styles.container}>
      <SafeForBubble center name={recipientName} />

      <Spacer size={40} />
      <Row gap={16}>
        <Controller
          control={control}
          name="safeFor"
          render={({ field: { onChange, value } }) =>
            watch('safeFor') ? (
              <CardItem
                title="Angel"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
                image={SafeNoteImages.Angel}
                tooltip="Lorem ipsum"
                active={value === SafeFor.ANGEL}
                onSelect={() => onChange(SafeFor.ANGEL)}
                disabled={formDisabled}
              />
            ) : (
              <SafeForItem
                title="Angel"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
                image={SafeNoteImages.AngelBig}
                onSelect={() => onChange(SafeFor.ANGEL)}
              />
            )
          }
        />

        <Controller
          control={control}
          name="safeFor"
          render={({ field: { onChange, value } }) =>
            watch('safeFor') ? (
              <CardItem
                title="Freelancer"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
                image={SafeNoteImages.Freelancer}
                tooltip="Lorem ipsum"
                active={value === SafeFor.AGENCY}
                onSelect={() => onChange(SafeFor.AGENCY)}
                disabled
              />
            ) : (
              <SafeForItem
                disabled
                title="Freelancer"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
                image={SafeNoteImages.FreelancerBig}
                onSelect={() => onChange(SafeFor.AGENCY)}
              />
            )
          }
        />
      </Row>

      <Spacer size={32} />
      <Form schema={RecipientStepSchema}>{renderFields()}</Form>
    </div>
  )
}

export default observer(RecipientStep)
