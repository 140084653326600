import { ToastContent } from 'react-toastify'
import { ToastOptions } from 'react-toastify/dist/types'

import { ToastService } from '@/common/services/ToastService'

export function useToast() {
  const show = (content: ToastContent, options: ToastOptions) => {
    ToastService.show(content, options)
  }

  const showWarning = (content: ToastContent) => {
    ToastService.showWarning(content)
  }

  const showInfo = (content: ToastContent) => {
    ToastService.showInfo(content)
  }

  const showSuccess = (content: ToastContent) => {
    ToastService.showSuccess(content)
  }

  return {
    showWarning,
    showInfo,
    showSuccess,
    show
  }
}
