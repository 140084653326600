import clsx from 'clsx'
import { FC, memo, SyntheticEvent } from 'react'

import { Label, LabelProps } from '../label'
import { ChangeEvent } from '../types'
import styles from './Checkbox.module.scss'

export interface CheckboxProps extends Pick<LabelProps, 'label' | 'tooltip'> {
  name: string
  small?: boolean
  checked?: boolean
  value?: string
  disabled?: boolean
  alwaysBold?: boolean
  type?: 'checkbox' | 'radio'
  onChange?: (value: boolean, event: ChangeEvent) => void
  onClick?: (event: SyntheticEvent) => void
  className?: string
}

const Checkbox: FC<CheckboxProps> = ({
  small,
  value,
  onChange,
  name,
  label = '',
  type = 'checkbox',
  onClick,
  disabled,
  tooltip,
  alwaysBold,
  className,
  ...rest
}: CheckboxProps) => {
  const handleChange = (event: ChangeEvent) => {
    onChange?.(event.target.checked, event)
  }

  const handleClick = (event: SyntheticEvent) => {
    onClick?.(event)
  }

  return (
    <Label
      className={clsx(
        styles[type],
        small && styles.small,
        !label && styles.withoutLabel,
        className
      )}
      label={label}
      bold={rest.checked || alwaysBold}
      tooltip={tooltip}
    >
      <input
        type={type}
        id={value}
        name={name}
        value={value}
        disabled={disabled}
        className={styles.input}
        onChange={handleChange}
        onClick={handleClick}
        {...rest}
      />
      <span className={styles.checkmark} />
    </Label>
  )
}
export default memo(Checkbox)
