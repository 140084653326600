import Angel from './roles/Angel.png'
import Entrepreneur from './roles/Entrepreneur.png'
import Advisor from './roles/Advisor.png'

import LoginAside from './login-aside.png'
import SignUpAside from './signup-aside.png'
import SignUpCompleteAside from './signup-complete.png'

import EntrepreneurAsideGeneral from './entrepreneur/1step_Signup.png'
import EntrepreneurAsideDetails from './entrepreneur/2step_Signup.png'
import EntrepreneurAsideTeam from './entrepreneur/3step_Signup.png'
import EntrepreneurWelcomePage from './entrepreneur/Entrepreneur_Welcomepage.png'
import EntrepreneurAddTeamMember from './entrepreneur/AddTeamMember.png'
import EntrepreneurFillInfo from './entrepreneur/Popup_Missed data.png'

import AngelAsideGeneral from './angel/1step_Signup.png'
import AngelAsideDetails from './angel/2step_Signup.png'
import AngelAsideTeam from './angel/3step_Signup.png'
import AngelWelcomePage from './angel/Angel_Welcomepage.png'
import AngelAddTeamMember from './angel/AddTeamMember.png'

export const RoleImages = {
  Entrepreneur,
  Angel,
  Advisor
}

export const AsideImages = {
  LoginAside,
  SignUpAside,
  SignUpCompleteAside,

  EntrepreneurAsideGeneral,
  EntrepreneurAsideDetails,
  EntrepreneurAsideTeam,

  AngelAsideGeneral,
  AngelAsideDetails,
  AngelAsideTeam
}

export const AddTeamMemberImages = {
  EntrepreneurAddTeamMember,
  AngelAddTeamMember
}

export const WelcomeCharacters = {
  EntrepreneurWelcomePage,
  AngelWelcomePage
}

export const FillCompanyImages = {
  EntrepreneurFillInfo
}
