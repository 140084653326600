import React, { FC, useEffect, useMemo, useState } from 'react'

import { ConditionalRender, Search } from '@/common/components'
import companiesSorting from '@/common/constants/companiesSorting'
import { StoreType, withStore } from '@/common/store'
import { CompaniesList, DashboardEmptyScreen } from '@/features/dashboard'
import { TeamIcon } from '@/features/dashboard/assets'
import { useDashboardNavigationContext } from '@/features/dashboard/context'
import {
  AddNewProfileModal,
  IncomingSafesModal,
  InvitationsModal
} from '@/features/dashboard/modals'
import { ICompanySummary } from '@/features/profile/api/types'
import { ICompany } from '@/features/profile/types'
import { ISafeNote } from '@/features/safe-note'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Loader,
  Row,
  Select,
  Text,
  TextTypes
} from '@/packages/ui'

import IncomingSafesIcon from '../../assets/icons/IncomingSafesIcon'
import styles from './DashboardCompaniesLevelView.module.scss'

const mapStateToProps = ({ user, safeNote, profile }: StoreType) => ({
  companiesList: user.sortedCompanies,
  myId: user.me?.id,
  fetchMultipleSummaries: profile.fetchSummaries,
  refetchCompany: user.refetchCompany,
  fetchPendingSafes: safeNote.fetchPendingSafes,
  loading:
    user.functionLoading.fetchMe || user.functionLoading.fetchPendingSafes
})

type CompaniesLevelViewProps = ReturnType<typeof mapStateToProps>

const DashboardCompaniesLevelView: FC<CompaniesLevelViewProps> = (props) => {
  const {
    refetchCompany,
    fetchMultipleSummaries,
    companiesList,
    myId,
    loading,
    fetchPendingSafes
  } = props

  const [summaries, setSummaries] = useState<ICompanySummary[]>([])
  const [pendingSafes, setPendingSafes] = React.useState<ISafeNote[]>([])
  const { selectedProfile = ProfileTypes.ENTREPRENEUR } =
    useDashboardNavigationContext()

  const { visible, closeModal, openModal } = useModal<
    'invites' | 'pendingSafes' | 'addProfile'
  >()

  const [search, setSearch] = React.useState<string>()
  const [sort, setSort] = React.useState<string>(companiesSorting[0].value)

  const profileCompanies = companiesList[selectedProfile]
  const requests = companiesList.requests.filter(
    (company) => company.type === selectedProfile
  )

  const noPendingSafes =
    selectedProfile === ProfileTypes.ENTREPRENEUR ? true : !pendingSafes.length
  const noCompanies = !profileCompanies.length && !requests.length

  const companies: ICompany[] = useMemo(() => {
    const sortedCompanies: ICompany[] = profileCompanies.filter(
      (company: ICompany) => {
        const isMyCompany = company.owner.id === myId

        if (sort === 'shared') return !isMyCompany
        if (sort === 'mine') return isMyCompany

        return true
      }
    )

    const filteredCompanies = !!search
      ? sortedCompanies.filter((company) =>
          company.name.toLowerCase().includes(search.toLowerCase())
        )
      : sortedCompanies

    return filteredCompanies
  }, [profileCompanies, search, sort, myId])

  const _fetchPendingSafes = async () => {
    try {
      const result = await fetchPendingSafes()

      if (!!result) {
        setPendingSafes(result)
      } else {
        setPendingSafes([])
      }
    } catch {
      setPendingSafes([])
    }
  }

  const fetchSummaries = async () => {
    try {
      const result = await fetchMultipleSummaries({
        data: { type: selectedProfile }
      })

      if (Array.isArray(result)) {
        setSummaries(result)
        return
      }

      setSummaries([])
    } catch {
      setSummaries([])
    }
  }

  useEffect(() => {
    if (selectedProfile === ProfileTypes.ANGEL) {
      _fetchPendingSafes()
    }

    fetchSummaries()
  }, [selectedProfile])

  if (loading) {
    return (
      <Row
        items="center"
        justify="center"
        className="tw-self-stretch tw-w-full"
      >
        <Loader width="100%" height="100%" />
      </Row>
    )
  }

  const filterOutPendingSafes = (id: string, companyId?: string) => {
    setPendingSafes((prev) => prev.filter((item) => item.id !== id))

    if (companyId) {
      refetchCompany({ data: { id: companyId } })
    }
  }

  const closeSafesModal = () => {
    closeModal()
    fetchSummaries()
  }

  const closeInvitesModal = () => {
    closeModal()
    fetchSummaries()
  }

  if (noCompanies && noPendingSafes) {
    return <DashboardEmptyScreen key={selectedProfile} type={selectedProfile} />
  }

  return (
    <Col gap={24} className={styles.wrapper}>
      {visible === 'invites' && (
        <InvitationsModal
          companies={requests}
          myId={myId}
          closeModal={closeInvitesModal}
        />
      )}

      {visible === 'pendingSafes' && (
        <IncomingSafesModal
          safes={pendingSafes}
          myId={myId}
          filterOutPendingSafes={filterOutPendingSafes}
          closeModal={closeSafesModal}
          toCreateProfile={() => openModal('addProfile')}
          availableCompanies={profileCompanies}
        />
      )}

      {visible === 'addProfile' && (
        <AddNewProfileModal
          predefinedRole={selectedProfile}
          closeModal={closeModal}
        />
      )}

      <Row items="center" justify="between" gap={20}>
        <Row items="center" gap={15}>
          <Heading type={HeadingTypes.H2} color={Color.neutral500}>
            Companies ({companies?.length})
          </Heading>

          <ConditionalRender condition={!!requests.length}>
            <div className={styles.spacer} />
            <Row
              items="center"
              gap={5}
              className="tw-cursor-pointer"
              onClick={() => openModal('invites')}
            >
              <TeamIcon />
              <Text
                type={TextTypes.BODY_SMALL}
                color={Color.primary500}
                weight={FontWeight.SEMIBOLD}
              >
                View invitations ({requests.length})
              </Text>
            </Row>
          </ConditionalRender>

          <ConditionalRender
            condition={
              !!pendingSafes.length && selectedProfile === ProfileTypes.ANGEL
            }
          >
            <div className={styles.spacer} />
            <Row
              items="center"
              gap={5}
              className="tw-cursor-pointer"
              onClick={() => openModal('pendingSafes')}
            >
              <IncomingSafesIcon />
              <Text
                type={TextTypes.BODY_SMALL}
                color={Color.primary500}
                weight={FontWeight.SEMIBOLD}
              >
                Incoming SAFEs ({pendingSafes.length})
              </Text>
            </Row>
          </ConditionalRender>
        </Row>

        <Row items="stretch" gap={14}>
          <Select
            small
            name="sort"
            placeholder="Sort By"
            value={sort}
            options={companiesSorting}
            onChange={(value: string) => setSort(value)}
          />
          <Search
            search={search}
            onSearchChange={(e) => setSearch(e.target.value)}
          />
        </Row>
      </Row>

      <ConditionalRender
        condition={['all', 'mine'].includes(sort) && !profileCompanies.length}
        fallbackElement={
          <CompaniesList
            summaries={summaries}
            type={selectedProfile}
            companies={companies}
            sharedAmount={companies.length}
            sharedOpened={sort === 'shared'}
          />
        }
      >
        <DashboardEmptyScreen type={selectedProfile} />
      </ConditionalRender>
    </Col>
  )
}

export default withStore(mapStateToProps)(DashboardCompaniesLevelView)
