import clsx from 'clsx'
import React, { FC } from 'react'

import { FontWeight, Text, TextTypes } from '@/packages/ui'
import { WithOnClick } from '@/packages/ui/types'

import { IStep } from '../../types'
import styles from './Item.module.scss'

interface ItemProps extends WithOnClick, IStep {
  index: number
  active?: boolean
  textType?: TextTypes
  notClickable?: boolean
  type: 'default' | 'small' | 'circled'
}

const Item: FC<ItemProps> = ({
  title,
  index,
  active,
  hidden,
  textType,
  onClick,
  type = 'default',
  notClickable
}: ItemProps) => {
  if (hidden) return <></>

  return (
    <li
      className={clsx(
        styles.container,
        active && styles.active,
        type === 'small' && styles.small,
        type === 'circled' && styles.circled,
        notClickable && styles.notClickable
      )}
      onClick={onClick}
    >
      <div className={clsx(styles.step)}>
        {type === 'circled' ? index + 1 : null}
      </div>
      <Text
        type={textType}
        weight={
          type === 'circled' && active ? FontWeight.MEDIUM : FontWeight.REGULAR
        }
      >
        {title}
      </Text>
    </li>
  )
}

export default Item
