export enum ProfileTypes {
  ENTREPRENEUR = 'entrepreneur',
  ANGEL = 'angel',
  ADVISOR = 'advisor'
}

export enum ProfileTypesNames {
  entrepreneur = 'Entrepreneur',
  angel = 'Angel Investor',
  agency = 'Agency/Freelancer',
  corporateAngel = 'Corporate Angel'
}
