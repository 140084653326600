import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'
import { UsaStates } from 'usa-states'

import { ConditionalRender, UploadImage } from '@/common/components'
import { EntrepreneurSchema } from '@/features/auth/schemas/AuthSchema'
import {
  entrepreneurStepsMapper,
  useCompleteSignUpContext
} from '@/features/auth/views/CompleteSignUpView/context'
import {
  CreateEntrepreneurProfileDto,
  ITeamMember,
  IUser
} from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import {
  BackButton,
  Button,
  Form,
  FormHelper,
  FormItem,
  Heading,
  MaskInput,
  NumberInput,
  Row,
  Select,
  Spacer,
  Step,
  Stepper,
  Text,
  TextInput,
  TextTypes,
  useStepper
} from '@/packages/ui'

import { AddTeamMembers, IComponentTeamMember, IOwner } from './AddTeamMembers'
import styles from './EntrepreneurForm.module.scss'
import VerificationCheckmarkBlock from './VerificationCheckmarkBlock/VerificationCheckmarkBlock'

interface EntrepreneurFormProps {
  user?: IUser
  onBack?: () => void
  onFinish?: (data: CreateEntrepreneurProfileDto) => void
  backButtonText?: string
  secondaryView?: boolean
  onScrollToTop?: () => void
  loading: boolean
  backDisabled?: boolean
}

const states = new UsaStates().states.map((item) => ({
  value: item.name.toLowerCase(),
  label: item.name
}))

const EntrepreneurForm: FC<EntrepreneurFormProps> = ({
  user,
  onBack,
  onFinish,
  loading,
  backButtonText = 'Back',
  secondaryView,
  backDisabled,
  onScrollToTop
}) => {
  const { currentStep, prevStep, nextStep } = useStepper(0, 3)

  const { setStep } = useCompleteSignUpContext()

  const [teamMembers, setTeamMembers] = useState<IComponentTeamMember[]>([])

  const [photo, setPhoto] = useState<any>(undefined)
  const [logo, setLogo] = useState<any>(undefined)

  const {
    watch,
    control,
    register,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(EntrepreneurSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      name: '',
      ownerTitle: '',
      stateOfIncorporation: '',
      einNumber: undefined,
      address1: undefined,
      address2: undefined,
      city: undefined,
      state: '',
      zipCode: undefined,
      country: 'USA'
    }
  })

  const owner: IOwner = {
    email: user?.email || '',
    name: user?.fullName || '',
    position: watch('ownerTitle'),
    photo: photo || user?.image
  }

  useEffect(() => {
    onScrollToTop?.()

    setStep?.(entrepreneurStepsMapper[currentStep])
  }, [currentStep])

  const handleBack = useCallback(() => {
    if (currentStep === 0) {
      onBack?.()
      return
    }

    prevStep()
  }, [currentStep])

  const handleNext = useCallback(() => {
    if (currentStep === 2) {
      const values = getValues()

      const mappedTeamMembers: ITeamMember[] =
        teamMembers.map(
          (member) =>
            ({
              email: member.email,
              fullName: member.name,
              permission: member.permission
            } as ITeamMember)
        ) || []

      onFinish?.({
        type: ProfileTypes.ENTREPRENEUR,
        image: logo,
        data: {
          name: values.name,
          stateOfIncorporation: values.stateOfIncorporation,
          einNumber: values.einNumber,
          ownerPosition: owner.position,
          teamMembers: mappedTeamMembers,
          address: {
            address1: values.address1,
            address2: values.address2,
            country: values.country,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode
          }
        },
        userData: {
          fullName: owner.name,
          image: owner.photo
        }
      })

      return
    }
    nextStep()
  }, [isValid, currentStep, getValues, owner, teamMembers])

  const Step1 = (
    <>
      <Heading>Tell us about yourself</Heading>
      <Spacer size={16} />
      <Text type={TextTypes.BODY_DEFAULT}>
        Tell us your company role and upload your photo so we can get you
        started.
      </Text>

      <Spacer size={26} />
      <Form schema={EntrepreneurSchema}>
        <FormItem errors={errors.ownerTitle?.message}>
          <TextInput
            label="Company title"
            placeholder="Company title"
            {...register('ownerTitle')}
            invalid={FormHelper.isFieldInvalid('ownerTitle', errors)}
          />
        </FormItem>

        <ConditionalRender condition={!user?.image}>
          <UploadImage
            description="Pick a photo to showcase on your profile. You can always change this later in settings. "
            label="Upload your photo"
            caption="Upload Photo (PNG or JPG)"
            image={photo}
            onImageSelect={setPhoto}
          />
        </ConditionalRender>
      </Form>
    </>
  )

  const Step2 = (
    <>
      <Row justify="between" items="center" gap={50}>
        <Heading>Tell us about your company</Heading>
      </Row>
      <Spacer size={16} />
      <Text type={TextTypes.BODY_DEFAULT}>
        Provide some details about your startup.
      </Text>

      <Spacer size={26} />
      <Form schema={EntrepreneurSchema}>
        <FormItem errors={errors.name?.message}>
          <TextInput
            label="Company name"
            placeholder="Company name"
            {...register('name')}
            invalid={FormHelper.isFieldInvalid('name', errors)}
          />
        </FormItem>

        <UploadImage
          required
          label="Company logo"
          caption="Upload Logo (PNG or JPG)"
          image={logo}
          onImageSelect={setLogo}
        />

        <FormItem errors={errors.country?.message}>
          <TextInput
            {...register('country')}
            disabled
            label="Country"
            tooltip="We’re currently only working within the USA, but hold tight, MySAFEnotes will be available in other countries shortly."
            placeholder="Country"
            invalid={FormHelper.isFieldInvalid('country', errors)}
          />
        </FormItem>

        <FormItem errors={errors.stateOfIncorporation?.message}>
          <Controller
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                name="stateOfIncorporation"
                label="State of Incorporation"
                placeholder="State of Incorporation"
                options={states}
                value={value!}
                toTopOnFocus
                onChange={(val: string) => onChange(val)}
              />
            )}
            name="stateOfIncorporation"
          />
        </FormItem>

        <FormItem errors={errors.einNumber?.message}>
          <Controller
            control={control}
            name="einNumber"
            render={({ field: { onChange, value, ...rest } }) => (
              <MaskInput
                {...rest}
                onValueChange={(val: NumberFormatValues) =>
                  onChange(val.floatValue)
                }
                tooltip="An Employer Identification Number (EIN) is also known as a Federal Tax Identification Number, and is used to identify a business entity"
                value={value}
                format="##-#######"
                label="EIN Number (tax ID)"
                placeholder="EIN Number (tax ID)"
                invalid={FormHelper.isFieldInvalid('einNumber', errors)}
              />
            )}
          />
        </FormItem>

        <FormItem errors={errors.address1?.message}>
          <TextInput
            {...register('address1')}
            label="Address"
            placeholder="Address"
            invalid={FormHelper.isFieldInvalid('address1', errors)}
          />
        </FormItem>

        <FormItem>
          <TextInput
            {...register('address2')}
            label="Apartment, suite, etc."
            placeholder="Apartment, suite, etc."
          />
        </FormItem>

        <FormItem errors={errors.city?.message}>
          <TextInput
            {...register('city')}
            label="City"
            placeholder="City"
            invalid={FormHelper.isFieldInvalid('city', errors)}
          />
        </FormItem>

        <FormItem errors={errors.state?.message}>
          <Controller
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                name="state"
                label="State"
                placeholder="State"
                options={states}
                value={value!}
                toTopOnFocus
                onChange={(val: string) => onChange(val)}
              />
            )}
            name="state"
          />
        </FormItem>

        <FormItem errors={errors.zipCode?.message}>
          <Controller
            control={control}
            name="zipCode"
            render={({ field: { onChange, value } }) => (
              <NumberInput
                value={value}
                onValueChange={(val: NumberFormatValues) => {
                  onChange(val.value)
                }}
                label="Zip code"
                placeholder="Zip code"
                name="zipCode"
                invalid={FormHelper.isFieldInvalid('zipCode', errors)}
                thousandSeparator={false}
              />
            )}
          />
        </FormItem>
      </Form>

      <VerificationCheckmarkBlock />
    </>
  )

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Stepper
          navigationType="circled"
          activeStep={currentStep}
          markPreviousAsCompleted={false}
          navigationClassName={styles.stepper}
        >
          <Step title="Your Info">{Step1}</Step>
          <Step title="Company Info">{Step2}</Step>
          <Step title="Your Team">
            <AddTeamMembers
              owner={owner}
              type={ProfileTypes.ENTREPRENEUR}
              teamMembers={teamMembers}
              onUpdateTeamMembers={setTeamMembers}
              secondaryView={secondaryView}
              company={watch('name') || 'Company'}
            />
          </Step>
        </Stepper>
      </div>

      <Spacer size={40} />
      <Row justify="between" gap={50} items="center" className={styles.footer}>
        <ConditionalRender condition={!(backDisabled && currentStep === 0)}>
          <BackButton disabled={loading} onClick={handleBack} uppercase>
            {backButtonText}
          </BackButton>
        </ConditionalRender>

        <Button
          width="default"
          loading={loading}
          onClick={handleNext}
          uppercase
          disabled={
            currentStep === 0
              ? !watch('ownerTitle')
              : !isValid || !logo || loading
          }
        >
          {currentStep === 2 ? 'Finish' : 'Next step'}
        </Button>
      </Row>
    </div>
  )
}

export default EntrepreneurForm
