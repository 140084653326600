import { FC } from 'react'

import searchIcon from '@/assets/icons/search-icon.svg'
import NoteIcon from '@/features/dashboard/assets/images/note.png'
import { Col, Heading, HeadingTypes, Spacer } from '@/packages/ui'

interface NothingFoundProps {
  isSearch?: boolean
  text?: string
}

const NothingFound: FC<NothingFoundProps> = ({ text, isSearch }) => (
  <Col items="center" justify="center" className="tw-min-h-[320px]">
    <img
      src={isSearch ? searchIcon : NoteIcon}
      alt="Search"
      className="tw-max-w-[180px]"
    />

    <Spacer size={24} />
    <Heading type={HeadingTypes.H3}>
      {text || 'Sorry, no results found for your query.'}
    </Heading>
  </Col>
)

export default NothingFound
