import React, { FC, useMemo } from 'react'

import { ConditionalRender } from '@/common/components'
import { StoreType, useStore, withStore } from '@/common/store'
import addCompanyImage from '@/features/dashboard/assets/images/add-company.png'
import { CompanyCard } from '@/features/dashboard/components'
import { AddNewProfileModal } from '@/features/dashboard/modals'
import { ICompanySummary, ISummaryItem } from '@/features/profile/api/types'
import { ICompany } from '@/features/profile/types'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Button, FontWeight, Loader, Row, Text, TextTypes } from '@/packages/ui'
import { NoItems } from '@/packages/ui/table/views'

import styles from './CompaniesList.module.scss'
import noItemsImg from './images/no-shared-img.png'

interface CompaniesListProps {
  companies: ICompany[]
  sharedAmount: number
  sharedOpened: boolean
  summaries: ICompanySummary[]
  type: ProfileTypes.ANGEL | ProfileTypes.ENTREPRENEUR
}

const mapStateToProps = ({ profile }: StoreType) => ({
  loading: profile.functionLoading.fetchSummaries
})

interface IFormattedSummaries {
  [key: string]: ISummaryItem
}

const CompaniesList: FC<
  CompaniesListProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const { loading, summaries, type, companies, sharedAmount, sharedOpened } =
    props

  const formattedSummaries: IFormattedSummaries = useMemo(
    () =>
      summaries.reduce(
        (result: IFormattedSummaries, nextItem: ICompanySummary) => {
          const values = { ...result }

          values[nextItem.id] = nextItem.summary

          return values
        },
        {}
      ),
    [summaries]
  )

  const { visible, closeModal, openModal } = useModal<boolean>(false)
  const myId = useStore((store) => store.user.me?.id)

  const Modal = (
    <>
      {visible && (
        <AddNewProfileModal predefinedRole={type} closeModal={closeModal} />
      )}
    </>
  )

  if (loading) {
    return <Loader width="100%" />
  }

  if (sharedAmount === 0 && sharedOpened) {
    return (
      <>
        {Modal}
        <NoItems
          onActionClick={openModal}
          action="Create own company"
          imgWidth={158}
          img={noItemsImg}
          title="You have not been invited to any other company yet"
        />
      </>
    )
  }

  const AddCompanyCard = (
    <div className={styles.addCompany}>
      <img src={addCompanyImage} width={93} alt="" className="tw-mb-[24px]" />
      <Text
        color={Color.neutral500}
        type={TextTypes.BODY_DEFAULT}
        weight={FontWeight.BOLD}
        className="tw-mb-[16px]"
      >
        Add a New Company
      </Text>

      <Button width="default" onClick={() => openModal()}>
        <Row items="center" gap={5}>
          <SharedIcons.PlusThin color={Color.neutral0} size={20} />
          Add Company
        </Row>
      </Button>
    </div>
  )

  return (
    <div className={styles.cards}>
      {Modal}
      <ConditionalRender condition={!sharedOpened}>
        {AddCompanyCard}
      </ConditionalRender>
      {companies.map((company: ICompany) => (
        <CompanyCard
          company={company}
          myId={myId}
          key={company.id}
          summary={formattedSummaries?.[company.id]}
        />
      ))}
    </div>
  )
}

export default withStore(mapStateToProps)(CompaniesList)
