import React, { FC, PropsWithChildren, useContext, useMemo } from 'react'

import { ISafeNote } from '@/features/safe-note/api/types'

interface CreateSafeContextValue {
  data: any
  updateData: (data: any, immediateUpdate?: boolean) => void
  setNextDisabled: (value: boolean) => void
  createSafeNote: (extraData?: any) => Promise<ISafeNote | undefined>
  checkIfSafeNoteCreated: () => Promise<ISafeNote>
  viewMode: boolean
  downloadPdf: () => void
}

const CreateSafeContext = React.createContext<CreateSafeContextValue>(
  {} as CreateSafeContextValue
)

const CreateSafeContextProvider: FC<
  PropsWithChildren<CreateSafeContextValue>
> = (props) => {
  const providerValue = useMemo(() => props, [props])

  return <CreateSafeContext.Provider value={providerValue} {...props} />
}

const useCreateSafeContext = () => useContext(CreateSafeContext)

export { CreateSafeContextProvider, useCreateSafeContext }
