import clsx from 'clsx'
import React, { FC } from 'react'

import { FormatterHelper } from '@/common/helpers'
import { PaymentImages } from '@/features/payment/assets'
import {
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Spacer,
  Text,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './PaymentDetails.module.scss'

interface PaymentDetailsProps {
  cashAmount: number
  platformFee?: number
  stripeFee?: number
  className?: string
}

const PaymentDetails: FC<PaymentDetailsProps> = ({
  cashAmount,
  platformFee = 0,
  stripeFee = 0,
  className
}: PaymentDetailsProps) => {
  const totalAmount = cashAmount + platformFee + stripeFee

  const listItems = [
    {
      text: 'Cash Amount',
      value: cashAmount
    },
    {
      text: "MySAFEnotes' Fee",
      value: platformFee
    },
    {
      text: 'Credit/Debit Transaction Fee',
      value: stripeFee
    },
    {
      text: 'Total Amount',
      value: totalAmount
    }
  ]

  return (
    <>
      <div className={clsx(styles.detailsSection, className)}>
        <Row items="center" justify="between" gap={30}>
          <Heading type={HeadingTypes.H3}>Payment Details</Heading>
          <Text type={TextTypes.BODY_DEFAULT}>
            Total Amount:&nbsp;
            <span className={styles.totalAmount}>
              {FormatterHelper.toCurrency(totalAmount)}
            </span>
          </Text>
        </Row>
        <Spacer size={24} />

        <div className={styles.details}>
          <div className={styles.paymentIcon}>
            <img src={PaymentImages.PaymentIcon} alt="Payment" />
          </div>
          <ul className={styles.detailsList}>
            {listItems.map((item, index) => (
              <li key={index}>
                <Row items="center" justify="between" gap={20}>
                  <Row items="center" gap={10}>
                    <Text
                      weight={FontWeight.SEMIBOLD}
                      type={TextTypes.BODY_SMALL}
                    >
                      {item.text}
                    </Text>
                    <Tooltip
                      kind={TooltipKind.INFO}
                      content="some explanation"
                    />
                  </Row>
                  <span className={styles.itemPrice}>
                    {FormatterHelper.toCurrency(item.value)}
                  </span>
                </Row>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default PaymentDetails
