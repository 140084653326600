import React from 'react'

import verificationImg from '@/features/auth/assets/images/verification.png'
import verificationIcon from '@/features/auth/assets/images/verification-big.png'
import { VerificationCheckmarkModal } from '@/features/auth/modals'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  Col,
  FontWeight,
  Row,
  Text,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './VerificationCheckmarkBlock.module.scss'

const VerificationCheckmarkBlock = () => {
  const { visible, closeModal, openModal } = useModal<boolean>(false)

  return (
    <>
      {visible && (
        <VerificationCheckmarkModal
          title="Get verified!"
          img={verificationIcon}
          text="There are two ways to verify your company. Get the verification
          included when you upgrade your subscription, or pay a one time fee of
          $50."
          closeModal={closeModal}
        />
      )}
      <Row className={styles.container} gap={10} onClick={() => openModal()}>
        <img src={verificationImg} width={76} alt="" />

        <Col gap={5}>
          <Text
            type={TextTypes.BODY_DEFAULT}
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral500}
          >
            Verify your company
          </Text>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            Use this Plus feature to show prospective investors your company is
            credible.
          </Text>
        </Col>

        <div className={styles.tooltip}>
          <Tooltip
            kind={TooltipKind.INFO}
            content="The check mark signifies a company exists and is in good standing in the state it was formed. MySAFEnotes can verify your company and place a checkmark next to your name. This way, you can show investors you’re legitimate and your company exists. Pay a one time fee or get it included with the upgraded subscription."
          />
        </div>
      </Row>
    </>
  )
}

export default VerificationCheckmarkBlock
