import { IStep } from '@/packages/ui/stepper/types'

export default [
  {
    id: 'Sign',
    title: 'Sign'
  },
  {
    id: 'invest',
    title: 'Invest'
  }
] as IStep[]
