import clsx from 'clsx'
import { noop } from 'lodash'
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState
} from 'react'
import { NavLink as RouterNavLink } from 'react-router-dom'

import { FontWeight } from '@/packages/ui'

import styles from './Button.module.scss'

export interface IButtonProps {
  type?: 'button' | 'submit' | 'reset'
  element?: 'button' | 'a' | 'routerLink'
  appearance?: 'primary' | 'secondary' | 'ordinary'
  width?: 'default' | 'full' | 'fit'
  fullHeight?: boolean
  disabled?: boolean
  href?: string // used only with element === a or routerLink
  linkTarget?: '_blank' | '_self' | '_parent' | '_top' | ' framename'
  children?: ReactNode
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  loading?: boolean
  uppercase?: boolean
  className?: string
  bold?: boolean
  addonBefore?: ReactNode
  addonAfter?: ReactNode
  textClassName?: string
}

const Button: FC<PropsWithChildren<IButtonProps>> = ({
  type = 'button',
  element = 'button',
  appearance = 'primary',
  width = 'full',
  disabled: btnDisabled = false,
  loading = false,
  href = '#',
  onClick,
  fullHeight,
  className = '',
  addonBefore,
  addonAfter,
  uppercase,
  textClassName,
  bold,
  linkTarget,
  children
}: IButtonProps) => {
  const [disabled, setDisabled] = useState<boolean>(false)

  useEffect(() => {
    setDisabled(btnDisabled || loading)
  }, [btnDisabled, loading])

  const commonStyles = clsx(
    styles[`button-${appearance}`],
    width && styles[`button-w-${width}`],
    disabled && styles.disabled,
    uppercase && styles.uppercase,
    fullHeight && styles.fullHeight
  )

  if (element === 'a' || element === 'routerLink') {
    const _common = {
      className: clsx(
        commonStyles,
        styles.buttonLink,
        bold && FontWeight.BOLD,
        className
      )
    }

    if (element === 'routerLink') {
      return (
        <RouterNavLink {..._common} to={href} onClick={onClick}>
          {children}
        </RouterNavLink>
      )
    }

    return (
      <a
        {..._common}
        href={href}
        target={linkTarget}
        rel="noreferrer"
        onClick={onClick}
      >
        {children}
      </a>
    )
  }

  return (
    <button
      type={type}
      className={clsx(commonStyles, styles.button, className)}
      disabled={disabled}
      onClick={addonAfter ? noop : onClick}
    >
      {!!addonBefore && addonBefore}

      <span
        className={clsx(
          styles.buttonText,
          bold && FontWeight.BOLD,
          textClassName
        )}
        onClick={addonAfter ? onClick : noop}
      >
        {loading ? 'Loading...' : children}
      </span>

      {!!addonAfter && addonAfter}
    </button>
  )
}

export default React.memo(Button)
