import { RoleImages } from '@/features/auth/assets'

export interface IRoleItem {
  id: string
  title: string
  description: string
  icon: string
  info: string
  disabled?: boolean
}

const roles: IRoleItem[] = [
  {
    id: 'entrepreneur',
    info: 'Create, negotiate, and execute SAFEs in exchange for seed funding. From this workspace you can raise seed funding for your startup with SAFE investments. We’re rooting for you.',
    title: 'Entrepreneur',
    description:
      'Select this workspace if you need to fundraise for your startup using SAFEs.',
    icon: RoleImages.Entrepreneur
  },
  {
    id: 'angel',
    info: 'Manage all of your investments in one easy-to-use dashboard. Help your friends, family, and entrepreneurs kickstart their ideas with seed funding. Understand and execute SAFEs with ease, and exchange them for your investments.',
    title: 'Angel',
    description:
      'Select this workspace to provide seed funding to companies you believe in.',
    icon: RoleImages.Angel
  },
  {
    id: 'advisor',
    info: 'Whether you’re an attorney or startup advisor, you can manage client SAFEs to ensure their success. Let the record reflect you’re good at what you do.',
    title: 'Advisor/Attorney',
    description:
      'Select this workspace to offer strategic counsel or manage client SAFEs.',
    icon: RoleImages.Advisor,
    disabled: true
  }
]

export default roles
