import React, { FC, useState } from 'react'
import Sticky from 'react-stickynode'

import { Avatar } from '@/common/components'
import { FormatterHelper } from '@/common/helpers'
import {
  PaymentBridgeChildrenProps,
  PaymentMethod,
  PaymentMethodData,
  PaymentModule,
  PaymentStatus
} from '@/features/payment'
import { IEntrepreneurProfile } from '@/features/profile/api/types'
import { NavigationMenu } from '@/features/safe-note'
import { MenuItemProps } from '@/features/safe-note/components/NavigationMenu/MenuItem'
import { SharedIcons } from '@/packages/icons'
import { SafeMini } from '@/packages/icons/Icons'
import { Color } from '@/packages/palette'
import {
  Col,
  FontWeight,
  IFile,
  Row,
  Text,
  TextTypes,
  Tooltip
} from '@/packages/ui'

import styles from './PaymentOverview.module.scss'

interface PaymentOverviewProps {
  safeAmount?: number
  cashAmount?: number
  onSelectPaymentMethod?: (method: PaymentMethod) => void
  onPay?: (
    data: PaymentBridgeChildrenProps
  ) => (data: PaymentMethodData) => void
  recipientName?: string
  payerName?: string
  withoutCash?: boolean
  safeStatus?: boolean
  status?: PaymentStatus
  onPayLater: () => void
  details?: any
  valuationCap?: number
  discountRate?: number
  platformFee: number
  stripeFee: number
  authorProfile?: IEntrepreneurProfile
  setRecordPaymentFiles?: (files: IFile[] | undefined) => void
}

const PaymentOverview: FC<PaymentOverviewProps> = ({
  cashAmount = 0,
  safeAmount = 0,
  onSelectPaymentMethod,
  recipientName,
  onPay,
  onPayLater,
  safeStatus = true,
  withoutCash,
  status,
  details,
  valuationCap,
  discountRate,
  payerName,
  platformFee,
  stripeFee,
  authorProfile,
  setRecordPaymentFiles
}: PaymentOverviewProps) => {
  const totalAmount = safeAmount + cashAmount

  const [generalMenu] = useState<MenuItemProps>({
    id: 'generalMenuItem',
    title: 'General',
    withoutStatus: true,
    withBackground: true,
    required: false,
    subMenu: [
      {
        id: 'paymentFor',
        title: (
          <Row items="center" gap={10}>
            <SharedIcons.User color={Color.neutral500} />
            <Text type={TextTypes.BODY_DEFAULT}>
              Payment for:{' '}
              <Tooltip
                interactive
                placement="top-start"
                className={styles.tooltip}
                content={
                  <Row items="center" gap={10}>
                    <Avatar
                      size={46}
                      name={recipientName}
                      image={authorProfile?.logo}
                    />
                    <Col>
                      <Text
                        type={TextTypes.BODY_DEFAULT}
                        weight={FontWeight.SEMIBOLD}
                      >
                        {recipientName}
                      </Text>
                      <Text type={TextTypes.BODY_SMALL}>
                        {authorProfile?.name}
                      </Text>
                    </Col>
                  </Row>
                }
              >
                <b className={styles.recipientName}>{recipientName}</b>
              </Tooltip>
            </Text>
          </Row>
        ),
        withoutStatus: true,
        required: false
      },
      {
        id: 'paymentFrom',
        title: (
          <Row items="center" gap={10}>
            <SharedIcons.User color={Color.neutral500} />
            <Text type={TextTypes.BODY_DEFAULT}>
              Payment from: <b>{payerName}</b>
            </Text>
          </Row>
        ),
        withoutStatus: true,
        required: false
      }
    ]
  })

  const [termsMenu] = useState<MenuItemProps>({
    id: 'termsMenuItem',
    title: 'SAFE Terms',
    withoutStatus: true,
    withBackground: true,
    required: false,
    subMenu: [
      {
        id: 'discountRate',
        title: (
          <Row items="center" gap={10}>
            <SafeMini background="black" size={18} color={Color.neutral0} />
            <Text type={TextTypes.BODY_DEFAULT}>
              Discount Rate: <b> {discountRate ? `${discountRate}%` : 'TBD'}</b>
            </Text>
          </Row>
        ),
        withoutStatus: true,
        required: false
      },
      {
        id: 'valuationCap',
        title: (
          <Row items="center" gap={10}>
            <SafeMini size={18} background="black" color={Color.neutral0} />
            <Text type={TextTypes.BODY_DEFAULT}>
              Valuation Cap:{' '}
              <b>
                {valuationCap
                  ? FormatterHelper.toCurrency(valuationCap)
                  : 'TBD'}
              </b>
            </Text>
          </Row>
        ),
        withoutStatus: true,
        required: false
      }
    ]
  })

  const [paymentMenu] = useState<MenuItemProps>({
    id: 'paymentMenuItem',
    title: 'Amount',
    withoutStatus: true,
    withBackground: true,
    required: false,
    subMenu: [
      {
        id: 'safeAmount',
        title: (
          <Text type={TextTypes.BODY_DEFAULT}>
            SAFE Amount: <b>{FormatterHelper.toCurrency(totalAmount)}</b>
          </Text>
        ),
        status: safeStatus,
        required: false
      }
    ]
  })

  // const [generalMenuForInvestor] = useState<MenuItemProps>({
  //   id: 'generalMenuItem',
  //   title: (
  //     <Text type={TextTypes.BODY_DEFAULT}>
  //       SAFE Amount:{' '}
  //       <b className={styles.money}>{FormatterHelper.toCurrency(safeAmount)}</b>
  //     </Text>
  //   ),
  //   status: safeStatus,
  //   withBackground: true,
  //   required: false,
  //   nested: true
  // })

  return (
    <div className={styles.container}>
      <Sticky top={20}>
        <Col gap={20}>
          <div className={styles.navigation}>
            <NavigationMenu menu={[generalMenu]} />
          </div>

          <div className={styles.navigation}>
            <NavigationMenu menu={[paymentMenu]} />
          </div>

          <div className={styles.navigation}>
            <NavigationMenu menu={[termsMenu]} />
          </div>
        </Col>
      </Sticky>

      <div className={styles.payment}>
        <PaymentModule
          details={details}
          cashAmount={withoutCash ? safeAmount : cashAmount}
          platformFee={platformFee}
          stripeFee={stripeFee}
          onPay={onPay}
          onPayLater={onPayLater}
          onSelect={onSelectPaymentMethod}
          status={status}
          setRecordPaymentFiles={setRecordPaymentFiles}
        />
      </div>
    </div>
  )
}

export default PaymentOverview
