import { noop } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'usehooks-ts'

import { ConditionalRender } from '@/common/components'
import { DateHelper } from '@/common/helpers'
import { StoreType, useStore } from '@/common/store'
import {
  LOREM_IPSUM,
  SAFE_NOTE_TEMPLATE_ANCHORS,
  ViewSafeAs
} from '@/features/safe-note'
import { SignatureData, SignatureModal } from '@/features/safe-note/modals'
import { useModal, useSmartState } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  ChangeEvent,
  Col,
  Disabled,
  EditButton,
  FontWeight,
  Heading,
  HeadingTypes,
  Info,
  InfoType,
  Label,
  Row,
  Spacer,
  Text,
  TextInput,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'
import { SignedColumn } from '@/packages/ui/table/columns'

import styles from './SafeNoteSignature.module.scss'

interface SignaturesInfo {
  name?: string
  signDate?: string | Date
  signature?: SignatureData | string
}

export interface SignatureProps {
  tempSignature?: SignatureData
  disableNameChange?: boolean

  senderName?: string
  senderSignDate?: string | Date
  senderSignature?: SignatureData | string

  recipientName?: string
  recipientSignDate?: string | Date
  // because it's dynamic readonly fields and only comes from API and cant be set from app
  recipientSignature?: string

  onAddSignature?: (data: SignatureData | undefined) => void
  // uses when click on button
  onSign?: (data: SignatureData) => void
  viewAs?: ViewSafeAs
  allowSign?: boolean
  showSignButton?: boolean
  emulateSignForNotSignedIn?: boolean
}

const TODAY_DATE = new Date()

const SafeNoteSignature: FC<SignatureProps> = ({
  tempSignature,

  senderName,
  senderSignDate = TODAY_DATE,
  senderSignature,

  recipientName,
  recipientSignDate,
  recipientSignature,

  onAddSignature,
  allowSign = false,
  showSignButton = false,
  onSign,
  viewAs = ViewSafeAs.SENDER,
  emulateSignForNotSignedIn,
  disableNameChange
}) => {
  const { visible, closeModal, openModal } = useModal<boolean>(false)
  const emailIsNotVerified = useStore(
    (store: StoreType) => !store.user.me?.emailVerified
  )

  const [currentSignatureData, setCurrentSignatureData] = useSmartState<
    SignatureData | undefined
  >(undefined)
  const debounceName = useDebounce(currentSignatureData?.name, 500)

  const [yourSignatureInfo, setYourSignatureInfo] = useState<SignaturesInfo>({})
  const [theirSignatureInfo, setTheirSignatureInfo] = useState<SignaturesInfo>(
    {}
  )

  useEffect(() => {
    const _senderSignatureInfo = {
      name: senderName || '',
      signDate: senderSignDate,
      signature:
        typeof senderSignature === 'string'
          ? senderSignature
          : senderSignature?.signature
    }
    const _recipientSignatureInfo = {
      name: recipientName || '',
      signDate: recipientSignDate,
      signature: recipientSignature as string
    }

    // If sign as sender then their signature is recipient signature
    setYourSignatureInfo(
      viewAs === ViewSafeAs.RECIPIENT
        ? _recipientSignatureInfo
        : _senderSignatureInfo
    )

    // If we sign as recipient, then their signature becomes sender signature
    setTheirSignatureInfo(
      viewAs === ViewSafeAs.RECIPIENT
        ? _senderSignatureInfo
        : _recipientSignatureInfo
    )

    if (typeof senderSignature !== 'string') {
      setCurrentSignatureData(senderSignature as SignatureData)
    }

    setCurrentSignatureData({
      name: viewAs === ViewSafeAs.SENDER ? senderName : recipientName
    })
  }, [
    senderName,
    senderSignDate,
    senderSignature,
    recipientName,
    recipientSignDate,
    recipientSignature,
    viewAs
  ])

  const isYourSignaturePresented = useMemo(
    () =>
      Boolean(currentSignatureData?.signature) ||
      Boolean(yourSignatureInfo?.signature),
    [yourSignatureInfo?.signature, currentSignatureData?.signature]
  )
  const isYourSignatureSaved = useMemo(
    () => typeof yourSignatureInfo?.signature === 'string',
    [yourSignatureInfo?.signature]
  )

  const isTheirSignaturePresented = useMemo(
    () =>
      Boolean(theirSignatureInfo?.signature) &&
      typeof theirSignatureInfo?.signature === 'string',
    [theirSignatureInfo?.signature]
  )

  const showSignButtonCondition =
    showSignButton && allowSign && isYourSignaturePresented && !tempSignature

  const showSafeSignedMessage = useMemo(() => {
    const isSignedAsInvestor =
      typeof yourSignatureInfo?.signature === 'string' &&
      viewAs === ViewSafeAs.RECIPIENT

    const bothSignaturePresented =
      !!yourSignatureInfo.signature && !!theirSignatureInfo.signature

    return isSignedAsInvestor || bothSignaturePresented
  }, [yourSignatureInfo, theirSignatureInfo, viewAs])

  const getSignDateValue = useCallback(
    (date?: string | Date) => {
      if (!date) return ''

      return DateHelper.toPrettyFormat(date)
    },
    [currentSignatureData?.signature, senderSignDate]
  )

  const yourSignDate = useMemo(() => {
    if (tempSignature) {
      return getSignDateValue(new Date())
    }

    if (isYourSignatureSaved) {
      return getSignDateValue(yourSignatureInfo?.signDate)
    }

    return currentSignatureData?.signature && getSignDateValue(TODAY_DATE)
  }, [
    isYourSignatureSaved,
    currentSignatureData,
    getSignDateValue,
    tempSignature
  ])

  useEffect(() => {
    if (debounceName && currentSignatureData?.signature) {
      onAddSignature?.(currentSignatureData)
    }
  }, [debounceName])

  const handleChangeName = useCallback((event: ChangeEvent) => {
    setCurrentSignatureData({ name: event.target.value })
  }, [])

  const handleOpenSignatureModal = useCallback(() => {
    if (allowSign) {
      openModal()
    }

    if (emulateSignForNotSignedIn) {
      onAddSignature?.(undefined)
    }

    return noop
  }, [allowSign, emulateSignForNotSignedIn])

  const handleAddSignature = useCallback(
    (data?: SignatureData) => {
      setCurrentSignatureData(data)
      onAddSignature?.(data)
      closeModal()
    },
    [onAddSignature]
  )

  const handleSign = useCallback(() => {
    onSign?.(currentSignatureData!)
  }, [onSign, currentSignatureData])

  const SignaturePlaceholder = (
    <Text
      className={styles.signature}
      type={TextTypes.BODY_SMALL}
      weight={FontWeight.BOLD}
    >
      .
    </Text>
  )

  const clearSignature = () => {
    handleAddSignature(undefined)
  }

  return (
    <div className={styles.container}>
      {visible && allowSign && (
        <SignatureModal
          closeModal={closeModal}
          onAddSignature={handleAddSignature}
          userName={currentSignatureData?.name || senderName}
          {...currentSignatureData}
        />
      )}

      <Row items="center" justify="between">
        <Row items="center" gap={10}>
          <Heading type={HeadingTypes.H2}>Signatures</Heading>
          <Tooltip kind={TooltipKind.INFO} content={LOREM_IPSUM} />
        </Row>
        <ConditionalRender condition={!allowSign}>
          <SignedColumn
            row={{
              senderSignature,
              sender: {
                fullName: senderName
              },
              recipientSignature,
              recipient: {
                fullName: recipientName
              }
            }}
          />
        </ConditionalRender>
      </Row>

      <Spacer size={20} />
      <Col gap={20} className={styles.contentWrapper}>
        <Row gap={20}>
          <TextInput
            label="Your name"
            value={currentSignatureData?.name || tempSignature?.name || ''}
            onChange={handleChangeName}
            disabled={
              isYourSignatureSaved || disableNameChange || !!tempSignature
            }
          />

          <TextInput
            label="Their name"
            value={theirSignatureInfo?.name || ''}
            disabled
            readOnly
          />
        </Row>

        <Row gap={20}>
          <TextInput
            label="Date Signed"
            value={yourSignDate || ''}
            disabled
            readOnly
          />
          <TextInput
            label="Date Signed"
            value={getSignDateValue(theirSignatureInfo?.signDate)}
            disabled
            readOnly
          />
        </Row>

        <Row gap={20}>
          <Col className={styles.signatureCol} gap={20}>
            <Label label="Signature" bold />

            <Row
              items="center"
              justify="between"
              gap={20}
              className={styles.signatureRow}
              id={SAFE_NOTE_TEMPLATE_ANCHORS.MY_SIGNATURE}
              onClick={emailIsNotVerified ? noop : handleOpenSignatureModal}
            >
              <ConditionalRender
                condition={!isYourSignaturePresented && !tempSignature}
                fallbackElement={
                  <>
                    <img
                      className={styles.signature}
                      src={
                        allowSign
                          ? currentSignatureData?.signature ||
                            tempSignature?.signature
                          : yourSignatureInfo.signature ||
                            tempSignature?.signature
                      }
                      alt="your-signature"
                    />

                    <ConditionalRender condition={allowSign && !tempSignature}>
                      <Row items="center" gap={8}>
                        <div
                          className={styles.iconWrapper}
                          onClick={(e) => {
                            e.stopPropagation()
                            clearSignature()
                          }}
                        >
                          <SharedIcons.Trash color={Color.neutral0} size={15} />
                        </div>
                        <EditButton
                          className={styles.editBtn}
                          size="default"
                          withIcon={false}
                        />
                      </Row>
                    </ConditionalRender>
                  </>
                }
              >
                <Disabled disabled={emailIsNotVerified}>
                  <Text
                    type={TextTypes.BODY_SMALL}
                    color={Color.primary400}
                    weight={FontWeight.BOLD}
                    clickable={allowSign || emulateSignForNotSignedIn}
                  >
                    {allowSign || emulateSignForNotSignedIn
                      ? 'Add signature'
                      : '.'}
                  </Text>
                </Disabled>
              </ConditionalRender>
            </Row>
          </Col>

          <Col className={styles.signatureCol} gap={20}>
            <Label label="Their signature" bold />

            <Row
              className={styles.signatureRow}
              id={SAFE_NOTE_TEMPLATE_ANCHORS.THEIR_SIGNATURE}
            >
              <ConditionalRender
                condition={isTheirSignaturePresented}
                fallbackElement={SignaturePlaceholder}
              >
                <img
                  className={styles.signature}
                  src={theirSignatureInfo?.signature as string}
                  alt="their-signature"
                />
              </ConditionalRender>
            </Row>
          </Col>
        </Row>

        <ConditionalRender condition={emailIsNotVerified}>
          <Info type={InfoType.WARNING}>
            Please verify your email to be able to sign SAFE note
          </Info>
        </ConditionalRender>

        <ConditionalRender condition={showSignButtonCondition}>
          <Button width="default" onClick={handleSign}>
            Sign
          </Button>
        </ConditionalRender>
      </Col>

      <ConditionalRender condition={showSafeSignedMessage}>
        <Spacer size={20} />
        <Info type={InfoType.SUCCESS}>The SAFE has been signed</Info>
      </ConditionalRender>

      <ConditionalRender condition={!!tempSignature}>
        <Spacer size={20} />
        <Info type={InfoType.WARNING}>
          Your signature will be saved only after you pay for this SAFE
        </Info>
      </ConditionalRender>
    </div>
  )
}

export default SafeNoteSignature
