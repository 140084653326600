import api, { withParams } from '@/common/api'

import { CreatePaymentIntentDto, IPayment, IPaymentIntent } from './types'

export const PAYMENT_ENDPOINTS = {
  GET_BY_ID: '/payment/:id',
  SAFE_NOTE_PAYMENT_INTENT: '/safe-note/:id/payment-intent',
  DOWNLOAD_RECEIPT: '/payment/:id/receipt'
}

export class PaymentRequests {
  static async createSafeNotePaymentIntent(
    id: string,
    dto: CreatePaymentIntentDto
  ): Promise<IPaymentIntent> {
    return api.post(
      withParams(PAYMENT_ENDPOINTS.SAFE_NOTE_PAYMENT_INTENT, { id }),
      dto
    )
  }

  static async getById(id: string): Promise<IPayment> {
    return api.get(withParams(PAYMENT_ENDPOINTS.GET_BY_ID, { id }))
  }

  static async downloadReceipt(id: string): Promise<Blob> {
    return api.get(withParams(PAYMENT_ENDPOINTS.DOWNLOAD_RECEIPT, { id }), {
      responseType: 'blob'
    })
  }
}
