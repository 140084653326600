import clsx from 'clsx'
import Lottie from 'lottie-react'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { LottieConfetti } from '@/assets/lottie'
import { ConditionalRender } from '@/common/components'
import { getInitialsFromName, toCurrencyFormat } from '@/common/utils'
import { SafeNoteImages } from '@/features/safe-note/assets'
import paymentSuccessImg from '@/features/safe-note/assets/images/PaymentSuccess.svg'
import { Color } from '@/packages/palette'
import {
  Button,
  Heading,
  HeadingTypes,
  Row,
  Spacer,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './SafeNoteSummary.module.scss'

interface SafeNoteSummaryProps {
  safeAmount: number
  safePercent: number
  cashAmount: number
  cashPercent: number
  safeFor: string
}

const SafeNoteSummary: FC<SafeNoteSummaryProps> = ({
  safeAmount,
  cashAmount,
  safePercent = 0,
  cashPercent = 0,
  safeFor
}: SafeNoteSummaryProps) => {
  const navigate = useNavigator()
  const isSafePlusCash = !!(safeAmount && cashAmount)

  const { companyId } = useParams()

  return (
    <div className={styles.wrapper}>
      <Lottie animationData={LottieConfetti} className={styles.leftConfetti} />

      <Lottie animationData={LottieConfetti} className={styles.rightConfetti} />

      <div className={styles.content}>
        <Heading type={HeadingTypes.H2}>
          Your SAFE {isSafePlusCash && '+ cash'} has been sent!
        </Heading>

        <Spacer size={21} />
        <Text
          type={TextTypes.BODY_SMALL}
          align={TextAlign.CENTER}
          color={Color.neutral400}
        >
          Lorem ipsum dolor sit to&nbsp;
          <span className={styles.initials}>
            {getInitialsFromName(safeFor)}
          </span>
          <span className={styles.name}>{safeFor}</span> adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Text>

        <div
          className={clsx(
            styles.valuesHolder,
            !isSafePlusCash && styles.center
          )}
        >
          <ConditionalRender
            condition={isSafePlusCash}
            fallbackElement={
              <Row gap={12} items="center" className={styles.onlySafe}>
                <img src={SafeNoteImages.OnlySafe} alt="safe note" />
                <Text type={TextTypes.BODY_DEFAULT}>SAFE Amount:</Text>
                <Heading color={Color.primary400} type={HeadingTypes.H3}>
                  $ {toCurrencyFormat(safeAmount)}
                </Heading>
              </Row>
            }
          >
            <div
              className={clsx(
                styles.taxCredit,
                !isSafePlusCash && styles.withoutPercentage
              )}
            >
              <div className={styles.price}>
                <Text type={TextTypes.BODY_SMALL}>SAFE Amount</Text>
                <Heading type={HeadingTypes.H2} className="tw-mt-[2px]">
                  $ {toCurrencyFormat(safeAmount)}
                </Heading>
              </div>
              <div className={styles.qualifiedPercent}>{safePercent}%</div>
            </div>
            <div className={styles.taxCredit}>
              <div className={styles.price}>
                <Text type={TextTypes.BODY_SMALL}>Cash Amount</Text>
                <Heading type={HeadingTypes.H2} className="tw-mt-[2px]">
                  $ {toCurrencyFormat(cashAmount)}
                </Heading>
              </div>
              <div className={styles.qualifiedPercent}>{cashPercent}%</div>
            </div>
          </ConditionalRender>
        </div>

        <Spacer size={26} />

        <Row justify="center">
          <Button
            uppercase
            appearance="primary"
            width="default"
            onClick={() => window.location.reload()}
          >
            View SAFE
          </Button>
        </Row>

        <Spacer size={10} />
        <Row justify="center">
          <Button
            appearance="ordinary"
            width="default"
            onClick={() =>
              companyId
                ? navigate.toDashboardCompany(companyId)
                : navigate.toDashboard()
            }
          >
            Go to Dashboard
          </Button>
        </Row>

        <Spacer size={30} />
        <img src={paymentSuccessImg} alt="Payment Success" />
      </div>
    </div>
  )
}

export default SafeNoteSummary
