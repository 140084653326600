import React, { FC, PropsWithChildren, useContext, useMemo } from 'react'

import { useModal, UseModalReturnType } from '@/packages/hooks'

interface ModalContextValue {
  companyInfoModal: UseModalReturnType<boolean>
  loginToSignModal: UseModalReturnType<boolean>
  reminderSentModal: UseModalReturnType<boolean>
  verifyEmailModal: UseModalReturnType<boolean>
  dontHavePermissionModal: UseModalReturnType<boolean>
  fillCompanyInfoModal: UseModalReturnType<boolean>
  logoutModal: UseModalReturnType<boolean>
}

const ModalContext = React.createContext<ModalContextValue>(
  {} as ModalContextValue
)

const ModalContextProvider: FC<PropsWithChildren> = (props) => {
  const companyInfoModal = useModal<boolean>(false)
  const loginToSignModal = useModal<boolean>(false)
  const reminderSentModal = useModal<boolean>(false)
  const verifyEmailModal = useModal<boolean>(false)
  const dontHavePermissionModal = useModal<boolean>(false)
  const fillCompanyInfoModal = useModal<boolean>(false)
  const logoutModal = useModal<boolean>(false)

  const providerValue = useMemo(
    () => ({
      logoutModal,
      companyInfoModal,
      loginToSignModal,
      reminderSentModal,
      verifyEmailModal,
      fillCompanyInfoModal,
      dontHavePermissionModal
    }),
    [
      logoutModal,
      companyInfoModal,
      loginToSignModal,
      reminderSentModal,
      verifyEmailModal,
      fillCompanyInfoModal,
      dontHavePermissionModal
    ]
  )

  return <ModalContext.Provider value={providerValue} {...props} />
}

const useModalContext = () => useContext(ModalContext)

export { ModalContextProvider, useModalContext }
