import clsx from 'clsx'
import React, { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { StaticIcons } from '@/assets/icons'
import { ConditionalRender, UserProfilePopup } from '@/common/components'
import { StoreType, useStore, withStore } from '@/common/store'
import { BurgerMenuIcon } from '@/features/dashboard/assets'
import { ResendEmailModal } from '@/features/dashboard/modals'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Heading,
  HeadingTypes,
  Info,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './HeaderBar.module.scss'
import { Notifications } from './Notifications'

const mapStateToProps = ({ user }: StoreType) => ({
  user: user.me
})

interface IProps extends ReturnType<typeof mapStateToProps> {
  title: string | JSX.Element
  withExit?: boolean
  setCollapsedMenu?: (value: (prev: boolean) => boolean) => void
  onExit?: () => void
}

const HeaderBar: FC<IProps> = (props) => {
  const { title, user, withExit = false, onExit, setCollapsedMenu } = props
  const navigate = useNavigator()
  const location = useLocation()

  const { me, resendVerifyEmail } = useStore((store: StoreType) => store.user)

  const [emailWasResent, setEmailResent] = useState(false)

  const emailVerified = me?.emailVerified

  const {
    visible: showEmailSentModal,
    closeModal,
    openModal
  } = useModal<boolean>(false)

  const handleResend = () => {
    resendVerifyEmail({
      data: null,
      options: {
        onSuccess: () => {
          setEmailResent(true)
          openModal()
        }
      }
    })
  }

  const toggleMenu = () => {
    setCollapsedMenu?.((prev: boolean) => !prev)
  }

  return (
    <header className={styles.header}>
      <ConditionalRender condition={showEmailSentModal}>
        <ResendEmailModal closeModal={closeModal} />
      </ConditionalRender>

      <ConditionalRender
        condition={withExit}
        fallbackElement={
          <Row items="center" gap={12} className="tw-ml-[-14px]">
            <ConditionalRender
              condition={location.pathname.includes('dashboard')}
            >
              <div className={styles.burgerWrapper}>
                <BurgerMenuIcon onClick={toggleMenu} />
              </div>
            </ConditionalRender>
            <StaticIcons.Logo
              className={styles.logo}
              onClick={() => navigate.toDashboard()}
            />
          </Row>
        }
      >
        <Row
          items="center"
          className="tw-cursor-pointer"
          onClick={onExit || navigate.goBack}
        >
          <SharedIcons.Arrow size={35} className={styles.exitIcon} />
          <Text weight={FontWeight.BOLD}>DASHBOARD</Text>
        </Row>
      </ConditionalRender>

      <ConditionalRender condition={!!title}>
        <Heading
          className={clsx(withExit && styles.absoluteCenter)}
          type={HeadingTypes.H2}
        >
          {title}
        </Heading>
      </ConditionalRender>

      <ConditionalRender
        condition={
          !!me?.id && !emailVerified && !location.pathname.includes('safe-note')
        }
      >
        <Info
          rounded
          iconSize={20}
          textType={TextTypes.BODY_SMALL}
          centerContent
          textClassName={styles.infoInner}
          className={styles.infoBlock}
        >
          <div className={styles.message}>
            <b>Verify</b>: Look for the verification email we sent (check your
            spam, too). &nbsp;
          </div>
          <ConditionalRender condition={!emailWasResent}>
            <Row inline items="center" clickable onClick={handleResend}>
              <Text
                color={Color.primary400}
                type={TextTypes.BODY_SMALL}
                weight={FontWeight.SEMIBOLD}
                asSpan
              >
                Resend email
              </Text>
              <SharedIcons.Arrow color={Color.primary400} className="tw-ml-5" />
            </Row>
          </ConditionalRender>
        </Info>
      </ConditionalRender>

      <Row gap={12} items="center">
        <Notifications />
        <UserProfilePopup user={user} />
      </Row>
    </header>
  )
}

export default withStore(mapStateToProps)(HeaderBar)
