import React, { FC, useEffect, useState } from 'react'
import { NumberFormatValues } from 'react-number-format'

import { FormatterHelper } from '@/common/helpers'
import { SafeNoteHelper } from '@/features/auth'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { useSmartState } from '@/packages/hooks'
import {
  Badge,
  Button,
  Heading,
  IModalWithCloseFn,
  Info,
  Modal,
  NumberInput,
  Row,
  Spacer,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './EditValuationCapModal.module.scss'

interface EditValuationCapModalProps extends IModalWithCloseFn {
  safeAllowance?: number
  safeAllocation?: number
  onSelect?: (valuationCap: number | undefined) => void
}

const EditValuationCapModal: FC<EditValuationCapModalProps> = ({
  closeModal,
  safeAllowance,
  safeAllocation,
  onSelect
}: EditValuationCapModalProps) => {
  const [stepIndex, setStepIndex] = useState<number>(0)
  const [state, setState] = useSmartState<any>({
    valuationCap: undefined,
    safeAllowance: undefined,
    safeAllocation: undefined
  })

  useEffect(() => {
    if (safeAllowance && safeAllocation) setStepIndex(1)
    setState({
      safeAllowance,
      safeAllocation
    })
  }, [safeAllowance, safeAllocation])

  useEffect(() => {
    if (!state.safeAllowance || !state.safeAllocation) {
      return
    }

    setState({
      valuationCap: SafeNoteHelper.calculateValuationCap(
        state.safeAllowance,
        state.safeAllocation
      )
    })
  }, [state.safeAllowance, state.safeAllocation])

  const Step1 = (
    <>
      <Row justify="center">
        <img
          className="tw-auto"
          src={SafeNoteImages.ValuationBig}
          alt="valuation-cap-image"
        />
      </Row>
      <Spacer size={30} />

      <Heading align={TextAlign.CENTER}>
        Do you want to edit valuation cap?
      </Heading>

      <Spacer size={20} />
      <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.CENTER}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua, dolor sit amet,
        consectetur adipiscing elit.
      </Text>

      <Spacer size={20} />
      <Info>
        MFN holders need to get get this, this is the better rate you have given
        so far. We will automatically update their SAFEs.
      </Info>
    </>
  )

  const Step2 = (
    <>
      <Row justify="between" className="tw-mb-[5px]">
        <img src={SafeNoteImages.ValuationBig} width={90} alt="discount" />
      </Row>

      <Heading>Edit Valuation CAP</Heading>

      <Spacer size={20} />
      <Text type={TextTypes.BODY_DEFAULT}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua, dolor sit amet,
        consectetur adipiscing elit.
      </Text>

      <Spacer size={20} />
      <Row gap={20}>
        <NumberInput
          prefix="$"
          label="SAFE allowance"
          placeholder="SAFE allowance ($)"
          value={state.safeAllowance}
          onValueChange={(values: NumberFormatValues) =>
            setState({ safeAllowance: values.floatValue })
          }
        />

        <NumberInput
          suffix="%"
          label="SAFE allocation"
          placeholder="SAFE allocation (%)"
          value={state.safeAllocation}
          isAllowed={({ floatValue }: NumberFormatValues) =>
            floatValue ? floatValue <= 100 : true
          }
          onValueChange={(values: NumberFormatValues) => {
            setState({ safeAllocation: values.floatValue })
          }}
        />
      </Row>

      <Spacer size={20} />
      <Row gap={5} items="center">
        <Text type={TextTypes.BODY_DEFAULT}>Total Company Valuation CAP:</Text>
        {state.valuationCap && (
          <Badge>{FormatterHelper.toCurrency(state.valuationCap)}</Badge>
        )}
      </Row>
    </>
  )

  const handleNextOrFinish = () => {
    if (stepIndex === 0) {
      setStepIndex(1)
      return
    }

    onSelect?.(state)
    closeModal?.()
  }

  return (
    <Modal
      id="editDiscountRateModal"
      className={styles.container}
      visible
      setVisible={closeModal}
    >
      {stepIndex === 0 ? Step1 : Step2}

      <Spacer size={40} />
      <Row gap={50} justify="center">
        <Button
          appearance="secondary"
          width="default"
          onClick={closeModal}
          uppercase
        >
          Cancel
        </Button>
        <Button
          width="default"
          disabled={stepIndex === 1 && !state.valuationCap}
          uppercase
          onClick={handleNextOrFinish}
        >
          {stepIndex === 1 ? 'Set new valuation cap' : 'Next'}
        </Button>
      </Row>
    </Modal>
  )
}

export default EditValuationCapModal
