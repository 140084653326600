export enum TextTypes {
  BODY_MAIN = 'body-main',
  BODY_DEFAULT = 'body-default',
  BODY_SMALL = 'body-small',
  BODY_EXTRA_SMALL = 'body-extra-small'
}

export enum HeadingTypes {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3'
}

export enum FontWeight {
  REGULAR = 'font-regular',
  MEDIUM = 'font-medium',
  SEMIBOLD = 'font-semibold',
  BOLD = 'font-bold',
  BLACK = 'font-black'
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify'
}
