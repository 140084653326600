import React, { FC, useState } from 'react'

import { ArrowNavigation, ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { capitalize } from '@/common/utils'
import { permissions } from '@/features/auth/components/EntrepreneurForm/AddTeamMembers/AddTeamMembers'
import { InviteStatus } from '@/features/auth/enums'
import {
  Decline,
  Invitations
} from '@/features/dashboard/modals/InvitationsModal/parts'
import { ICompany, ICompanyTeamMember } from '@/features/profile/types'
import { IModalWithCloseFn, Loader, Modal, Row } from '@/packages/ui'

import styles from './InvitationsModal.module.scss'

const MODAL_ID = 'invitations-modal'

interface InvitationsModalProps extends IModalWithCloseFn {
  companies: ICompany[]
  myId?: string
}

const mapStateToProps = ({ user }: StoreType) => ({
  updateTeamMember: user.updateTeamMember,
  loading:
    user.functionLoading.updateTeamMember ||
    user.functionLoading.refetchCompany,
  removeCompanyFromList: user.removeCompanyFromList,
  refetchCompany: user.refetchCompany
})

const InvitationsModal: FC<
  InvitationsModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    myId,
    companies,
    closeModal,
    loading,
    refetchCompany,
    updateTeamMember,
    removeCompanyFromList
  } = props

  const [activeRequest, setActiveRequest] = useState<number>(0)
  const [step, setStep] = useState<'invites' | 'decline' | 'accept'>('invites')

  const currentRequest = companies?.[activeRequest]

  if (!Array.isArray(companies) || !currentRequest) {
    return null
  }

  const acceptedTeamMembers = currentRequest.teamMembers.filter(
    (teamMember: ICompanyTeamMember) =>
      teamMember.inviteStatus === InviteStatus.Accepted
  )
  const meAsTeamMember = currentRequest.teamMembers.find(
    (teamMember: ICompanyTeamMember) => teamMember.userId === myId
  )
  const workspace = capitalize(currentRequest.type)
  const myPermissionLabel = capitalize(
    permissions.find(
      (permission) => permission.value === meAsTeamMember?.permission
    )?.label || 'Collaborator'
  )

  const handleAccept = async (position: string) => {
    await updateTeamMember({
      data: {
        companyId: currentRequest.id,
        teamMemberId: meAsTeamMember?.userId as string,
        values: { inviteStatus: InviteStatus.Accepted, position }
      }
    })

    if (companies.length > 1) {
      await refetchCompany({ data: { id: currentRequest.id } })
      setActiveRequest(activeRequest - 1)
      setStep('invites')
    } else {
      await refetchCompany({ data: { id: currentRequest.id } })
      closeModal?.()
    }
  }

  const handleDecline = async () => {
    await updateTeamMember({
      data: {
        companyId: currentRequest.id,
        teamMemberId: meAsTeamMember?.userId as string,
        values: { inviteStatus: InviteStatus.Declined }
      }
    })

    if (companies.length > 1) {
      await removeCompanyFromList(currentRequest.id)
      setActiveRequest(0)
      setStep('invites')
    } else {
      await removeCompanyFromList(currentRequest.id)
      closeModal?.()
    }
  }

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" height="300px" />}
      >
        {companies.length > 1 && (
          <Row className={styles.navigation}>
            <ArrowNavigation
              active={activeRequest}
              setActive={setActiveRequest}
              size={companies.length}
            />
          </Row>
        )}

        {step === 'invites' && (
          <Invitations
            onDecline={() => setStep('decline')}
            onAccept={handleAccept}
            workspace={workspace}
            currentRequest={currentRequest}
            permissionLabel={myPermissionLabel}
            acceptedTeamMembers={acceptedTeamMembers}
          />
        )}

        {step === 'decline' && (
          <Decline
            teamMembers={acceptedTeamMembers}
            company={currentRequest}
            onFinish={handleDecline}
            onCancel={() => setStep('invites')}
          />
        )}
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(InvitationsModal)
