import React, { FC } from 'react'

import advancedPlan from '@/features/auth/assets/images/advanced-plan.png'
import oneTimeFee from '@/features/auth/assets/images/one-time-fee.png'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Modal,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './VerificationCheckmarkModal.module.scss'

interface VerificationCheckmarkModalProps {
  closeModal: () => void
  title: string
  text: string
  img: string
}

const VerificationCheckmarkModal: FC<VerificationCheckmarkModalProps> = (
  props
) => {
  const { title, text, img, closeModal } = props

  return (
    <Modal
      id="verification-checkmark-modal"
      size="sm"
      visible
      setVisible={closeModal}
    >
      <Col items="center" className={styles.container}>
        <img
          src={img}
          alt="Verification"
          width={160}
          className={styles.mainImg}
        />
        <Heading
          type={HeadingTypes.H2}
          align={TextAlign.CENTER}
          color={Color.neutral500}
        >
          {title}
        </Heading>
        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral400}
          align={TextAlign.CENTER}
        >
          {text}
        </Text>

        <Col items="stretch" gap={12} className={styles.options}>
          <Row items="center" justify="between" className={styles.option}>
            <Row items="center" gap={12}>
              <img src={advancedPlan} width={76} alt="Plus" />

              <Col>
                <Text
                  type={TextTypes.BODY_MAIN}
                  weight={FontWeight.BOLD}
                  color={Color.neutral500}
                >
                  Plus
                </Text>
                <div className={styles.price}>
                  $9.99 <span>/per month</span>
                </div>
              </Col>
            </Row>

            <Col items="end" gap={15}>
              <SharedIcons.Arrow color={Color.primary500} size={30} />
              <Button uppercase width="fit" className={styles.btn}>
                Upgrade
              </Button>
            </Col>
          </Row>

          <Row items="center" justify="between" className={styles.option}>
            <Row items="center" gap={12}>
              <img src={oneTimeFee} width={76} alt="One-time Fee" />

              <Col>
                <Text
                  type={TextTypes.BODY_MAIN}
                  weight={FontWeight.BOLD}
                  color={Color.neutral500}
                >
                  One-time fee
                </Text>
                <div className={styles.price}>$50</div>
              </Col>
            </Row>

            <SharedIcons.Arrow color={Color.primary500} size={30} />
          </Row>
        </Col>
      </Col>
    </Modal>
  )
}

export default VerificationCheckmarkModal
