import cloneDeep from 'lodash/cloneDeep'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { NumberFormatValues } from 'react-number-format'

import { ConditionalRender } from '@/common/components'
import { FormatterHelper } from '@/common/helpers'
import { SafeNoteHelper } from '@/features/auth'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { useSmartState } from '@/packages/hooks'
import {
  Badge,
  Button,
  FontWeight,
  IModalWithCloseFn,
  Modal,
  NumberInput,
  Row,
  Spacer,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './SetTermsModal.module.scss'

interface SetTermsModalProps extends IModalWithCloseFn {
  safeAllowance?: number
  safeAllocation?: number
  valuationCap?: number
  discountRate?: number
  onSelect?: (values: any) => void
  type: 'discount' | 'valuation'
}

const SetTermsModal: FC<SetTermsModalProps> = ({
  type,
  closeModal,
  onSelect,
  ...props
}: SetTermsModalProps) => {
  const [state, setState] = useSmartState<any>({
    safeAllowance: undefined,
    safeAllocation: undefined,
    valuationCap: undefined,
    discountRate: undefined
  })

  useEffect(() => {
    setState({
      ...props
    })
  }, [
    props.valuationCap,
    props.discountRate,
    props.safeAllocation,
    props.safeAllowance
  ])

  useEffect(() => {
    if (!state.safeAllowance || !state.safeAllocation) {
      return
    }

    setState({
      ...state,
      valuationCap: SafeNoteHelper.calculateValuationCap(
        state.safeAllowance,
        state.safeAllocation
      )
    })
  }, [state.safeAllowance, state.safeAllocation])

  const handleSetTerms = useCallback(() => {
    const { discountRate, valuationCap, safeAllocation, safeAllowance } =
      cloneDeep(state)

    if (type === 'discount') {
      onSelect?.({
        discountRate,
        discountRateActive: true
      })
    } else {
      onSelect?.({
        valuationCap,
        safeAllowance,
        safeAllocation,
        valuationCapActive: true
      })
    }

    closeModal?.()
  }, [state])

  const DiscountRateForm = (
    <>
      <NumberInput
        suffix="%"
        autoFocus
        decimalScale={3}
        label="Discount Rate"
        placeholder="Discount Rate"
        value={state.discountRate}
        isAllowed={({ floatValue }: NumberFormatValues) =>
          floatValue ? floatValue <= 99 : true
        }
        onValueChange={(values: NumberFormatValues) =>
          setState({ discountRate: values.floatValue })
        }
      />
      <Spacer size={10} />

      <Row gap={5} items="center">
        <Text type={TextTypes.BODY_DEFAULT}>Discount Rate:</Text>
        <Badge size="large">{state.discountRate || 0}%</Badge>
      </Row>
    </>
  )

  const ValuationCapForm = (
    <>
      <NumberInput
        autoFocus
        label="SAFE allowance"
        placeholder="SAFE allowance ($)"
        prefix="$"
        value={state.safeAllowance}
        onValueChange={(values: NumberFormatValues) =>
          setState({ safeAllowance: values.floatValue })
        }
      />
      <Spacer size={20} />
      <NumberInput
        label="SAFE allocation"
        placeholder="SAFE allocation (%)"
        suffix="%"
        value={state.safeAllocation}
        isAllowed={({ floatValue }: NumberFormatValues) =>
          floatValue ? floatValue <= 100 : true
        }
        onValueChange={(values: NumberFormatValues) =>
          setState({ safeAllocation: values.floatValue })
        }
      />

      <Spacer size={10} />
      <Row gap={5} items="center">
        <Text type={TextTypes.BODY_DEFAULT}>Total Company Valuation CAP:</Text>
        {state.valuationCap && (
          <Badge size="large">
            {FormatterHelper.toCurrency(state.valuationCap)}
          </Badge>
        )}
      </Row>
    </>
  )

  const disabledButtonCondition = useMemo(
    () =>
      (type === 'valuation' && !state.valuationCap) ||
      (type === 'discount' && !state.discountRate),
    [state]
  )

  return (
    <Modal id="setTermsModal" visible setVisible={closeModal} size="sm">
      <ConditionalRender condition={type === 'discount'}>
        <div className={styles.termsOption}>
          <Row justify="between">
            <img src={SafeNoteImages.DiscountBig} alt="discount" />
          </Row>

          <Spacer size={20} />
          <Text type={TextTypes.BODY_MAIN} weight={FontWeight.SEMIBOLD}>
            Discount Rate
          </Text>
          <Spacer size={10} />
          <Text type={TextTypes.BODY_DEFAULT}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore Learn more
          </Text>

          <Spacer size={20} />
          {DiscountRateForm}
        </div>
      </ConditionalRender>

      <ConditionalRender condition={type === 'valuation'}>
        <div className={styles.termsOption}>
          <Row justify="between">
            <img src={SafeNoteImages.ValuationBig} alt="discount" />
          </Row>

          <Spacer size={20} />
          <Text type={TextTypes.BODY_MAIN} weight={FontWeight.SEMIBOLD}>
            Valuation Cap
          </Text>
          <Spacer size={10} />
          <Text type={TextTypes.BODY_DEFAULT}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore Learn more
          </Text>

          <Spacer size={20} />
          {ValuationCapForm}
        </div>
      </ConditionalRender>

      <Spacer size={40} />
      <Row gap={50} justify="center">
        <Button
          appearance="secondary"
          width="default"
          onClick={closeModal}
          uppercase
        >
          Cancel
        </Button>
        <Button
          width="default"
          onClick={handleSetTerms}
          uppercase
          disabled={disabledButtonCondition}
        >
          Set terms
        </Button>
      </Row>
    </Modal>
  )
}

export default SetTermsModal
