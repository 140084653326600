import clsx from 'clsx'
import React, { FC } from 'react'

import { Color } from '@/packages/palette'
import { FontWeight, Text } from '@/packages/ui'

import styles from './SafeForBubble.module.scss'

interface RecipientBubbleProps {
  name: string
  center?: boolean
  className?: string
}

const SafeForBubble: FC<RecipientBubbleProps> = ({
  name,
  center,
  className
}: RecipientBubbleProps) => (
  <div className={clsx(styles.container, center && styles.center, className)}>
    <Text>
      Creating <b>SAFE</b> note for:&nbsp;
      <Text asSpan weight={FontWeight.BOLD} color={Color.primary400}>
        {name}
      </Text>
    </Text>
  </div>
)

export default SafeForBubble
