import clsx from 'clsx'
import React, { FC, MouseEvent, useMemo } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { useStore } from '@/common/store'
import { InviteIcon } from '@/features/dashboard/assets'
import { AddTeamMembersModal } from '@/features/dashboard/modals'
import { ICompanyTeamMember, IOwner } from '@/features/profile/types'
import { TeamMemberPermission } from '@/features/user'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from './DashboardTeamMembers.module.scss'

interface DashboardTeamMembersProps {
  teamMembers: ICompanyTeamMember[]
  owner?: IOwner
  companyId: string
  companyName: string
  hideText?: boolean
  whiteBorder?: boolean
  minimizedInviteButton?: boolean
}

const DashboardTeamMembers: FC<DashboardTeamMembersProps> = (props) => {
  const {
    owner,
    whiteBorder,
    hideText,
    companyId,
    teamMembers,
    companyName,
    minimizedInviteButton
  } = props

  const myId = useStore((store) => store.user.me?.id)

  const teamMembersNumber = teamMembers.length
  const extraTeamMembers = teamMembersNumber > 2 ? teamMembersNumber - 2 : 0

  const creator = useMemo(
    () =>
      myId === owner?.id ||
      teamMembers.find((member: ICompanyTeamMember) => member.userId === myId)
        ?.permission === TeamMemberPermission.CREATE,
    [myId, owner?.id, teamMembers]
  )

  const { visible, closeModal, openModal } = useModal<boolean>(false)

  const handleInviteClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    openModal()
  }

  const InviteButton = (
    <Row
      items="center"
      gap={4}
      onClick={handleInviteClick}
      className={styles.invite}
    >
      <InviteIcon />
      <Text
        weight={FontWeight.SEMIBOLD}
        type={TextTypes.BODY_SMALL}
        color={Color.primary500}
      >
        Invite
      </Text>
    </Row>
  )

  const MinimizedInviteButton = (
    <div
      className={clsx(styles.member, styles.add)}
      onClick={handleInviteClick}
    >
      <SharedIcons.PlusThin color={Color.primary500} size={17} />
    </div>
  )

  const ExtraNumber = (
    <div className={clsx(styles.member, styles.extraItem)}>
      {extraTeamMembers}
      <SharedIcons.Plus color={Color.neutral400} size={7} />
    </div>
  )

  return (
    <Row items="center" gap={16}>
      {visible && (
        <AddTeamMembersModal
          owner={owner}
          companyId={companyId}
          teamMembers={teamMembers}
          companyName={companyName}
          closeModal={closeModal}
        />
      )}
      <Row items="center" gap={8}>
        <ConditionalRender condition={!hideText}>
          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral400}
          >
            Your team:
          </Text>
        </ConditionalRender>

        <div className={styles.teamMembers}>
          <ConditionalRender condition={!!owner}>
            <Avatar
              size={32}
              name={owner?.fullName}
              image={owner?.image}
              className={clsx(styles.member, whiteBorder && styles.whiteBorder)}
            />
          </ConditionalRender>

          {teamMembers.slice(0, 2).map((teamMember: ICompanyTeamMember) => (
            <Avatar
              key={teamMember.id}
              size={32}
              name={teamMember.user.fullName}
              image={teamMember.user.image}
              className={clsx(styles.member, whiteBorder && styles.whiteBorder)}
            />
          ))}

          <ConditionalRender condition={!!extraTeamMembers}>
            {ExtraNumber}
          </ConditionalRender>

          <ConditionalRender condition={creator}>
            {minimizedInviteButton ? MinimizedInviteButton : InviteButton}
          </ConditionalRender>
        </div>
      </Row>
    </Row>
  )
}

export default DashboardTeamMembers
