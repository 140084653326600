class SafeNoteHelper {
  static calculateValuationCap(
    allowance: number,
    allocation: number
  ): number | null {
    if (!allocation && !allocation) return null

    const percentageFromAllowance = (allowance / 100) * allocation
    return Math.floor(allowance * (allowance / percentageFromAllowance))
  }
}

export default SafeNoteHelper
