import { action, makeObservable, observable, runInAction } from 'mobx'
import { clearPersistedStore, makePersistable } from 'mobx-persist-store'

import { getErrorMessage } from '@/common/api'
import { IRequestError } from '@/common/api/types'
import { ToastService } from '@/common/services'
import { RootStoreType } from '@/common/store/store'
import StoreHandler from '@/common/store/StoreHandler'
import { TFailedFields } from '@/common/types'
import { AuthService } from '@/features/auth'
import { AuthRequests } from '@/features/auth/api/AuthRequests'
import {
  AuthData,
  AuthResponse,
  LoginDto,
  SignUpDto,
  ValidateTokenResponse
} from '@/features/auth/api/types'
import { AuthType } from '@/features/auth/enums'

class AuthStore extends StoreHandler {
  @observable failedFields: TFailedFields = {}

  @observable isLoggedIn = false

  constructor(rootStore: RootStoreType) {
    super(rootStore)
    makeObservable(this)
    makePersistable(this, {
      name: 'AuthStore',
      properties: ['isLoggedIn'],
      storage: window.sessionStorage
    })
  }

  @action
  public clearErrors = () => {
    this.failedFields = {}
  }

  @action
  private auth = this.execute<AuthData>(
    async ({ data, options }) => {
      try {
        const isLogin = data.type === AuthType.Login

        const response: AuthResponse = isLogin
          ? await AuthRequests.login(data)
          : await AuthRequests.signUp(data as SignUpDto)

        if (response) {
          AuthService.saveAuthToken(response.accessToken)
          await this.rootStore.user.fetchMe()

          runInAction(() => {
            this.isLoggedIn = true
            options?.onSuccess?.()
          })
        }
      } catch (error) {
        const err = error as IRequestError
        if (
          'message' in err &&
          typeof err.message === 'object' &&
          err.message !== null
        ) {
          this.failedFields = (err.message || {}) as TFailedFields
          return
        }
        throw new Error(getErrorMessage(err))
      }
    },
    {
      loaderName: 'auth',
      manualOnSuccess: true
    }
  )

  @action
  public signUp = this.execute<SignUpDto>(
    async ({ data, options }) =>
      this.auth({
        data: { type: AuthType.SignUp, ...data },
        options
      }),
    {
      loaderName: 'signUp',
      manualOnSuccess: true
    }
  )

  @action
  public login = this.execute<LoginDto>(
    async ({ data, options }) =>
      this.auth({
        data: { type: AuthType.Login, ...data },
        options
      }),

    {
      loaderName: 'login',
      manualOnSuccess: true
    }
  )

  @action
  public oAuthLogin = this.execute<{ token: string }>(
    async ({ data: { token }, options }) => {
      try {
        const validateTokenResponse: ValidateTokenResponse =
          await AuthRequests.validateUserToken(token)

        if (validateTokenResponse.valid) {
          AuthService.saveAuthToken(token)

          await this.rootStore.user.fetchMe()

          runInAction(() => {
            this.isLoggedIn = true
          })
        }

        options?.onSuccess?.(validateTokenResponse.valid)
      } catch (err) {
        ToastService.showWarning('Cannot login using social')
      }
    },
    {
      loaderName: 'oAuthLogin',
      manualOnSuccess: true
    }
  )

  @action
  public autoLogin = this.execute<{ accessToken: string | null }>(
    async ({ data: { accessToken }, options }) => {
      const token = accessToken ?? AuthService.getAuthToken()

      if (!token) {
        this.isLoggedIn = false
        await clearPersistedStore(this)
      }

      try {
        const validateTokenResponse: ValidateTokenResponse =
          await AuthRequests.validateUserToken(token as string)

        const isLoggedIn = validateTokenResponse.valid

        runInAction(() => {
          this.isLoggedIn = isLoggedIn

          if (accessToken) {
            AuthService.saveAuthToken(accessToken)
          }

          if (isLoggedIn) {
            this.rootStore.user.fetchMe().then(options?.onSuccess)
          }
        })
      } catch (err) {
        this.isLoggedIn = false
        await clearPersistedStore(this)
      }
    },
    {
      loaderName: 'autoLogin',
      manualOnSuccess: true
    }
  )

  @action
  public logout = this.execute(async () => {
    AuthService.removeAuthToken()

    runInAction(() => {
      this.isLoggedIn = false
      this.rootStore.user.clearUser()
    })

    await clearPersistedStore(this)
  }, 'logout')

  @action
  public validateToken = this.execute<{ token: string }, ValidateTokenResponse>(
    async ({ data }) => AuthRequests.validateToken(data.token),
    'validateToken'
  )
}

export default AuthStore
