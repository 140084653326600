import React, { FC, useMemo } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { Terms } from '@/features/dashboard/modals/IncomingSafesModal/parts'
import { ICompany } from '@/features/profile/types'
import { IUser } from '@/features/user'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Select,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from '../IncomingSafesModal.module.scss'

interface SafesListProps {
  amount: number
  discount?: number
  valuation?: number
  sender: IUser
  onDecline: () => void
  onAccept: (id: string) => void
  toCreateCompany: () => void
  availableCompanies: ICompany[]
}

const CompanyOption: FC<any> = ({ value, label, image }) => (
  <div>
    <Row items="center" gap={8}>
      <ConditionalRender
        condition={value === 'addCompany'}
        fallbackElement={<Avatar size={30} image={image} name={label} />}
      >
        <SharedIcons.PlusThin size={23} color={Color.primary500} />
      </ConditionalRender>
      <Text
        color={Color.neutral500}
        type={TextTypes.BODY_SMALL}
        weight={FontWeight.SEMIBOLD}
      >
        {label}
      </Text>
    </Row>
  </div>
)

const SafesList: FC<SafesListProps> = (props) => {
  const {
    amount,
    valuation,
    discount,
    sender,
    onDecline,
    onAccept,
    availableCompanies = [],
    toCreateCompany
  } = props

  const [selectedCompany, setSelectedCompany] = React.useState<string>()

  const options = useMemo(() => {
    const list = availableCompanies.map((company: ICompany) => ({
      value: company.id,
      label: company.name,
      image: company.image
    }))

    list.push({
      value: 'addCompany',
      label: 'Create a Company',
      image: ''
    })

    return list
  }, [availableCompanies])

  return (
    <Col items="stretch">
      <div className={styles.safeInfo}>
        <Avatar
          size={80}
          image={sender.image}
          name={sender.fullName}
          className={styles.avatar}
        />

        <Heading
          type={HeadingTypes.H2}
          align={TextAlign.CENTER}
          color={Color.neutral500}
        >
          {sender.fullName} sent you a SAFE request:
        </Heading>

        <Terms amount={amount} discount={discount} valuation={valuation} />
      </div>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        className="tw-mt-[16px]"
      >
        Сhoose in which investment company you want to display SAFE
      </Text>

      <Select
        custom
        withPortal
        CustomOption={CompanyOption}
        className="tw-mt-[10px]"
        placeholder="Write or select the company"
        name="companies"
        options={options}
        value={selectedCompany}
        onChange={(val: string) =>
          val === 'addCompany' ? toCreateCompany() : setSelectedCompany(val)
        }
      />

      <div className={styles.footer}>
        <Button
          uppercase
          appearance="secondary"
          width="full"
          onClick={onDecline}
        >
          Decline
        </Button>
        <Button
          disabled={!selectedCompany}
          uppercase
          width="full"
          onClick={() => onAccept(selectedCompany as string)}
        >
          Accept
        </Button>
      </div>
    </Col>
  )
}

export default SafesList
