import React, { FC } from 'react'

import warningImg from '@/features/dashboard/modals/InvitationsModal/images/decline-request.png'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './LogoutModal.module.scss'

const MODAL_ID = 'logout-modal'

const LogoutModal: FC<IModalWithCloseFn> = (props) => {
  const { closeModal } = props

  const navigate = useNavigator()

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <Col items="center" gap={24}>
        <img width={150} src={warningImg} alt="Logout" />

        <Col items="center" gap={5}>
          <Heading
            type={HeadingTypes.H2}
            align={TextAlign.CENTER}
            color={Color.neutral500}
          >
            Are you sure you want to logout?
          </Heading>

          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral400}
            align={TextAlign.CENTER}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing
          </Text>
        </Col>

        <div className={styles.footer}>
          <Button appearance="secondary" width="full" onClick={closeModal}>
            No, I don`t want
          </Button>
          <Button
            width="full"
            onClick={() => {
              closeModal?.()
              navigate.toLogout()
            }}
          >
            Yes, I want to logout
          </Button>
        </div>
      </Col>
    </Modal>
  )
}

export default LogoutModal
