import React from 'react'
import { ToastContainer as ToastifyContainer } from 'react-toastify'
// import { Icons } from '@/packages/icons'
//
// const ToastCloseButton = () => (
//   <Icons.Cross
//     className="Toastify__toast-close-icon"
//     size={20}
//     color={TEXT_COLORS.neutral900}
//   />
// )

const ToastContainer = () => (
  <ToastifyContainer />
)

export default ToastContainer
