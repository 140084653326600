export const SAFE_NOTE_TEMPLATE_ID = 'safe-note-template-id'

export const SAFE_NOTE_TEMPLATE_ANCHORS = {
  COMPANY: 'safe-company-anchor',
  INVESTOR: 'safe-recipient-anchor',
  AMOUNT: 'safe-amount-anchor',
  DISCOUNT_RATE: 'safe-discount-rate-anchor',
  VALUATION_CAP: 'safe-valuation-cap-anchor',
  MY_SIGNATURE: 'safe-my-signature-anchor',
  THEIR_SIGNATURE: 'safe-their-signature-anchor'
}

export const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'

export const PLATFORM_FEE = 4
export const PROCESSING_FEE = 4
