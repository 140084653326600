import clsx from 'clsx'
import React, { FC, useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'

import { ConditionalRender } from '@/common/components'
import { FormatterHelper } from '@/common/helpers'
import { AccountHolderType, BankTransferData } from '@/features/payment'
import { PaymentImages } from '@/features/payment/assets/images'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  CardExpiryInput,
  FileSelect,
  FontWeight,
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  IFile,
  MaskInput,
  NumberInput,
  Radio,
  Row,
  Select,
  Spacer,
  Text,
  TextInput,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './PaymentOptions.module.scss'
import { BankPaymentSchema, CardPaymentSchema } from './schemas'
import { CardPaymentData, PaymentMethod, PaymentMethodData } from './types'

interface PaymentMethodProps {
  title: string
  withDescription?: boolean
  withRecordPayment?: boolean
  onPayLater?: () => void
  onPay?: (data: PaymentMethodData) => void
  cashAmount?: number
  className?: string
  onSelect?: (method: PaymentMethod) => void
  setRecordPaymentFiles?: (files: IFile[] | undefined) => void
}

const accountHolderTypeOptions = [
  {
    value: AccountHolderType.INDIVIDUAL,
    label: 'Individual'
  },
  {
    value: AccountHolderType.COMPANY,
    label: 'Company'
  }
]

const PaymentOptions: FC<PaymentMethodProps> = ({
  title,
  withDescription,
  withRecordPayment,
  onPayLater,
  onPay,
  className,
  cashAmount,
  onSelect,
  setRecordPaymentFiles
}) => {
  const {
    control,
    register,
    watch,
    getValues,
    reset,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    resolver: async (data: any) => {
      try {
        const schema =
          data.payWith === 'card' ? CardPaymentSchema : BankPaymentSchema

        const values = await schema.validate(data, { abortEarly: false })

        return {
          values,
          errors: {}
        } as any
      } catch (caughtErrors: any) {
        return {
          values: {},
          errors: FormHelper.formatYupErrors(caughtErrors)
        }
      }
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      payWith: 'card',
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      accountNumber: '',
      routingNumber: '',
      accountHolderType: '',
      billingDetails: ''
    }
  })

  const payWithCardSelected = watch('payWith') === PaymentMethod.CARD
  const payWithBankSelected = watch('payWith') === PaymentMethod.BANK_TRANSFER
  const payRecordSelected = watch('payWith') === PaymentMethod.RECORD_PAYMENT

  useEffect(() => {
    reset(getValues())
    setRecordPaymentFiles?.(undefined)
    onSelect?.(watch('payWith') as PaymentMethod)
  }, [watch('payWith')])

  const handlePay = useCallback(() => {
    if (payWithCardSelected) {
      const { cvv, cardNumber, expirationDate } = getValues()
      onPay?.({
        method: PaymentMethod.CARD,
        cvv,
        cardNumber: String(cardNumber),
        expirationDate
      } as CardPaymentData)
    }

    if (payWithBankSelected) {
      const {
        accountNumber,
        routingNumber,
        accountHolderType,
        billingDetails
      } = getValues()
      onPay?.({
        method: PaymentMethod.BANK_TRANSFER,
        accountHolderType,
        routingNumber,
        accountNumber,
        billingDetails: {
          name: billingDetails
        }
      } as BankTransferData)
    }
  }, [onPay, payWithCardSelected])

  const PayWithCardContent = (
    <>
      <FormItem errors={errors?.cardNumber?.message as string}>
        <Controller
          control={control}
          name="cardNumber"
          render={({ field: { onChange, value } }) => (
            <MaskInput
              value={value}
              label="Card Number"
              placeholder="0000 0000 0000 0000"
              format="#### #### #### ####"
              onValueChange={(val: NumberFormatValues) => {
                onChange(val.floatValue)
              }}
              invalid={FormHelper.isFieldInvalid('cardNumber', errors)}
            />
          )}
        />
      </FormItem>
      <Spacer size={12} />

      <Row>
        <FormItem errors={errors?.expirationDate?.message as string}>
          <Controller
            control={control}
            name="expirationDate"
            render={({ field: { onChange, value } }) => (
              <CardExpiryInput
                value={value}
                label="Expiration Date"
                placeholder="MM/YY"
                onValueChange={(val: NumberFormatValues) => {
                  onChange(val.formattedValue)
                }}
                invalid={FormHelper.isFieldInvalid('expirationDate', errors)}
              />
            )}
          />
        </FormItem>
        <Spacer size={10} vertical />

        <FormItem errors={errors?.cvv?.message as string}>
          <Controller
            control={control}
            name="cvv"
            render={({ field: { onChange, value } }) => (
              <NumberInput
                label="CVV"
                placeholder="123"
                maxLength={4}
                thousandSeparator=""
                invalid={FormHelper.isFieldInvalid('cvv', errors)}
                value={value}
                onValueChange={(val: NumberFormatValues) => {
                  onChange(val.floatValue)
                }}
              />
            )}
          />
        </FormItem>
      </Row>
    </>
  )

  const RecordPayment = (
    <>
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.neutral400}
        className="tw-flex tw-items-center"
      >
        <SharedIcons.Checkmark size={16} />
        <Spacer size={6} vertical />
        Record payment:
        <Spacer size={6} vertical />
        <span className={styles.recordPaymentPrice}>
          {FormatterHelper.toCurrency(cashAmount)}
        </span>
      </Text>

      <Spacer size={10} />
      <FileSelect
        label="Upload receipt (optionally)"
        caption="Drop the document, or select a file."
        appearance="compact"
        hideSpotIfFilePresented
        initialFiles={[]}
        onSelectFiles={setRecordPaymentFiles}
      />
    </>
  )

  const PayWithBankContent = (
    <>
      <FormItem errors={errors?.accountNumber?.message as string}>
        <Controller
          control={control}
          name="accountNumber"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              label="Account number"
              placeholder="Account number"
              decimalScale={0}
              thousandSeparator={false}
              onChange={onChange}
              value={value}
              invalid={FormHelper.isFieldInvalid('accountNumber', errors)}
            />
          )}
        />
      </FormItem>
      <Spacer size={12} />

      <FormItem errors={errors?.routingNumber?.message as string}>
        <Controller
          control={control}
          name="routingNumber"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              label="Routing number"
              placeholder="Routing number"
              decimalScale={0}
              thousandSeparator={false}
              onChange={onChange}
              value={value}
              invalid={FormHelper.isFieldInvalid('routingNumber', errors)}
            />
          )}
        />
      </FormItem>
      <Spacer size={12} />

      <Row>
        <FormItem errors={errors?.accountHolderType?.message as string}>
          <Controller
            control={control}
            name="accountHolderType"
            render={({ field: { onChange, value } }) => (
              <Select
                name="accountHolder"
                value={value}
                label="Account holder"
                onChange={onChange}
                options={accountHolderTypeOptions}
                placeholder="Individual/Company"
              />
            )}
          />
        </FormItem>
        <Spacer size={12} vertical />

        <FormItem errors={errors?.cvv?.message as string}>
          <TextInput
            {...register('billingDetails')}
            label="Account holder name"
            placeholder="Account holder name"
          />
        </FormItem>
      </Row>
    </>
  )

  return (
    <div className={clsx(styles.container, className)}>
      <Heading type={HeadingTypes.H3} className="tw-mb-[12px]">
        {title}
      </Heading>
      <ConditionalRender
        condition={withDescription}
        fallbackElement={<Spacer size={10} />}
      >
        <Text type={TextTypes.BODY_SMALL} className="tw-mb-[24px]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempo
        </Text>
      </ConditionalRender>
      <div>
        <Form>
          <div
            className={clsx(
              styles.paymentMethod,
              payWithCardSelected && styles.active
            )}
            onClick={() => setValue('payWith', PaymentMethod.CARD)}
          >
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Row justify="between" items="center">
                  <Radio
                    small
                    alwaysBold
                    value="card"
                    name="payWith"
                    label="Card"
                    checked={value === PaymentMethod.CARD}
                    onChange={() => onChange(PaymentMethod.CARD)}
                  />
                  <img src={PaymentImages.PaymentMethods} alt="Methods" />
                </Row>
              )}
              name="payWith"
            />
            <Text
              type={TextTypes.BODY_SMALL}
              color={Color.neutral400}
              className="tw-pl-[25px]"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod
            </Text>
            <ConditionalRender condition={payWithCardSelected}>
              <Spacer size={15} />
              {PayWithCardContent}
              <Spacer size={10} />
            </ConditionalRender>
          </div>

          <div
            className={clsx(
              styles.paymentMethod,
              payWithBankSelected && styles.active
            )}
            onClick={() => setValue('payWith', PaymentMethod.BANK_TRANSFER)}
          >
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Radio
                  small
                  alwaysBold
                  value="bank"
                  name="payWith"
                  label="Bank transfer"
                  checked={value === PaymentMethod.BANK_TRANSFER}
                  onChange={() => onChange(PaymentMethod.BANK_TRANSFER)}
                />
              )}
              name="payWith"
            />
            <Text
              type={TextTypes.BODY_SMALL}
              color={Color.neutral400}
              className="tw-pl-[25px]"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod
            </Text>
            <ConditionalRender condition={payWithBankSelected}>
              <Spacer size={15} />
              {PayWithBankContent}
              <Spacer size={10} />
            </ConditionalRender>
          </div>

          <ConditionalRender condition={withRecordPayment}>
            <div
              className={clsx(
                styles.paymentMethod,
                payRecordSelected && styles.active
              )}
              onClick={() => setValue('payWith', PaymentMethod.RECORD_PAYMENT)}
            >
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Row items="center">
                    <Radio
                      small
                      alwaysBold
                      value="record"
                      name="payWith"
                      label="Record Payment"
                      checked={value === PaymentMethod.RECORD_PAYMENT}
                      onChange={() => onChange(PaymentMethod.RECORD_PAYMENT)}
                    />
                    <Tooltip
                      kind={TooltipKind.INFO}
                      content="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda blanditiis dolores earum et eum explicabo inventore magni modi, officia vel."
                    />
                  </Row>
                )}
                name="payWith"
              />
              <Text
                type={TextTypes.BODY_SMALL}
                color={Color.neutral400}
                className="tw-pl-[25px]"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod
              </Text>
              <ConditionalRender condition={payRecordSelected}>
                <Spacer size={15} />
                {RecordPayment}
                <Spacer size={10} />
              </ConditionalRender>
            </div>
          </ConditionalRender>
        </Form>

        <Spacer size={14} />
        <Row items="center" gap={8}>
          <img src={PaymentImages.Security} alt="" />

          <Text type={TextTypes.BODY_EXTRA_SMALL} weight={FontWeight.MEDIUM}>
            We protect your payment information using encryption to provide
            bank-level security.
          </Text>
        </Row>
      </div>

      <ConditionalRender condition={payWithCardSelected || payWithBankSelected}>
        <Spacer size={40} />
        <Row gap={50} justify="between">
          <Button
            appearance="secondary"
            width="default"
            uppercase
            onClick={onPayLater}
          >
            Pay later
          </Button>
          <Button
            width="default"
            uppercase
            onClick={handlePay}
            disabled={!isValid}
          >
            Pay now
          </Button>
        </Row>
      </ConditionalRender>
    </div>
  )
}

export default PaymentOptions
