import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { UploadImage } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import {
  ChangePasswordSchema,
  EditUserSchema
} from '@/features/auth/schemas/AuthSchema'
import { Color } from '@/packages/palette'
import {
  Button,
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  IFile,
  PasswordInput,
  Row,
  Text,
  TextInput,
  TextTypes
} from '@/packages/ui'

const mapStateToProps = ({ user }: StoreType) => ({
  me: user.me
})

const ProfileSettingsView: FC<ReturnType<typeof mapStateToProps>> = (props) => {
  const { me } = props

  const [photo, setPhoto] = useState<IFile | undefined>(undefined)

  const {
    setValue,
    register,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(EditUserSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const {
    register: registerPassword,
    formState: { errors: passwordErrors, isValid: passwordIsValid }
  } = useForm({
    resolver: yupResolver(ChangePasswordSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const makeUrlAsFile = async () => {
    const response = await fetch(me?.image as string)
    const data = await response.blob()
    const metadata = {
      type: 'image/png'
    }

    const filename = me?.image.split('/').pop() || 'user-image.png'

    const file = new File([data], filename, metadata)

    setPhoto(file as IFile)
  }

  useEffect(() => {
    if (me?.image) {
      makeUrlAsFile()
    } else {
      setPhoto(undefined)
    }

    if (me?.id) {
      setValue('fullName', me?.fullName)
      setValue('email', me?.email)
    }
  }, [me])

  return (
    <div className="tw-mb-50">
      <Heading type={HeadingTypes.H2} color={Color.neutral500}>
        My profile
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        className="tw-mt-8"
      >
        This is the place to manage your profile. Change your password or update
        your email all right here.
      </Text>

      <div className={clsx('tw-mt-32', !photo && 'tw-mb-20')}>
        <UploadImage
          description="Pick a photo to showcase on your profile. You can always change this later in settings. "
          image={photo}
          label="Upload profile photo"
          caption="Upload photo (PNG or JPG)"
          onImageSelect={setPhoto}
        />
      </div>

      <Form>
        <Row items="center" gap={24}>
          <FormItem errors={errors.fullName?.message}>
            <TextInput
              {...register('fullName')}
              name="fullName"
              label="Your full name"
              placeholder="Full Name"
              invalid={FormHelper.isFieldInvalid('fullName', errors)}
            />
          </FormItem>
          <FormItem errors={errors.email?.message}>
            <TextInput
              {...register('email')}
              name="email"
              label="Your email"
              placeholder="Email"
              invalid={FormHelper.isFieldInvalid('email', errors)}
            />
          </FormItem>
        </Row>

        <Button disabled={!isValid} uppercase width="default">
          Save
        </Button>
      </Form>

      <div className="tw-mt-50">
        <Form>
          <Row items="center" gap={24}>
            <FormItem errors={passwordErrors.password?.message}>
              <PasswordInput
                {...registerPassword('password')}
                name="password"
                label="Old password"
                placeholder="Old password"
                invalid={FormHelper.isFieldInvalid('password', passwordErrors)}
              />
            </FormItem>
            <FormItem errors={passwordErrors.newPassword?.message}>
              <PasswordInput
                {...registerPassword('newPassword')}
                name="newPassword"
                label="New password"
                placeholder="New password"
                invalid={FormHelper.isFieldInvalid(
                  'newPassword',
                  passwordErrors
                )}
              />
            </FormItem>
          </Row>

          <Button disabled={!passwordIsValid} uppercase width="default">
            Change password
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default withStore(mapStateToProps)(ProfileSettingsView)
