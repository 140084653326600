export enum COMPANY_VERIFICATION {
  VERIFIED = 'verified',
  NOT_VERIFIED = 'not-verified',
  PENDING = 'pending'
}

export enum DASHBOARD_TABS {
  NOTES = 'notes',
  REQUESTS = 'requests'
}

export enum SAFE_NOTES_TABS {
  ALL = 'all',
  DRAFT = 'draft',
  UNPAID = 'not-paid'
}
