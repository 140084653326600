import React, { FC } from 'react'

import { Avatar } from '@/common/components'
import { ViewSafeAs } from '@/features/safe-note'
import { Color } from '@/packages/palette'
import { FontWeight, Row, Text, TextTypes } from '@/packages/ui'

// import requestTo from '@/features/dashboard/assets/images/safe-request-to.svg'

interface SafeProps {
  row: any
}

const SAFE: FC<SafeProps> = (props) => {
  const {
    row: { viewAs, sender, recipient }
  } = props

  const byMe = viewAs === ViewSafeAs.SENDER || viewAs === ViewSafeAs.TEAM_MEMBER

  return (
    <Row gap={16} items="center">
      <Avatar
        size={36}
        name={byMe ? recipient?.fullName : sender?.fullName}
        image={byMe ? recipient?.image : sender?.image}
      />
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.neutral500}
        weight={FontWeight.SEMIBOLD}
      >
        {byMe ? recipient?.fullName : sender?.fullName}
      </Text>
    </Row>
  )
}

export default SAFE
