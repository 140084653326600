import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { StoreType, withStore } from '@/common/store'
import { useModalContext } from '@/features/modals'
import {
  ISafeNote,
  NotAuthorizedHeader,
  ViewSafeAs
} from '@/features/safe-note'
import { SafeNote } from '@/features/safe-note/components'
import { getRawSafeTemplate } from '@/features/safe-note/utils/raw-template'
import { FullScreenLoading } from '@/packages/ui'

import styles from './PreviewSafe.module.scss'

const mapStateToProps = ({ safeNote }: StoreType) => ({
  fetchWithToken: safeNote.fetchWithToken,
  generatePdf: safeNote.generatePdf,
  loading: safeNote.functionLoading.fetchOne,
  generatePdfLoading: safeNote.functionLoading.generatePdf
})

type PreviewSafeProps = ReturnType<typeof mapStateToProps>

const PreviewSafe: FC<PreviewSafeProps> = ({
  fetchWithToken,
  loading,
  generatePdf,
  generatePdfLoading
}: PreviewSafeProps) => {
  const { loginToSignModal } = useModalContext()
  const [searchParams] = useSearchParams()
  const [safeNote, setSafeNote] = useState<undefined | ISafeNote>(undefined)

  const token = searchParams.get('token')

  const runFetchSafeNote = async () => {
    if (!token) return

    const data: ISafeNote = await fetchWithToken({ data: { token } })
    setSafeNote(data)
  }

  useEffect(() => {
    if (token) {
      runFetchSafeNote()
    }
  }, [token])

  const handleDownload = useCallback(() => {
    const template = getRawSafeTemplate()

    const fileName = `SAFE_${safeNote?.recipient.fullName}`

    generatePdf({
      data: {
        html: template,
        fileName
      }
    })
  }, [generatePdf, safeNote])

  const SafeNoteTabContent = (
    <SafeNote
      {...safeNote}
      // for not signed-in users, viewAs is always recipient
      viewAs={ViewSafeAs.RECIPIENT}
      senderName={
        safeNote?.senderSignName || safeNote?.senderCompany?.owner?.fullName
      }
      discountRateActive
      valuationCapActive={!!safeNote?.valuationCap}
      recipientName={safeNote?.recipient?.fullName}
      companyName={safeNote?.senderCompany?.name}
      discountRate={safeNote?.discountRate}
      valuationCap={safeNote?.valuationCap}
      mfn={safeNote?.mfn}
      signatureData={{
        senderName: safeNote?.senderSignName,
        senderSignDate: safeNote?.senderSignDate,
        senderSignature: safeNote?.senderSignature,

        recipientName:
          safeNote?.recipientSignName || safeNote?.recipient?.fullName,
        recipientSignDate: safeNote?.recipientSignDate,
        recipientSignature: safeNote?.recipientSignature,
        emulateSignForNotSignedIn: true,
        onAddSignature: () => loginToSignModal.openModal(),

        disableNameChange: true
      }}
      viewMode
      onDownload={handleDownload}
    />
  )

  return (
    <div className={styles.container}>
      <NotAuthorizedHeader token={token} />
      <div className={styles.content}>
        <FullScreenLoading loading={loading || generatePdfLoading} />
        <div className={styles.wrapper}>{SafeNoteTabContent}</div>
      </div>
      <NotAuthorizedHeader token={token} withoutLogo />
    </div>
  )
}

export default withStore(mapStateToProps)(PreviewSafe)
