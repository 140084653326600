import { yupResolver } from '@hookform/resolvers/yup'
import { noop } from 'lodash'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ConditionalRender } from '@/common/components'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import { permissions } from '@/features/auth/components/EntrepreneurForm/AddTeamMembers/AddTeamMembers'
import { InviteStatus } from '@/features/auth/enums'
import { EmailSchema } from '@/features/auth/schemas/AuthSchema'
import { TeamMembersTableRow } from '@/features/dashboard'
import { ICompanyTeamMember, IOwner } from '@/features/profile/types'
import { TeamMemberPermission } from '@/features/user'
import {
  Button,
  Col,
  FormHelper,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Loader,
  Modal,
  Select,
  TextAlign,
  TextInput
} from '@/packages/ui'

import styles from './AddTeamMembers.module.scss'
import inviteImg from './images/invite-team-member.png'

const mapStateToProps = ({ profile, user }: StoreType) => ({
  addTeamMember: profile.addTeamMember,
  deleteTeamMember: user.deleteTeamMember,
  updateTeamMember: user.updateTeamMember,
  refetchCompany: user.refetchCompany,
  getPublicUserByEmail: user.getPublicUserByEmail
})

interface AddTeamMembersModalProps extends IModalWithCloseFn {
  companyName: string
  owner: IOwner
  companyId: string
  teamMembers: ICompanyTeamMember[]
}

const AddTeamMembersModal: FC<
  AddTeamMembersModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    owner,
    companyId,
    teamMembers,
    closeModal,
    addTeamMember,
    refetchCompany,
    companyName,
    deleteTeamMember,
    updateTeamMember,
    getPublicUserByEmail
  } = props

  const [loading, setLoading] = useState<boolean>(false)

  const [selectedPermission, setSelectedPermission] =
    useState<TeamMemberPermission>(TeamMemberPermission.VIEW)

  const {
    watch,
    register,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(EmailSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const handleSendInvite = async () => {
    const email = watch('email')

    const existedMember = teamMembers.find(
      (member) => member.user.email === email
    )

    if (!!existedMember || owner.email === email) {
      ToastService.showError('This user is already in the team')
      return
    }

    setLoading(() => true)

    try {
      const existingUser = await getPublicUserByEmail({ data: { email } })

      await addTeamMember({
        data: {
          companyId,
          member: {
            fullName: existingUser?.fullName || email.split('@')[0],
            email,
            permission: selectedPermission,
            position: '-'
          }
        },
        options: {
          onSuccess: async () => {
            await refetchCompany({ data: { id: companyId } })
            ToastService.showSuccess('Your invitation was successfully sent')
          }
        }
      })
    } finally {
      setLoading(() => false)
    }
  }

  const handleDeleteTeamMember = async (id: string) => {
    setLoading(() => true)

    try {
      await deleteTeamMember({
        data: {
          companyId,
          id
        },
        options: {
          onSuccess: async () => {
            ToastService.showSuccess('Team member was successfully deleted')
          }
        }
      })
    } finally {
      setLoading(() => false)
    }
  }

  const handleChangePermission = async (
    id: string,
    permission: TeamMemberPermission
  ) => {
    setLoading(() => true)

    try {
      await updateTeamMember({
        data: {
          companyId,
          teamMemberId: id,
          values: { permission }
        }
      })
    } finally {
      setLoading(() => false)
    }
  }

  const Component = (
    <div className={styles.selectWrapper}>
      <div className={styles.spacer} />
      <Select
        small
        custom
        withPortal
        withSearch={false}
        withoutBorders
        name="teamMemberPermission"
        options={permissions}
        value={selectedPermission}
        onChange={(val: string) =>
          setSelectedPermission(val as TeamMemberPermission)
        }
      />
    </div>
  )

  return (
    <Modal size="sm" id="add-team-members" visible setVisible={closeModal}>
      <Col items="center" gap={24}>
        <img src={inviteImg} alt="" width={321} />

        <Heading type={HeadingTypes.H2} align={TextAlign.CENTER}>
          Invite people to {companyName} company
        </Heading>

        <div className={styles.inviteBox}>
          <TextInput
            placeholder="Email"
            {...register('email')}
            className={styles.emailInput}
            addonAfter={Component}
            invalid={FormHelper.isFieldInvalid('email', errors)}
          />
          <Button
            uppercase
            width="fit"
            disabled={!isValid || loading}
            onClick={handleSendInvite}
          >
            Invite
          </Button>
        </div>

        <ConditionalRender
          condition={!loading}
          fallbackElement={<Loader width="100%" height="200px" />}
        >
          <Col items="stretch" gap={20} className={styles.table}>
            <TeamMembersTableRow
              id="owner"
              name={owner.fullName}
              email={owner.email}
              avatar={owner.image}
              permission="owner"
              title={owner.position}
              handleChangePermission={noop}
              handleDeleteTeamMember={noop}
            />

            {teamMembers
              ?.filter?.(
                (teamMember) =>
                  teamMember.inviteStatus !== InviteStatus.Declined
              )
              ?.map((teamMember: ICompanyTeamMember) => (
                <TeamMembersTableRow
                  key={teamMember.id}
                  id={teamMember.userId}
                  name={teamMember.user.fullName}
                  email={teamMember.user.email}
                  avatar={teamMember.user.image}
                  permission={teamMember.permission}
                  inviteStatus={teamMember.inviteStatus}
                  title={teamMember.position}
                  handleChangePermission={handleChangePermission}
                  handleDeleteTeamMember={handleDeleteTeamMember}
                />
              ))}
          </Col>
        </ConditionalRender>
      </Col>
    </Modal>
  )
}

export default withStore(mapStateToProps)(AddTeamMembersModal)
