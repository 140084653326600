import clsx from 'clsx'
import { CSSProperties, FC } from 'react'

import { SharedImages } from '@/assets/images'
import { ConditionalRender } from '@/common/components'
import { getInitialsFromName } from '@/common/utils'
import { Color } from '@/packages/palette'
import { FontWeight, Text, TextTypes } from '@/packages/ui'

import styles from './Avatar.module.scss'

interface AvatarProps {
  name?: string
  image?: string | null
  defaultImage?: string | null
  size?: number
  className?: string
  rectangleMode?: boolean
}

const Avatar: FC<AvatarProps> = ({
  name,
  image,
  size = 40,
  rectangleMode,
  className,
  defaultImage
}: AvatarProps) => {
  const PrimaryView = (
    <div
      className={clsx(
        className,
        styles.container,
        rectangleMode && styles.rectangle
      )}
      style={{ '--size': `${size}px` } as CSSProperties}
    >
      <img src={image || defaultImage || ''} alt="user-avatar" />
    </div>
  )

  const SecondaryView = (
    <div
      className={clsx(
        className,
        styles.container,
        rectangleMode && styles.rectangle,
        !!name && styles.withBg
      )}
      style={{ '--size': `${size}px` } as CSSProperties}
    >
      <ConditionalRender
        condition={!!name}
        fallbackElement={
          <span>
            <img
              src={SharedImages.UserPlaceholder}
              alt="user-avatar-placeholder"
            />
          </span>
        }
      >
        <Text
          type={TextTypes.BODY_EXTRA_SMALL}
          weight={FontWeight.BOLD}
          color={Color.neutral0}
        >
          {getInitialsFromName(name)}
        </Text>
      </ConditionalRender>
    </div>
  )

  return !!image || !!defaultImage ? PrimaryView : SecondaryView
}

export default Avatar
