import React, { FC, useState } from 'react'

import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  DropdownOption,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Select,
  Text,
  TextAlign,
  TextArea,
  TextTypes
} from '@/packages/ui'

import styles from './ContactUsModal.module.scss'

const MODAL_ID = 'contact-us-modal'

const kinds: DropdownOption[] = [
  { value: '1', label: 'Questions' },
  { value: '2', label: 'Technical Support' },
  { value: '3', label: 'Privacy Concerns' },
  { value: '4', label: 'Marketing and Promotions' },
  { value: '5', label: 'Account Help' },
  { value: '6', label: 'Content Issues' }
]

const ContactUsModal: FC<IModalWithCloseFn> = (props) => {
  const { closeModal } = props

  const [issueKind, setIssueKind] = useState<string | undefined>()
  const [feedback, setFeedback] = useState<string | undefined>(undefined)

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <Col items="start">
        <Heading
          type={HeadingTypes.H2}
          align={TextAlign.LEFT}
          className="tw-mt-10 tw-mb-6"
        >
          Contact us
        </Heading>

        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral300}
          align={TextAlign.LEFT}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor Manage
        </Text>

        <div className="tw-self-stretch tw-mt-25">
          <Select
            withPortal
            withSearch={false}
            name="contactUs"
            options={kinds}
            placeholder="Select the subject"
            value={issueKind}
            onChange={(val: string) => setIssueKind(val as string)}
          />
        </div>

        <div className="tw-self-stretch tw-mt-20">
          <TextArea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            label="Tell us more!"
            placeholder="Shortly describe your problem"
          />
        </div>

        <div className={styles.footer}>
          <Button
            uppercase
            appearance="secondary"
            width="full"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            uppercase
            width="full"
            disabled={!issueKind || !feedback}
            onClick={closeModal}
          >
            Send
          </Button>
        </div>
      </Col>
    </Modal>
  )
}

export default ContactUsModal
