export const state = {
  steps: [
    {
      target: '#terms-control-item',
      title: 'Lorem Ipsum',
      disableBeacon: true
    },
    {
      target: '#document-control',
      title: 'Lorem Ipsum'
    },
    {
      target: '#safe-note-navigation-container',
      title: 'Lorem Ipsum',
      placement: 'right'
    }
  ]
}
