import React, { FC, useState } from 'react'

import requestImg from '@/features/auth/assets/images/request-upgrade-permission.png'
import { permissions } from '@/features/auth/components/EntrepreneurForm/AddTeamMembers/AddTeamMembers'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Modal,
  Select,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './RequestUpgradePermission.module.scss'

interface RequestUpgradePermissionProps {
  closeModal: () => void
  sendRequest: (id: string) => void
  permission: any
}

const RequestUpgradePermission: FC<RequestUpgradePermissionProps> = (props) => {
  const { closeModal, sendRequest, permission } = props

  const [newPermission, setNewPermission] = useState<string | undefined>()

  return (
    <Modal
      id="request-permission-modal"
      size="sm"
      visible
      setVisible={closeModal}
    >
      <div className={styles.wrapper}>
        <img
          src={requestImg}
          alt="request upgrade permission"
          className="tw-mb-[20px]"
        />
        <Heading
          type={HeadingTypes.H3}
          align={TextAlign.CENTER}
          className="tw-mb-[12px]"
        >
          Request upgrade your permission.
        </Heading>
        <Text
          className="tw-mb-[32px]"
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral500}
          align={TextAlign.CENTER}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod.
        </Text>

        <div className={styles.permissions}>
          <Col gap={8}>
            <Text type={TextTypes.BODY_MAIN}>Your Permission</Text>
            <Col className={styles.currentPermission}>
              <Text type={TextTypes.BODY_DEFAULT} weight={FontWeight.SEMIBOLD}>
                {permission?.label}
              </Text>
              <Text type={TextTypes.BODY_DEFAULT}>
                {permission?.description}
              </Text>
            </Col>
          </Col>
          <div className={styles.itemsToSelect}>
            <Col gap={8}>
              <Text type={TextTypes.BODY_MAIN}>Upgrade Permission to</Text>
              <Select
                withPortal
                name="permission"
                placeholder="Permissions"
                options={permissions.map((item) =>
                  item.value === permission.value
                    ? { ...item, disabled: true }
                    : item
                )}
                value={newPermission}
                custom
                className="tw-mt-[8px]"
                onChange={setNewPermission}
              />
            </Col>
          </div>
        </div>

        <div className={styles.footer}>
          <Button
            width="default"
            type="button"
            appearance="secondary"
            onClick={closeModal}
            uppercase
          >
            Cancel
          </Button>

          <Button
            width="default"
            type="button"
            appearance="primary"
            disabled={!newPermission}
            onClick={() => sendRequest(newPermission!)}
            uppercase
          >
            Request
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default RequestUpgradePermission
