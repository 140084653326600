import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'
import { useLocation } from 'react-router-dom'
import { UsaStates } from 'usa-states'

import { StoreType, withStore } from '@/common/store'
import { FillCompanyImages } from '@/features/auth/assets'
import { EntrepreneurFillInfoSchema } from '@/features/auth/schemas/AuthSchema'
import { UpdateCompanyValues } from '@/features/profile/api/types'
import { Color } from '@/packages/palette'
import {
  Button,
  Form,
  FormHelper,
  FormItem,
  Heading,
  IModalWithCloseFn,
  MaskInput,
  Modal,
  NumberInput,
  Row,
  Select,
  Spacer,
  Text,
  TextInput,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './FillCompanyInfoModal.module.scss'

const states = new UsaStates().states.map((item) => ({
  value: item.name.toLowerCase(),
  label: item.name
}))

const MODAL_ID = 'fill-company-info-modal'

const mapStateToProps = ({ user, profile }: StoreType) => ({
  companies: user.companies,
  refetchCompany: user.refetchCompany,
  updateCompany: profile.updateCompany,
  loading: profile.functionLoading.updateCompany
})

const FillCompanyInfoModal: FC<
  IModalWithCloseFn & ReturnType<typeof mapStateToProps>
> = (props) => {
  const navigate = useNavigator()
  const location = useLocation()

  const { closeModal, companies, refetchCompany, loading, updateCompany } =
    props

  const path = location.pathname.split('/')
  const id = path[path.length - 1]

  const currentCompany = companies.find((company) => company.id === id)

  const {
    control,
    register,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(EntrepreneurFillInfoSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      stateOfIncorporation: currentCompany?.stateOfIncorporation,
      einNumber: currentCompany?.einNumber,
      address1: currentCompany?.address?.address1,
      address2: currentCompany?.address?.address2,
      city: currentCompany?.address?.city,
      state: currentCompany?.address?.state,
      zipCode: currentCompany?.address?.zipCode,
      country: 'USA'
    }
  })

  const onSubmit = async () => {
    const { stateOfIncorporation, einNumber, ...address } = getValues()
    const query = {
      name: currentCompany?.name,
      einNumber,
      stateOfIncorporation,
      address
    }

    await updateCompany({
      data: { companyId: id, values: query as UpdateCompanyValues },
      options: {
        onSuccess: async () => {
          await refetchCompany({ data: { id } })

          closeModal?.()
          navigate.toCreateSafeNote(id)
        }
      }
    })
  }

  return (
    <Modal
      id={MODAL_ID}
      visible
      size="lg"
      setVisible={closeModal}
      className={styles.modalStyles}
    >
      <div className={styles.wrapper}>
        <div className={styles.form}>
          <Row justify="between" items="center" gap={50}>
            <Heading>To create a SAFE, we need more details about you</Heading>
          </Row>
          <Spacer size={8} />
          <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
            Fill the missed data and move on!
          </Text>

          <Spacer size={24} />
          <Form schema={EntrepreneurFillInfoSchema}>
            <Row gap={18}>
              <FormItem errors={errors.stateOfIncorporation?.message}>
                <Controller
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      withPortal
                      name="stateOfIncorporation"
                      label="State of Incorporation"
                      placeholder="State of Incorporation"
                      options={states}
                      value={value!}
                      toTopOnFocus
                      onChange={(val: string) => onChange(val)}
                    />
                  )}
                  name="stateOfIncorporation"
                />
              </FormItem>

              <FormItem errors={errors.einNumber?.message}>
                <Controller
                  control={control}
                  name="einNumber"
                  render={({ field: { onChange, value, ...rest } }) => (
                    <MaskInput
                      {...rest}
                      onValueChange={(val: NumberFormatValues) =>
                        onChange(val.floatValue)
                      }
                      tooltip="An Employer Identification Number (EIN) is also known as a Federal Tax Identification Number, and is used to identify a business entity"
                      value={value}
                      format="##-#######"
                      label="EIN Number (Tax ID)"
                      placeholder="EIN Number (Tax ID)"
                      invalid={FormHelper.isFieldInvalid('einNumber', errors)}
                    />
                  )}
                />
              </FormItem>
            </Row>

            <Row gap={18}>
              <FormItem errors={errors.address1?.message}>
                <TextInput
                  {...register('address1')}
                  label="Address"
                  placeholder="Address"
                  invalid={FormHelper.isFieldInvalid('address1', errors)}
                />
              </FormItem>

              <FormItem>
                <TextInput
                  {...register('address2')}
                  label="Apartment, suite, etc."
                  placeholder="Apartment, suite, etc."
                />
              </FormItem>
            </Row>

            <Row gap={18}>
              <FormItem errors={errors.city?.message}>
                <TextInput
                  {...register('city')}
                  label="City"
                  placeholder="City"
                  invalid={FormHelper.isFieldInvalid('city', errors)}
                />
              </FormItem>

              <FormItem errors={errors.state?.message}>
                <Controller
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      withPortal
                      name="state"
                      label="State"
                      placeholder="State"
                      options={states}
                      value={value!}
                      toTopOnFocus
                      onChange={(val: string) => onChange(val)}
                    />
                  )}
                  name="state"
                />
              </FormItem>
            </Row>

            <Row items="center" gap={18}>
              <FormItem errors={errors.zipCode?.message}>
                <Controller
                  control={control}
                  name="zipCode"
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      value={value}
                      onValueChange={(val: NumberFormatValues) => {
                        onChange(val.value)
                      }}
                      label="Zip Code"
                      placeholder="Zip Code"
                      name="zipCode"
                      invalid={FormHelper.isFieldInvalid('zipCode', errors)}
                      thousandSeparator={false}
                    />
                  )}
                />
              </FormItem>

              <FormItem errors={errors.country?.message}>
                <TextInput
                  {...register('country')}
                  disabled
                  label="Country"
                  tooltip="We’re currently only working within the USA, but hold tight, MySAFEnotes will be available in other countries shortly."
                  placeholder="Country"
                  invalid={FormHelper.isFieldInvalid('country', errors)}
                />
              </FormItem>
            </Row>
          </Form>

          <Button
            uppercase
            onClick={onSubmit}
            disabled={!isValid || loading}
            width="default"
            className="tw-mt-[15px]"
          >
            {loading ? 'Loading...' : 'Save & move on'}
          </Button>
        </div>
        <div className={styles.stepImage}>
          <img src={FillCompanyImages.EntrepreneurFillInfo} alt="" />
        </div>
      </div>
    </Modal>
  )
}

export default withStore(mapStateToProps)(FillCompanyInfoModal)
