import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

import {
  BlueVerified,
  Verified
} from '@/common/components/UserProfile/VerificationStatusIcons'
import { DateHelper } from '@/common/helpers'
import { useStore } from '@/common/store'
import { SubscriptionsModal } from '@/features/auth/modals'
import advancedPlan from '@/features/profile-settings/assets/advanced.png'
import basicPlan from '@/features/profile-settings/assets/basic.png'
import { ComparePlans } from '@/features/profile-settings/assets/icons'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './Plans.module.scss'

interface PlansProps {
  activePlan: 'basic' | 'advanced'
}

const Plans: FC<PlansProps> = (props) => {
  const { activePlan } = props

  const { visible, openModal, closeModal } = useModal<boolean>(false)
  const createdDate = useStore((store) => store.user.me?.createdAt)

  const renderItem = (text: ReactNode) => (
    <Row items="center" gap={12}>
      <BlueVerified size={22} color={Color.primary500} />
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.neutral400}
        weight={FontWeight.MEDIUM}
      >
        {text}
      </Text>
    </Row>
  )

  const renderHeader = (
    id: string,
    title: string,
    price: number,
    img: string
  ) => (
    <Row className={styles.planHeader} gap={20} justify="between">
      <Row items="center" gap={18}>
        <div className={styles.iconWrapper}>
          <img src={img} alt={title} />
        </div>
        <Col gap={2}>
          <Heading type={HeadingTypes.H2} color={Color.neutral500}>
            {title}
          </Heading>

          <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral300}>
            <span className={styles.price}>${price}</span> / month
          </Text>
        </Col>
      </Row>

      {activePlan === id && <Verified color={Color.success400} size={30} />}
    </Row>
  )

  return (
    <div>
      {visible && <SubscriptionsModal closeModal={closeModal} />}
      <Row items="center" gap={20} justify="between">
        <Heading type={HeadingTypes.H2}>Subscriptions</Heading>

        <Row
          className="tw-cursor-pointer"
          items="center"
          gap={6}
          onClick={() => openModal()}
        >
          <ComparePlans color={Color.primary400} />

          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            color={Color.primary400}
          >
            Compare plans
          </Text>
        </Row>
      </Row>

      <div className={styles.plansContainer}>
        <Col
          gap={65}
          justify="between"
          className={clsx(styles.plan, activePlan === 'basic' && styles.active)}
        >
          <div>
            {renderHeader('basic', 'Basic plan', 0, basicPlan)}

            <Col gap={17} className="tw-pt-24">
              {renderItem(
                <>
                  Create <b>2 SAFE</b> notes
                </>
              )}
              {renderItem(
                <>
                  Invite <b>2 team members</b>
                </>
              )}
              {renderItem(
                <>
                  <b>Sharing</b> creative capabilities
                </>
              )}
            </Col>
          </div>

          <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
            Started {DateHelper.toPrettyFormat(createdDate)}
          </Text>
        </Col>

        <Col
          gap={65}
          justify="between"
          className={clsx(
            styles.plan,
            activePlan === 'advanced' && styles.active
          )}
        >
          <div>
            {renderHeader('advanced', 'Advanced plan', 9.99, advancedPlan)}

            <Col gap={17} className="tw-pt-24">
              {renderItem(
                <>
                  All features <b>in Basic</b>
                </>
              )}
              {renderItem(
                <>
                  Company verification <b>checkmark</b>
                </>
              )}
              {renderItem(
                <>
                  Legal consultation <b>one hour</b>
                </>
              )}
              {renderItem(
                <>
                  Ability to create <b>more than 2 SAFE notes</b>
                </>
              )}
              {renderItem(
                <>
                  Invite <b>as many team members</b> as you want
                </>
              )}
            </Col>
          </div>

          <Button width="default" uppercase>
            Upgrade plan
          </Button>
        </Col>
      </div>
    </div>
  )
}

export default Plans
