import clsx from 'clsx'
import { CSSProperties, FC } from 'react'

import { Avatar } from '@/common/components'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import { FontWeight, Text, TextTypes } from '@/packages/ui'

import styles from './UserProfile.module.scss'

interface IUserProfileProps {
  userName: string
  email: string
  image?: string | null
  avatarSize?: number
  className?: string
  onClick?: () => void
}

type ModalType = 'why' | 'verify'

const UserProfile: FC<IUserProfileProps> = ({
  email,
  userName,
  image,
  onClick,
  className,
  avatarSize = 46
}: IUserProfileProps) => {
  const { closeModal, visible: activeModal } = useModal<ModalType>()

  return (
    <div className={clsx(className, styles.userProfile)} onClick={onClick}>
      <div
        className={styles.imgWrapper}
        style={{ '--size': `${avatarSize}px` } as CSSProperties}
      >
        <Avatar image={image} name={userName} size={avatarSize} />
      </div>
      <div className={clsx(styles.content)}>
        <div className={styles.titleWrapper}>
          <Text
            type={TextTypes.BODY_DEFAULT}
            weight={FontWeight.SEMIBOLD}
            className={styles.title}
          >
            {userName}
          </Text>
        </div>

        <Text type={TextTypes.BODY_EXTRA_SMALL} color={Color.neutral300}>
          {email}
        </Text>
      </div>
    </div>
  )
}

export default UserProfile
