import { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import {
  NotVerified,
  Verified
} from '@/common/components/UserProfile/VerificationStatusIcons'
import { getInitialsFromName } from '@/common/utils'
import { Color } from '@/packages/palette'
import { Row } from '@/packages/ui'

import styles from './SignedColumn.module.scss'

interface SignedColumnProps {
  row: any
}

const SignedColumn: FC<SignedColumnProps> = (props) => {
  const {
    row: { senderSignature, sender, recipientSignature, recipient }
  } = props

  return (
    <Row gap={6}>
      <ConditionalRender condition={sender || recipient} fallbackElement="-">
        <div key={sender?.id} className={styles.initials}>
          {getInitialsFromName(sender?.fullName)}
          {senderSignature ? (
            <Verified color={Color.success400} clickable={false} />
          ) : (
            <NotVerified color={Color.error200} clickable={false} />
          )}
        </div>

        <div key={recipient?.id} className={styles.initials}>
          {getInitialsFromName(recipient?.fullName)}
          {recipientSignature ? (
            <Verified color={Color.success400} clickable={false} />
          ) : (
            <NotVerified color={Color.error200} clickable={false} />
          )}
        </div>
      </ConditionalRender>
    </Row>
  )
}

export default SignedColumn
