import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext
} from 'react'

import { SignatureProps, ViewSafeAs } from '@/features/safe-note'

export interface SafeNoteContextValue {
  data: {
    // general props
    companyName?: string
    senderName?: string
    recipientEmail?: string
    recipientName?: string
    safeAmount?: number
    cashAmount?: number
    discountRate?: number
    discountRateActive?: boolean
    valuationCap?: number
    valuationCapActive?: boolean
    safeAllocation?: number
    safeAllowance?: number
    mfn?: boolean
    stateOfIncorporation?: string
    hasSenderSignature?: boolean
    hasInvestorSignature?: boolean
    signatureData: SignatureProps
  }

  // terms control
  onSwitchMfn?: (value: boolean) => void
  onSwitchDiscountRate?: (value: boolean) => void
  onSwitchValuationCap?: (value: boolean) => void
  onEditDiscountRate?: (value: boolean) => void
  onEditValuationCap?: (value: boolean) => void

  // general control
  viewAs: ViewSafeAs
  fullScreen: boolean
  setFullScreen: (value: boolean) => void
  toggleFullScreen: () => void
  showColors: boolean
  setShowColors: (value: boolean) => void
  sidebarCollapsed: boolean
  toggleSidebar: () => void

  // utils
  viewMode?: boolean
  onUpdateData?: (data: any, immediateUpdate?: boolean) => void
  onFullScreen?: (newV: boolean, prevV: boolean) => void
  onDownload?: () => void
  onNavigationItemClick: (anchor: string, defaultStyles?: any) => void
}

type SafeNoteContextProviderProps = PropsWithChildren<SafeNoteContextValue>

const SafeNoteContext = createContext<SafeNoteContextValue>(
  {} as SafeNoteContextValue
)

export const SafeNoteContextProvider: FC<SafeNoteContextProviderProps> = ({
  children,
  ...props
}: SafeNoteContextProviderProps) => (
  <SafeNoteContext.Provider value={props as SafeNoteContextValue}>
    {children}
  </SafeNoteContext.Provider>
)

export const useSafeNoteContext = (): SafeNoteContextValue =>
  useContext<SafeNoteContextValue>(SafeNoteContext)
