import api, { withQuery } from '@/common/api'

import {
  AuthResponse,
  LoginDto,
  SignUpDto,
  ValidateTokenResponse
} from './types'

export const AUTH_ENDPOINTS = {
  LOGIN: '/auth/login',
  SIGN_UP: '/auth/registration',
  VALIDATE_USER_TOKEN: '/user/validate-token',
  VALIDATE_TOKEN: '/validate-token'
}

export class AuthRequests {
  static async login(dto: LoginDto): Promise<AuthResponse> {
    return api.post(AUTH_ENDPOINTS.LOGIN, dto)
  }

  static async signUp(dto: SignUpDto): Promise<AuthResponse> {
    return api.post(AUTH_ENDPOINTS.SIGN_UP, dto)
  }

  static validateToken(
    token: string,
    endpoint = AUTH_ENDPOINTS.VALIDATE_TOKEN
  ): Promise<ValidateTokenResponse> {
    if (!token || token.trim() === '') {
      return Promise.resolve({
        valid: false,
        data: null
      } as ValidateTokenResponse)
    }

    return api.get(withQuery(endpoint, { token }))
  }

  static validateUserToken(token: string): Promise<ValidateTokenResponse> {
    return this.validateToken(token, AUTH_ENDPOINTS.VALIDATE_USER_TOKEN)
  }
}
