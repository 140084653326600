import { action, makeObservable } from 'mobx'

import { RootStoreType } from '@/common/store'
import StoreHandler from '@/common/store/StoreHandler'
import { ProfileRequests } from '@/features/profile/api/ProfileRequests'
import {
  ICompanySummary,
  UpdateCompanyValues
} from '@/features/profile/api/types'
import { ITeamMember } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'

class ProfileStore extends StoreHandler {
  constructor(rootStore: RootStoreType) {
    super(rootStore)
    makeObservable(this)
  }

  @action
  public fetchOne = this.execute<{ id: string }>(
    async ({ data }) => ProfileRequests.fetchOne(data.id),
    'fetchOne'
  )

  @action
  public fetchSingleSummary = this.execute<{ id: string }>(
    async ({ data }): Promise<ICompanySummary> =>
      ProfileRequests.fetchSingleSummary(data.id),
    'fetchSingleSummary'
  )

  @action
  public fetchSummaries = this.execute<{
    type?: ProfileTypes
    angelCompanyId?: string
  }>(
    async ({ data }): Promise<ICompanySummary[]> =>
      ProfileRequests.fetchSummaries(data.type, data.angelCompanyId),
    'fetchSummaries'
  )

  @action
  public addTeamMember = this.execute<{
    companyId: string
    member: ITeamMember
  }>(async ({ data, options }) => {
    await ProfileRequests.addTeamMember(data.companyId, data.member)

    options?.onSuccess?.()
  }, 'addTeamMember')

  @action
  public updateCompany = this.execute<{
    companyId: string
    values: UpdateCompanyValues
  }>(async ({ data, options }) => {
    await ProfileRequests.updateCompany(data.companyId, data.values)

    options?.onSuccess?.()
  }, 'updateCompany')

  @action
  public updateCompanyGoal = this.execute<{
    id: string
    goal: number
  }>(
    async ({ data }) =>
      ProfileRequests.updateCompany(data.id, { goal: data.goal }),
    'updateGoal'
  )
}

export default ProfileStore
