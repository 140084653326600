import 'react-image-crop/dist/ReactCrop.css'

import imageCompression from 'browser-image-compression'
import React, { FC, useCallback, useRef, useState } from 'react'
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop
} from 'react-image-crop'

import { ConditionalRender } from '@/common/components'
import { dataUrlToFile, fileToDataUrl } from '@/common/utils'
import { useDebounceEffect } from '@/packages/hooks'
import {
  Button,
  FileSelect,
  Heading,
  HeadingTypes,
  IFile,
  IFileProgressInfo,
  IModalWithCloseFn,
  Modal,
  Spacer,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './PickImageModal.module.scss'
import { canvasPreview } from './utils'

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

interface PickImageModalProps extends IModalWithCloseFn {
  onSelectFile?: (file: IFile) => void
  description?: string
  fileProgressInfo?: IFileProgressInfo
}

const PickImageModal: FC<PickImageModalProps> = ({
  closeModal,
  description,
  onSelectFile,
  fileProgressInfo
}: PickImageModalProps) => {
  const [file, setFile] = useState<IFile | null>(null)
  const [image, setImage] = useState<string>()
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [croppedFile, setCroppedFile] = useState<any>(null)
  const imageRef = useRef(null)

  const handleSelectFiles = useCallback(async ([_file]: IFile[]) => {
    setCrop(undefined)

    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1366
    }
    try {
      if (_file) {
        const compressedFile = await imageCompression(_file, options)
        setFile(
          Object.assign(compressedFile, {
            id: Date.now().toString()
          })
        )
        const base64 = await fileToDataUrl(compressedFile)
        setImage(base64 as string)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const handleCancel = useCallback(() => {
    if (!file) {
      closeModal?.()
      return
    }

    setFile(null)
  }, [closeModal, file])

  const handleSave = useCallback(() => {
    if (croppedFile) {
      onSelectFile?.(croppedFile)
    }
    closeModal?.()
  }, [closeModal, croppedFile, onSelectFile])

  const handleCompleteCrop = useCallback(
    async (c: PixelCrop) => {
      setCompletedCrop(c)
    },
    [setCompletedCrop]
  )

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imageRef.current) {
        const croppedImage = canvasPreview(imageRef.current, completedCrop)

        const fileName = (file?.name || 'unknown.png').replace(
          /\.[^/.]+$/,
          '.png'
        )

        const _file = await dataUrlToFile(croppedImage, fileName)
        setCroppedFile(_file)
      }
    },
    50,
    [completedCrop]
  )

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, 1))
  }

  const ImageCropper = (
    <>
      <ReactCrop
        crop={crop}
        aspect={1}
        onChange={(_, c) => setCrop(c)}
        onComplete={handleCompleteCrop}
        circularCrop
      >
        <img
          ref={imageRef}
          className={styles.image}
          src={image}
          alt=""
          onLoad={onImageLoad}
        />
      </ReactCrop>
    </>
  )

  return (
    <Modal id="pick-image-modal" size="sm" visible setVisible={closeModal}>
      <div className={styles.container}>
        <Heading type={HeadingTypes.H3}>Upload photo</Heading>
        <Spacer size={12} />
        <Text type={TextTypes.BODY_DEFAULT} align={TextAlign.CENTER}>
          {description ||
            ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'}
        </Text>

        <Spacer size={28} />
        <div className={styles.content}>
          <ConditionalRender
            condition={!file || !image}
            fallbackElement={ImageCropper}
          >
            <FileSelect
              allowedExtensions=".png,.jpg,.jpeg,.svg"
              onSelectFiles={handleSelectFiles}
              progressInfo={fileProgressInfo}
              caption="Drag and drop to upload a file (PNG, JPG, or SVG), or import a file from your device"
              hideFileList
            />
          </ConditionalRender>
          <Spacer size={10} />
          <Text type={TextTypes.BODY_EXTRA_SMALL}>
            For the best quality, use images between 1500 and 2500 pixels wide.
          </Text>
        </div>
        <Spacer size={35} />
        <div className={styles.footer}>
          <Button
            width="default"
            type="button"
            appearance="secondary"
            onClick={handleCancel}
            uppercase
          >
            {file ? 'Delete' : 'Cancel'}
          </Button>

          <Button
            width="default"
            type="button"
            appearance="primary"
            onClick={handleSave}
            disabled={!file}
            uppercase
          >
            Upload
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default PickImageModal
