import React from 'react'

import { WithdrawIcon } from '@/features/dashboard/assets'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Row,
  Text,
  TextAlign,
  TextTypes,
  Tooltip
} from '@/packages/ui'

import styles from './WithdrawButton.module.scss'

const WithdrawButton = () => (
  <Tooltip
    trigger="click"
    interactive
    placement="bottom-start"
    className="tw-w-[220px] tw-p-[15px]"
    content={
      <div>
        <Text
          align={TextAlign.CENTER}
          type={TextTypes.BODY_SMALL}
          color={Color.neutral300}
        >
          You don`t have money to withdraw
        </Text>
      </div>
    }
  >
    <div>
      <Row gap={6}>
        <WithdrawIcon />
        <Text
          className={styles.text}
          type={TextTypes.BODY_SMALL}
          color={Color.neutral400}
          weight={FontWeight.SEMIBOLD}
        >
          Withdraw
        </Text>
      </Row>
    </div>
  </Tooltip>
)

export default WithdrawButton
