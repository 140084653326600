import React, { FC } from 'react'

import {
  BlueVerified,
  Verified
} from '@/common/components/UserProfile/VerificationStatusIcons'
import { ArrowDown } from '@/features/auth/assets/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Modal,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './SubscriptionsModal.module.scss'

interface SubscriptionsModalProps {
  closeModal: () => void
}

const list = [
  {
    title: 'View 2 companies shared with you',
    basic: true,
    premium: true
  },
  {
    title: 'Add 2 team members',
    basic: true,
    premium: true
  },
  {
    title: 'Create 2 SAFEs',
    basic: true,
    premium: true
  },
  {
    title: 'Create 2 Angel companies',
    basic: true,
    premium: true
  },
  {
    title: 'Unlimited companies shared with you',
    basic: false,
    premium: true
  },
  {
    title: 'Unlimited team members',
    basic: false,
    premium: true
  },
  {
    title: 'Unlimited SAFEs',
    basic: false,
    premium: true
  },
  {
    title: 'Unlimited Angel companies',
    basic: false,
    premium: true
  },
  {
    title: 'Share creative permissions (view, edit, create)',
    basic: false,
    premium: true
  },
  {
    title: 'Company verification',
    basic: false,
    premium: true
  },
  {
    title: 'One hour legal consultation per month',
    basic: false,
    premium: true
  }
]

const SubscriptionsModal: FC<SubscriptionsModalProps> = (props) => {
  const { closeModal } = props

  return (
    <Modal id="subscriptions-modal" size="md" visible setVisible={closeModal}>
      <Heading
        type={HeadingTypes.H2}
        align={TextAlign.CENTER}
        color={Color.neutral500}
      >
        Upgrade your subscription and add unlimited team members!
      </Heading>

      <table className={styles.table}>
        <thead>
          <tr>
            <td />
            <td className={styles.basic}>
              Basic
              <div className={styles.selectedPlan}>
                <Verified color={Color.success400} width={30} height={30} />
              </div>
            </td>
            <td className={styles.premium}>Plus</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.optionName}>
              <Col gap={10} className="tw-mt-[-65px]">
                <Heading type={HeadingTypes.H2} color={Color.neutral500}>
                  Compare plans <br /> to choose the best!
                </Heading>

                <Row items="center" gap={4}>
                  <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
                    Read the feature list in more detail
                  </Text>

                  <ArrowDown />
                </Row>
              </Col>
            </td>
            <td>
              <Col items="center" gap={8}>
                <div className={styles.price}>
                  $0 <span>/ month</span>
                </div>

                <Text
                  type={TextTypes.BODY_DEFAULT}
                  weight={FontWeight.SEMIBOLD}
                  color={Color.neutral400}
                >
                  Started today
                </Text>
              </Col>
            </td>
            <td>
              <Col items="center" gap={8}>
                <div className={styles.price}>
                  $9.99 <span>/ month</span>
                </div>

                <Button uppercase width="fit" className={styles.upgradeBtn}>
                  Upgrade plan
                </Button>
              </Col>
            </td>
          </tr>
          {list.map((item, index) => (
            <tr key={index}>
              <td>
                <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
                  {item.title}
                </Text>
              </td>

              <td>
                {item.basic ? (
                  <BlueVerified size={25} color={Color.primary400} />
                ) : (
                  <Text
                    type={TextTypes.BODY_MAIN}
                    color={Color.neutral500}
                    weight={FontWeight.BOLD}
                  >
                    —
                  </Text>
                )}
              </td>

              <td>
                <BlueVerified size={25} color={Color.primary400} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  )
}

export default SubscriptionsModal
