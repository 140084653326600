import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import notermSheetIcon from '@/features/dashboard/assets/images/summary/no-termsheet.png'
import termSheetIcon from '@/features/dashboard/assets/images/summary/termsheet.png'
import { useModalContext } from '@/features/modals'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Text,
  TextAlign,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './DashboardTermSheet.module.scss'

interface DashboardUpdatesProps {
  role: ProfileTypes
  permission: TeamMemberPermission
}

const DashboardTermSheet: FC<DashboardUpdatesProps> = (props) => {
  const { role, permission } = props

  const isEntrepreneur = role === ProfileTypes.ENTREPRENEUR
  const { dontHavePermissionModal } = useModalContext()

  const handleClick = () => {
    if (permission !== TeamMemberPermission.CREATE) {
      dontHavePermissionModal.openModal()
    }
  }

  return (
    <Col
      gap={11}
      items="center"
      justify="center"
      className={styles.updatesBlock}
    >
      <div className={styles.tooltip}>
        <Tooltip
          kind={TooltipKind.INFO}
          content={
            isEntrepreneur
              ? 'Ready to raise funds? Set basic terms (like investment amount and SAFE features) on a Term Sheet and send them to prospective investors. It’s an easy, legally non-binding way to begin collecting capital.'
              : ''
          }
        />
      </div>

      <img
        width={60}
        src={isEntrepreneur ? termSheetIcon : notermSheetIcon}
        alt="Updates"
      />

      <Text
        weight={FontWeight.SEMIBOLD}
        type={TextTypes.BODY_MAIN}
        align={TextAlign.CENTER}
        className="!tw-leading-snug"
      >
        {isEntrepreneur ? (
          <>Prepare and send a term sheet</>
        ) : (
          <>
            You don’t have any <br /> term sheets yet.
          </>
        )}
      </Text>

      <ConditionalRender
        condition={isEntrepreneur}
        fallbackElement={
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
            Lorem ipsum dolor sit amet, <br /> consectetur adipiscing elit.
          </Text>
        }
      >
        <Button
          width="fit"
          appearance="primary"
          // disabled={TeamMemberPermission.VIEW === permission}
          className="!tw-px-[20px] !tw-py-[10px]"
          onClick={handleClick}
        >
          Create term sheet
        </Button>
      </ConditionalRender>
    </Col>
  )
}

export default DashboardTermSheet
