import clsx from 'clsx'
import React, { FC, useCallback } from 'react'

// eslint-disable-next-line import/no-cycle
import { MenuItem, MenuItemProps } from './MenuItem'
import styles from './NavigationMenu.module.scss'

interface NavigationMenuProps {
  menu: MenuItemProps[]
  nested?: boolean
}

const NavigationMenu: FC<NavigationMenuProps> = ({
  menu = [],
  nested = false
}: NavigationMenuProps) => {
  const renderMenuItems = useCallback(
    () =>
      menu?.map((item: any, index: number) => (
        <MenuItem key={index} nested={nested} {...item} />
      )),
    [menu]
  )

  return (
    <div className={clsx(styles.container, nested && styles.nested)}>
      <ul>{renderMenuItems()}</ul>
    </div>
  )
}

export default NavigationMenu
