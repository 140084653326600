import React, { FC } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { permissions } from '@/features/auth/components/EntrepreneurForm/AddTeamMembers/AddTeamMembers'
import { InviteStatus } from '@/features/auth/enums'
import { TeamMemberPermission } from '@/features/user'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Col,
  FontWeight,
  Row,
  Select,
  Text,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

interface TeamMembersTableRowProps {
  id: string
  name: string
  email: string
  avatar: string | null
  permission: TeamMemberPermission | 'owner'
  inviteStatus?: InviteStatus
  title?: string
  handleChangePermission: (id: string, permission: TeamMemberPermission) => void
  handleDeleteTeamMember: (id: string) => void
}

const TeamMembersTableRow: FC<TeamMembersTableRowProps> = (props) => {
  const {
    id,
    name,
    email,
    avatar,
    permission,
    inviteStatus,
    handleChangePermission,
    handleDeleteTeamMember,
    title
  } = props

  const pendingTeamMember = inviteStatus === InviteStatus.Pending
  const withoutName = name === '-'
  const permissionsList =
    id === 'owner'
      ? [...permissions, { value: 'owner', label: 'Owner' }]
      : permissions

  return (
    <Row items="center" justify="between" gap={20}>
      <Row items="center" gap={10}>
        <Avatar size={48} name={withoutName ? email : name} image={avatar} />
        <Col>
          <ConditionalRender condition={!withoutName}>
            <Row items="center" gap={5}>
              <Text
                type={TextTypes.BODY_DEFAULT}
                weight={FontWeight.SEMIBOLD}
                color={Color.neutral500}
              >
                {name}
              </Text>

              <ConditionalRender
                condition={!!title && title !== '-' && title !== 'true'}
              >
                <Badge size="small" type={BadgeType.WARNING}>
                  {title}
                </Badge>
              </ConditionalRender>
            </Row>
          </ConditionalRender>

          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.MEDIUM}
            color={Color.neutral400}
            className="!tw-flex tw-items-center tw-gap-6"
          >
            {email}
            <ConditionalRender condition={pendingTeamMember}>
              <Tooltip
                kind={TooltipKind.WARNING}
                content="This user didn't accept your invite yet"
              />
            </ConditionalRender>
          </Text>
        </Col>
      </Row>

      <Row items="center" gap={10}>
        <Select
          small
          withPortal
          name="teamMemberPermission"
          options={permissionsList}
          value={permission}
          disabled={permission === 'owner'}
          onChange={(val: string) =>
            handleChangePermission(id, val as TeamMemberPermission)
          }
        />

        <ConditionalRender condition={permission !== 'owner'}>
          <SharedIcons.Trash
            onClick={() => handleDeleteTeamMember(id)}
            color={Color.neutral400}
            size={24}
          />
        </ConditionalRender>
      </Row>
    </Row>
  )
}

export default TeamMembersTableRow
